import React from 'react'

const Profile = () => {
    return (
        <>
            {'Red Letter?'}
            {'Dark Mode'}
            {'Serif Font'}
        </>
    )
}

export default Profile