import React from 'react'
import BibleToolbar from 'views/overview/bible-toolbar'
import styled from 'styled-components'
import BibleReader from 'views/overview/bible-reader'
import TagCloud from 'views/overview/tag-cloud'
import WordCloud from 'views/overview/word-cloud'
import Bookmarks from 'views/overview/bookmarks'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { DragTargetEnum } from 'enums/ui'
import { UIStore } from 'stores/ui-store'
import { SearchStore } from 'stores/search-store'

const ViewContainer = styled.div`
    position: relative;
    width: 100vw;
    padding: 2rem;
    height: 100%;
    display: flex;
    overflow: hidden;
`
const LeftColumn = styled.div<{hideSidebar: boolean}>`
    height: 100%;
    width: ${p => p.hideSidebar ? 0 : 20}%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-right: ${p => p.hideSidebar ? 0 : 4}rem;
    transition: width .4s ease, margin-right .4s ease;
`
const RightColumn = styled.div<{hideSidebar: boolean}>`
    transition: width .4s ease;
    width: ${p => p.hideSidebar ? 100 : 80}%;
`

const Overview = () => {
    console.log('overview')
    const hideSidebar = UIStore.useState(state => state.hideSidebar)

    const onDragEnd = (event: DragEndEvent) => {
        if (event.active.data.current?.type === DragTargetEnum.SearchLabel) {
            if (event.over?.data.current?.type === DragTargetEnum.ComparisonCard) {
                SearchStore.update(state => {
                    const referenceId = event.active?.data.current?.id
                    const reference = state.references.find(a => a.id === referenceId)
                    if (reference) {
                        reference.comparisonCard = event.over?.data.current?.card
                    }
                })
            }
        }
    }

    return (
        <ViewContainer>
            <LeftColumn hideSidebar={hideSidebar}>
                <TagCloud />
                <Bookmarks />
                <WordCloud />
            </LeftColumn>
            <RightColumn hideSidebar={hideSidebar}>
                <DndContext onDragEnd={onDragEnd}>
                    <BibleToolbar />
                    <BibleReader />
                </DndContext>
            </RightColumn>
        </ViewContainer>
    )
}

export default Overview