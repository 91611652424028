import { LanguagesEnum } from 'enums/ui'
import React from 'react'
import BibleStore from 'stores/bible-store'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import CheckIcon from './icons/check-icon'

const Container = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    background-color: ${p => p.theme.backgrounds.white};
    border-radius: ${styles.borderRadius.default};
    box-shadow: ${p => p.theme.shadows.far};
    min-width: 20rem;
    overflow: hidden;
`
const LanguageList = styled.div`
 
`
const LanguageItem = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
    padding: 0 2rem 0 4rem;
    line-height: 4rem;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
        background-color: ${p => p.theme.backgrounds.input};
    }
`
const StyledCheckIcon = styled(CheckIcon)`
    stroke: green;
`

type Props = {
    selectedLanguage: LanguagesEnum
    setSelectedLanguage: (language: LanguagesEnum) => void
    closeMenu: () => void
}

const BibleLanguageMenu = (props: Props) => {
    const { selectedLanguage, setSelectedLanguage, closeMenu } = props

    const languages = BibleStore.languages

    const onLanguageClick = (event: React.MouseEvent, lang: LanguagesEnum) => {
        event.stopPropagation()
        setSelectedLanguage(lang)
        closeMenu()
    }

    return (
        <Container>
            <LanguageList>
                {
                    languages.map(lang => {
                 
                        return (
                            <LanguageItem key={`language-${lang.type}`} onClick={e => onLanguageClick(e, lang.type)}>
                                {lang.text}
                                {
                                    lang.type === selectedLanguage &&
                                    <StyledCheckIcon />
                                }
                            </LanguageItem>
                        )
                    })
                }
            </LanguageList>
        </Container>
    )
}

export default BibleLanguageMenu