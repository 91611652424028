import { Provider } from 'use-pouchdb'
import PouchDB from 'pouchdb'
import PouchDBFindPlugin from 'pouchdb-find'
import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppRouter from 'router/app-router'
import { UIStore } from 'stores/ui-store'
import { ThemeProvider } from 'styled-components'
import { IBookmarkEntity, ICrossReferenceEntity, IEntryEntity, IFavoriteEntity, IHighlightEntity, IProfileEntity, ITagEntity } from 'types/entity'
import { EntityEnum } from 'enums/entity'
import { BibleBookEnum, BibleVersionEnum } from 'enums/bible'
import { EntryIconEnum } from 'enums/icon'
import { LanguagesEnum } from 'enums/ui'

PouchDB.plugin(PouchDBFindPlugin)

const pouchdb = new PouchDB('bible-treasure-db')
//pouchdb.destroy()

const App = () => {
    const theme = UIStore.useState(state => state.theme)

    useEffect(() => {
        pouchdb.put<IProfileEntity>({
            _id: 'profile1',
            email: 'test@test.de',
            bibleVersion: BibleVersionEnum.NKVJ,
            language: LanguagesEnum.English,
            recentSearches: [{
                _id: '',
                orderId: -1,
                references: [{
                    version: BibleVersionEnum.NKVJ,
                    bookId: BibleBookEnum.Chronicles1,
                    chapterNr: 1
                }],
                tagIds: [],
                terms: [],
                text: 'Custom Search',
                type: EntityEnum.Bookmark
            }],
            type: EntityEnum.Profile,
            username: 'Alexander Badak',
            password: 'hashedpw',
            settings: {

            }
        })

        pouchdb.put<ITagEntity>({
            _id: 'tag1',
            type: EntityEnum.Tag,
            color: {primary: '#B80000', secondary: '#EB9694'},
            text: 'Kingdom of God',
            references: [{
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.James,
                chapterNr: 1,
                subset: {
                    startVerse: 1,
                    endVerse: 1
                }
            },
            {
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.James,
                chapterNr: 1,
                subset: {
                    startVerse: 9,
                    endVerse: 13
                }
            }]
        })

        pouchdb.put<ITagEntity>({
            _id: 'tag7',
            type: EntityEnum.Tag,
            color: {primary: '#006B76', secondary: '#BEDADC'},
            text: 'Royal Law',
            references: [{
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.James,
                chapterNr: 1,
                subset: {
                    startVerse: 1,
                    endVerse: 12
                }
            },
            {
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.James,
                chapterNr: 1,
                subset: {
                    startVerse: 8,
                    endVerse: 12
                }
            }]
        })

        pouchdb.put<ITagEntity>({
            _id: 'tag4',
            type: EntityEnum.Tag,
            color: {primary: '#5300EB', secondary: '#D4C4FB'},
            text: 'Love',
            references: [{
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.James,
                chapterNr: 1,
                subset: {
                    startVerse: 1,
                    endVerse: 8
                }
            }]
        })

        pouchdb.put<ITagEntity>({
            _id: 'tag6',
            type: EntityEnum.Tag,
            color: {primary: '#004DCF', secondary: '#BED3F3'},
            text: 'Forgiveness',
            references: [{
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.James,
                chapterNr: 1,
                subset: {
                    startVerse: 2,
                    endVerse: 7
                }
            }]
        })

        pouchdb.put<ITagEntity>({
            _id: 'tag2',
            type: EntityEnum.Tag,
            color: {primary: '#1273DE', secondary: '#C4DEF6'},
            text: 'Study later',
            references: [{
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.James,
                chapterNr: 1,
                subset: {
                    startVerse: 6,
                    endVerse: 12
                }
            }]
        })

        pouchdb.put<ITagEntity>({
            _id: 'tag3',
            type: EntityEnum.Tag,
            color: {primary: '#FCCB00', secondary: '#FEF3BD'},
            text: 'Favorites',
            references: [{
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.James,
                chapterNr: 1,
                subset: {
                    startVerse: 13,
                    endVerse: 18
                }
            }]
        })

        pouchdb.put<IEntryEntity>({
            _id: 'entry1',
            icon: EntryIconEnum.Compass,
            type: EntityEnum.Entry,
            title: 'V1 No Greater Love',
            reference: {
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.James,
                chapterNr: 1,
                subset: {
                    startVerse: 1,
                    endVerse: 1
                }
            },
            htmlContent: `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat
                    nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                    sunt in culpa qui officia deserunt mollit anim id est laborum.`,
            createdBy: 'Alexander Badak',
            createdOn: new Date().getTime(),
            tagIds: []
        })

        pouchdb.put<IEntryEntity>({
            _id: 'entry2',
            icon: EntryIconEnum.Compass,
            type: EntityEnum.Entry,
            title: 'V7 True Religion',
            reference: {
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.James,
                chapterNr: 1,
                subset: {
                    startVerse: 7,
                    endVerse: 7
                }
            },
            htmlContent: `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat
                    nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                    sunt in culpa qui officia deserunt mollit anim id est laborum.`,
            createdBy: 'Alexander Badak',
            createdOn: new Date().getTime(),
            tagIds: []
        })

        pouchdb.put<IEntryEntity>({
            _id: 'entry3',
            icon: EntryIconEnum.Coffee,
            type: EntityEnum.Entry,
            title: 'V1 Royal Law',
            reference: {
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.James,
                chapterNr: 1,
                subset: {
                    startVerse: 1,
                    endVerse: 1
                }
            },
            htmlContent: `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat
                    nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                    sunt in culpa qui officia deserunt mollit anim id est laborum.`,
            createdBy: 'Alexander Badak',
            createdOn: new Date().getTime(),
            tagIds: []
        })

        pouchdb.put<IEntryEntity>({
            _id: 'entry4',
            icon: EntryIconEnum.Coffee,
            type: EntityEnum.Entry,
            title: 'V2 A Faithful Servant',
            reference: {
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.James,
                chapterNr: 1,
                subset: {
                    startVerse: 2,
                    endVerse: 2
                }
            },
            htmlContent: `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat
                    nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                    sunt in culpa qui officia deserunt mollit anim id est laborum.`,
            createdBy: 'Alexander Badak',
            createdOn: new Date().getTime(),
            tagIds: []
        })

        pouchdb.put<IEntryEntity>({
            _id: 'entry5',
            icon: EntryIconEnum.Coffee,
            type: EntityEnum.Entry,
            title: 'V15 Random Text Title',
            reference: {
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.James,
                chapterNr: 1,
                subset: {
                    startVerse: 15,
                    endVerse: 15
                }
            },
            htmlContent: `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat
                    nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                    sunt in culpa qui officia deserunt mollit anim id est laborum.`,
            createdBy: 'Alexander Badak',
            createdOn: new Date().getTime(),
            tagIds: []
        })

        pouchdb.put<IEntryEntity>({
            _id: 'entry6',
            icon: EntryIconEnum.Coffee,
            type: EntityEnum.Entry,
            title: 'V25 Amazing Grace',
            reference: {
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.James,
                chapterNr: 1,
                subset: {
                    startVerse: 25,
                    endVerse: 25
                }
            },
            htmlContent: `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat
                    nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                    sunt in culpa qui officia deserunt mollit anim id est laborum.`,
            createdBy: 'Alexander Badak',
            createdOn: new Date().getTime(),
            tagIds: []
        })

        pouchdb.put<IEntryEntity>({
            _id: 'entry7',
            icon: EntryIconEnum.Coffee,
            type: EntityEnum.Entry,
            title: 'V26 Orphans and Widows',
            reference: {
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.James,
                chapterNr: 1,
                subset: {
                    startVerse: 26,
                    endVerse: 26
                }
            },
            htmlContent: `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat
                    nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                    sunt in culpa qui officia deserunt mollit anim id est laborum.`,
            createdBy: 'Alexander Badak',
            createdOn: new Date().getTime(),
            tagIds: []
        })

        pouchdb.put<IEntryEntity>({
            _id: 'entry8',
            icon: EntryIconEnum.Coffee,
            type: EntityEnum.Entry,
            title: 'V27 A dangerous Mission',
            reference: {
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.James,
                chapterNr: 1,
                subset: {
                    startVerse: 27,
                    endVerse: 27
                }
            },
            htmlContent: `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat
                    nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                    sunt in culpa qui officia deserunt mollit anim id est laborum.`,
            createdBy: 'Alexander Badak',
            createdOn: new Date().getTime(),
            tagIds: []
        })

        pouchdb.put<IEntryEntity>({
            _id: 'entry9',
            icon: EntryIconEnum.Coffee,
            type: EntityEnum.Entry,
            title: 'V27 An immediate Answer',
            reference: {
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.James,
                chapterNr: 1,
                subset: {
                    startVerse: 27,
                    endVerse: 27
                }
            },
            htmlContent: `Lorem ipsum <i>dolor</i> sit amet, <b>consectetur</b> adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat
                    nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                    sunt in culpa qui officia deserunt mollit anim id est laborum.`,
            createdBy: 'Alexander Badak',
            createdOn: new Date().getTime(),
            tagIds: []
        })

        pouchdb.put<IEntryEntity>({
            _id: 'entry10',
            icon: EntryIconEnum.Feather,
            type: EntityEnum.Entry,
            title: 'Topical Entry',
            reference: null,
            htmlContent: `Lorem ipsum <i>dolor</i> sit amet, <b>consectetur</b> adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
            createdBy: 'Alexander Badak',
            createdOn: new Date().getTime(),
            tagIds: ['tag1']
        })

        pouchdb.put<IEntryEntity>({
            _id: 'entry11',
            icon: EntryIconEnum.TermEntry,
            type: EntityEnum.Entry,
            title: 'dishonored',
            reference: {
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.James,
                chapterNr: 2,
                subset: {
                    startVerse: 6,
                    endVerse: 6,
                    startOffset: 13,
                    endOffset: 23
                }
            },
            htmlContent: 'Lorem ipsum <i>dolor</i> sit amet, <b>consectetur</b>',
            createdBy: 'Alexander Badak',
            createdOn: new Date().getTime(),
            tagIds: ['tag1']
        })

        pouchdb.put<ICrossReferenceEntity>({
            _id: 'crossreference1',
            type: EntityEnum.CrossReference,
            reference: {
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.James,
                chapterNr: 2,
                subset: {
                    startVerse: 1,
                    endVerse: 1,
                    endOffset: 30,
                    startOffset: 20
                }
            },
            targetReference: {
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.James,
                chapterNr: 1,
                subset: {
                    startVerse: 1,
                    endVerse: 3
                }
            }
        })

        pouchdb.put<IBookmarkEntity>({
            _id: 'book0',
            text: 'Romans 1',
            orderId: 0,
            type: EntityEnum.Bookmark,
            tagIds: [],
            terms: [],
            references: [{
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.Romans,
                chapterNr: 1
            }]
        })

        pouchdb.put<IBookmarkEntity>({
            _id: 'book1',
            text: 'James 1',
            orderId: 0,
            type: EntityEnum.Bookmark,
            tagIds: [],
            terms: [],
            references: [{
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.James,
                chapterNr: 1
            }]
        })

        pouchdb.put<IBookmarkEntity>({
            _id: 'book3',
            text: 'James 2',
            orderId: 0,
            type: EntityEnum.Bookmark,
            tagIds: [],
            terms: [],
            references: [{
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.James,
                chapterNr: 2
            }]
        })

        pouchdb.put<IBookmarkEntity>({
            _id: 'book2',
            text: 'Amos / Kingdom / Jesus',
            orderId: 0,
            type: EntityEnum.Bookmark,
            tagIds: ['tag1'],
            terms: ['Jesus'],
            references: [{
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.Amos,
                chapterNr: 2,
                subset: {
                    startVerse: 2,
                    endVerse: 5
                }
            }]
        })

        pouchdb.put<IFavoriteEntity>({
            _id: 'favorite1',
            type: EntityEnum.Favorite,
            timestamp: new Date().getTime(),
            reference: {
                bookId: BibleBookEnum.Daniel,
                version: BibleVersionEnum.NKVJ,
                chapterNr: 3,
                verseNr: 5
            }
        })

        pouchdb.put<IHighlightEntity>({
            _id: 'highlight1',
            type: EntityEnum.Highlight,
            color: '#1fc289',
            reference: {
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.James,
                chapterNr: 2,
                subset: {
                    startVerse: 3,
                    endVerse: 3,
                    startOffset: 10,
                    endOffset: 40
                }
            }
        })

        pouchdb.put<IHighlightEntity>({
            _id: 'highlight2',
            type: EntityEnum.Highlight,
            color: '#cf8246',
            reference: {
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.James,
                chapterNr: 2,
                subset: {
                    startVerse: 4,
                    endVerse: 7,
                    startOffset: 3,
                    endOffset: 10
                }
            }
        })
    }, [])

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <Provider pouchdb={pouchdb}>
                    <AppRouter />
                </Provider>
            </ThemeProvider>
        </BrowserRouter>
    )
}

export default App