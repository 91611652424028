class EntryService {
    public static validateEntryTitle = (title: string) => {
        if (title.length === 0) return false

        return true
    }

    public static trimHtmlContent = (content: string, maxLength: number) : string => {
        if (content.length <= maxLength) return content

        //count the html tags and remove them from the count and add more characters
        const trimmedHtml = content.slice(0, maxLength).trim()
        const lastWord = trimmedHtml.slice(trimmedHtml.lastIndexOf(' ') + 1)

        //if the end is a tag that's partially cut off

        //if the end tag is cut off entirely

        //if the start tag is cut off partially

        return `${trimmedHtml}...`
    }
}

export default EntryService