import ClockIcon from 'components/icons/clock-icon'
import { EntityEnum } from 'enums/entity'
import { SearchLabelEnum, ComparisonCardEnum } from 'enums/ui'
import React from 'react'
import { useNavigate } from 'react-router'
import BibleService from 'services/bible-service'
import { ProfileStore } from 'stores/profile-store'
import { SearchStore } from 'stores/search-store'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import { IBookmarkEntity, ITagEntity } from 'types/entity'
import { ISearchReference, ISearchTag, ISearchTerm } from 'types/search'
import { useFind } from 'use-pouchdb'

const Container = styled.div`
    min-width: 25%;
    max-width: 50%;
    border-radius: ${styles.borderRadius.default};
    box-shadow: ${p => p.theme.shadows.close};
    padding: 3rem;
`
const Header = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-bottom: 2rem;
`
const Title = styled.h3`
    font-size: ${styles.fontSizes.larger};
    font-family: ${styles.fonts.bold};
    letter-spacing: ${p => p.theme.letterSpacing.medium};
    font-weight: 700;
    margin: 0;
`
const StyledClockIcon = styled(ClockIcon)``

const RecentPassage = styled.div`
    padding: 1rem 2rem;
    background-color: ${p => p.theme.backgrounds.content};
    cursor: pointer;
`

const RecentPassagesCard = () => {
    const { recentSearches } = ProfileStore.useState(state => state.profile!)

    const navigate = useNavigate()

    const { docs: tags } = useFind<ITagEntity>({
        index: {fields: ['type']},
        selector: {type: EntityEnum.Tag}
    })

    const onSetActiveBookmark = (bookmark: IBookmarkEntity) => {
        //TODO ? This shouldnt be an order id like this
        let orderId = 0

        const searchReferences = bookmark.references.map<ISearchReference>(r => ({
            labelType: SearchLabelEnum.Reference,
            comparisonCard: ComparisonCardEnum.Default,
            orderId: orderId++,
            reference: r
        }))

        const searchTags = bookmark.tagIds.map<ISearchTag | undefined>(id => {
            const tag = tags.find(t => t._id === id)
            if (tag) {
                const searchTag: ISearchTag = {
                    tagId: tag._id,
                    orderId: orderId++,
                    color: tag.color,
                    labelType: SearchLabelEnum.Tag,
                    comparisonCard: ComparisonCardEnum.Default,
                    references: tag.references,
                    text: tag.text
                }

                return searchTag
            }

            return undefined
        }).filter(tag => !!tag) as ISearchTag[]

        const searchTerms = bookmark.terms.map<ISearchTerm>(term => {
            const searchResults = BibleService.searchBibleExact(term)

            const searchTerm: ISearchTerm = {
                text: term,
                count: searchResults.count,
                labelType: SearchLabelEnum.Term,
                comparisonCard: ComparisonCardEnum.Default,
                orderId: orderId++,
                references: searchResults.references
            }

            return searchTerm
        })

        SearchStore.update(state => {
            state.references = searchReferences
            state.tags = searchTags
            state.terms = searchTerms
        })

        navigate('/bible/overview')
    }

    return (
        <Container>
            <Header>
                <StyledClockIcon />
                <Title>{'Recent Passages'}</Title>
            </Header>
            {
                recentSearches.map(bookmark =>
                    <RecentPassage key={`recent-passage-${bookmark._id}`} onClick={() => onSetActiveBookmark(bookmark)}>
                        {bookmark.text}
                    </RecentPassage>)
            }
        </Container>
    )
}

export default RecentPassagesCard