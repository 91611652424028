import { EntityEnum } from 'enums/entity'
import Helper from 'helpers/helper'
import React, { useCallback } from 'react'
import { IBookmarkEntity } from 'types/entity'
import { IReference, IVerseReference } from 'types/verse'
import { usePouch } from 'use-pouchdb'

interface IUseBookmarkEntity {
    createBookmark: (text: string, references: IReference[], tagIds: string[], terms: string[]) => void
    updateBookmark: (id: string, references: IReference[], tagIds: string[], terms: string[]) => void
    deleteBookmark: (id: string) => void
}

export const useBookmarkEntity = () : IUseBookmarkEntity => {
    const db = usePouch()

    const createBookmark = useCallback(async (text: string, references: IReference[], tagIds: string[], terms: string[]) => {
        const bookmark: IBookmarkEntity = {
            _id: Helper.randomUid(),
            type: EntityEnum.Bookmark,
            text,
            tagIds,
            terms,
            references,
            orderId: new Date().getTime()
        }

        const result = await db.put(bookmark)

        return result
    }, [db])

    const updateBookmark = useCallback(async (id: string, references: IReference[], tagIds: string[], terms: string[]) => {
        const bookmark = await db.get<IBookmarkEntity>(id)
        bookmark.references = references
        bookmark.tagIds = tagIds
        bookmark.terms = terms
        await db.put(bookmark)
    }, [db])

    const deleteBookmark = useCallback(async (id: string) : Promise<boolean> => {
        const bookmark = await db.get<IBookmarkEntity>(id)
        const result = await db.remove(bookmark)

        return result.ok
    }, [db])

    return {
        createBookmark,
        updateBookmark,
        deleteBookmark
    }
}