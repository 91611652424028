import { stat } from 'fs'
import useClickOutside from 'hooks/useClickOutside'
import React, { useRef } from 'react'
import { SearchStore } from 'stores/search-store'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import { IPassage } from 'types/verse'
import ExpandIcon from './icons/expand-icon'
import ShareIcon from './icons/share-icon'

const MoreMenuContainer = styled.div`
    position: absolute;
    right: 0;
    top: 100%;
    display: flex;
    flex-direction: column;
    background: ${p => p.theme.backgrounds.white};
    padding: 1rem 0;
    box-shadow: ${p => p.theme.shadows.far};
    border-radius: ${styles.borderRadius.default};
`
const MenuItem = styled.div<{disabled?: boolean}>`
    display: flex;
    align-items: center;
    white-space: nowrap;
    line-height: 3rem;
    column-gap: 2rem;
    padding-left: 2rem;
    cursor: pointer;
    padding-right: 2rem;
    color: ${p => p.disabled ? 'grey' : 'black'};
    pointer-events: ${p => p.disabled ? 'none' : 'auto'};

    &:hover {
        background-color: ${p => p.theme.backgrounds.input};
    }
`
const StyledShareIcon = styled(ShareIcon)`
    font-size: .75em;
`
const StyledExpandIcon = styled(ExpandIcon)`
    font-size: .75em;
`

type Props = {
    passage: IPassage
    closeMenu: () => void
}

const BiblePassageMenu = (props: Props) => {
    const { passage, closeMenu } = props
    
    const ignoreRef = useRef<HTMLDivElement>(null)
    useClickOutside(closeMenu, ignoreRef)

    const onExpandClick = () => {
        SearchStore.update(state => {
            const searchReference = state.references.find(r => r.orderId === passage.orderId)
            if (searchReference) {
                searchReference.reference.subset = undefined
            }
        })
    }

    const isEntireChapter = !passage.subset

    return (
        <MoreMenuContainer ref={ignoreRef}>
            <MenuItem>
                <StyledShareIcon />
                {'Share'}
            </MenuItem>
            <MenuItem disabled={isEntireChapter} onClick={onExpandClick}>
                <StyledExpandIcon />
                {'Expand Passage'}
            </MenuItem>
        </MoreMenuContainer>
    )
}

export default BiblePassageMenu