import BookIcon from 'components/icons/book-icon'
import DashboardIcon from 'components/icons/dashboard-icon'
import FolderIcon from 'components/icons/folder-icon'
import UsersIcon from 'components/icons/users-icon'
import React, { CSSProperties } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { styles } from 'styles/styles'

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 7rem;
    display: flex;
    align-items: center;
    background-color: ${p => p.theme.backgrounds.white};
    padding-left: 3rem;
    padding-right: 3rem;
    box-shadow: ${p => p.theme.shadows.medium};
`
const Logo = styled.div`
    height: 100%;
    color: ${p => p.theme.colors.primary};
    text-align: center;
    line-height: 7rem;
    font-size: ${styles.fontSizes.large};
    border-radius: 5rem;
    padding: 0 4rem;
    margin-right: 20rem;
`
const ProfileIcon = styled.div`
    border-radius: 50%;
    background-color: #727272;
    height: 80%;
    margin-left: 1rem;
    aspect-ratio: 1 / 1;
`
const NavButton = styled.div`
    display: inline-flex;
    align-items: center;
    column-gap: 1rem;
    align-items: stretch;
    height: 100%;
`
const ProfileNavButton = styled.div`
    display: inline-flex;
    align-items: center;
    column-gap: 1rem;
    text-decoration: none;
    height: 100%;
    padding-left: 4rem;
    color: #727272;
    margin-left: auto;
`
const LeftMenuContainer = styled.div`
    display: flex;
    column-gap: 5rem;
    align-items: stretch;
    height: 100%;
`

type Props = {
    username: string
}

const Header = (props: Props) => {
    const { username } = props

    const getActiveStyle = ({isActive} : {isActive: boolean}) : CSSProperties => ({
        fontFamily: styles.fonts.default,
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
        columnGap: '1rem',
        textDecoration: 'none',
        height: '100%',
        color: isActive ? styles.colors.primary : styles.colors.text,
        fontSize: styles.fontSizes.default,
        borderBottom: isActive ? `.3rem solid ${styles.colors.primary}` : '.3rem solid transparent'
    })

    return (
        <Container>
            <Logo>
                {'MY BIBLE'}
                <i style={{color: styles.colors.secondary, fontWeight: 700}}>{' APP'}</i>
            </Logo>

            <LeftMenuContainer>
                <NavButton>
                    <NavLink to='dashboard' style={getActiveStyle}>
                        <DashboardIcon />
                        {'Dashboard'}
                    </NavLink>
                </NavButton>
                <NavButton>
                    <NavLink to='overview' style={getActiveStyle}>
                        <BookIcon />
                        {'Study Bible'}
                    </NavLink>
                </NavButton>

                <NavButton>
                    <NavLink to='entries' style={getActiveStyle}>
                        <FolderIcon />
                        {'Collection'}
                    </NavLink>
                </NavButton>

                <NavButton>
                    <NavLink to='study-groups' style={getActiveStyle}>
                        <UsersIcon />
                        {'Groups'}
                    </NavLink>
                </NavButton>
            </LeftMenuContainer>

            <ProfileNavButton>
                <NavLink to='profile' style={getActiveStyle}>
                    {username}
                    <ProfileIcon />
                </NavLink>

            </ProfileNavButton>
        </Container>
    )
}

export default Header