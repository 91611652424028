import ArrowLeftIcon from 'components/icons/arrow-left-icon'
import { BibleVersionEnum } from 'enums/bible'
import BibleTag from 'components/bible-tag'
import { EntityEnum } from 'enums/entity'
import { ComparisonCardEnum, ListStateEnum, SearchLabelEnum } from 'enums/ui'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import BibleService from 'services/bible-service'
import { SearchStore } from 'stores/search-store'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import { IBook, IChapter } from 'types/bible'
import { ITagEntity } from 'types/entity'
import { ISearchReferenceResult, ISearchTermResult } from 'types/search'
import { useFind } from 'use-pouchdb'
import { ProfileStore } from 'stores/profile-store'
import BibleVersionMenu from 'components/bible-version-menu'
import Helper from 'helpers/helper'

const Container = styled.div<{visible: boolean}>`
    position: absolute;
    top: 105%;
    left: 0;
    width: 40vw;
    z-index: ${styles.zIndex.dropdown};
    box-shadow: ${p => p.theme.shadows.far};
    background-color: ${p => p.theme.backgrounds.white};
    border-radius: 1rem;
    transform-origin: top center;
    transform: scaleY(${p => p.visible ? 1 : 0});
    transition: transform .2s ease;
`
const ScrollContainer = styled.div`
    overflow: hidden;
`
const ListContainer = styled.div`
    width: 100%;
`
const BookList = styled.div`
    padding: 0;
    font-size: 1.25em;
    line-height: 2;
    margin: 0;
    max-height: 30vh;
`
const ChapterList = styled.div`
    display: flex;
    padding: 0;
    font-size: 1.25em;
    line-height: 2;
    margin: 0;
    max-height: 30vh;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    padding-left: 5rem;
    padding-right: 5rem;
`
const ArbitraryList = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    font-size: 1.25em;
    line-height: 2;
    margin: 0;
    max-height: 30vh;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
`
const TagList = styled.div`
    display: flex;
    flex-flow: wrap;
`
const ListTitle = styled.h4`
    display: inline-flex;
    align-items: center;
    background-color: white;
    padding-left: 5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin: 0;
    font-size: 1.5em;
    text-transform: uppercase;
`
const BookListItem = styled.div`
    padding: .5rem 5rem;
    cursor: pointer;

    &:hover {
        background-color: ${p => p.theme.backgrounds.input};
        font-weight: 600;
    }
`
const ChapterListItem = styled.div`
    padding: .5rem 2rem;
    display: grid;
    place-items: center;
    cursor: pointer;

    &:hover {
        background-color: lightgrey;
        font-weight: 600;
    }
`
const ArbitraryListItem = styled.div`
    padding: .5rem 5rem;
    cursor: pointer;

    &:hover {
        background-color: lightgrey;
        font-weight: 600;
    }
`
const TagListItem = styled.div`
    padding: .5rem 1.5rem;
    border-radius: 25rem;
    cursor: pointer;
`
const SlideContainer = styled.div`
    display: flex;
    height: 100%;
    transition: transform .2s ease;
`
const BookListContainer = styled.div<{visible: boolean}>`
    display: flex;
    height: 100%;
    width: 100%;
    flex-shrink: 0;
    overflow-x: hidden;
    overflow-y: auto;
`
const ChapterListContainer = styled.div<{visible: boolean}>`
    display: flex;
    height: 100%;
    width: 100%;
    flex-shrink: 0;
    overflow-x: hidden;
    overflow-y: auto;
`
const ArbitraryListContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
`
const NoResultText = styled.div`
    padding-left: 5rem;
    padding-bottom: 1rem;
`
const StyledArrowLeftIcon = styled(ArrowLeftIcon)`
    margin-right: 1rem;
    cursor: pointer;
`
const BibleVersionCell = styled.div`
    position: absolute;
    right: 1rem;
    top: 1rem;
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
    cursor: pointer;
`
const BibleVersion = styled.span`
    border-radius: ${styles.borderRadius.small};
    border: .2rem solid ${p => p.theme.backgrounds.inputDark};
    padding: .25rem .5rem;
    color: ${p => p.theme.backgrounds.inputDark};
    font-size: ${styles.fontSizes.small};
    font-family: ${styles.fonts.bold};
    font-weight: 700;
    letter-spacing: ${p => p.theme.letterSpacing.medium};
    background-color: ${p => p.theme.backgrounds.white};
`

type Props = {
    book?: IBook
    options?: ISearchTermResult[]
    listVisible: boolean
    query: string
    setListVisible: (visible: boolean) => void
    setSelectedBook: (book?: IBook) => void
    setQuery: (query: string) => void
}

const SearchList = (props: Props) => {
    const { book, listVisible, query, options} = props
    const { setSelectedBook, setListVisible, setQuery } = props

    const homeBibleVersion = ProfileStore.useState(state => state.profile!.bibleVersion)

    const [listState, setListState] = useState<ListStateEnum>(ListStateEnum.Books)
    const [referenceResults, setReferenceResults] = useState<ISearchReferenceResult[]>([])
    const [showBibleVersionMenu, setShowBibleVersionMenu] = useState<boolean>(false)
    const bookListRef = useRef<HTMLDivElement>(null)

    const books = useMemo(() => BibleService.getBooks(BibleVersionEnum.NKVJ), [])

    const oldTestament = books.slice(0, 39)
    const newTestament = books.slice(39, books.length)

    const { docs: tags, loading, error } = useFind<ITagEntity>({
        index: {fields: ['type', 'text']},
        selector: {
            type: EntityEnum.Tag
        }
    })

    useEffect(() => {
        if (bookListRef.current) {
            bookListRef.current.scrollTo({top: 0})
        }
    }, [listState])

    useEffect(() => {
        if (query.length > 0) {
            setListState(ListStateEnum.Arbitrary)

            const bookResults = BibleService.searchBibleBookName(query.trim())
            setReferenceResults(bookResults)
        } else {
            setListState(ListStateEnum.Books)
        }
    }, [query])

    const onBookItemSelect = (book: IBook) => {
        setSelectedBook(book)
        setListState(ListStateEnum.Chapters)
    }

    const onChapterItemSelect = (chapter: IChapter) => {
        setListVisible(false)
        setQuery('')
        setSelectedBook(undefined)
        setListState(ListStateEnum.Books)

        SearchStore.update(state => {
            const newReference = {
                version: BibleVersionEnum.NKVJ,
                bookId: BibleService.getBookIdByBook(BibleVersionEnum.NKVJ, book!),
                chapterNr: chapter.num,
                subset: undefined
            }

            const existingSearchReference = state.references.find(({reference}) =>
                reference.version === newReference.version &&
                reference.bookId === newReference.bookId &&
                reference.chapterNr === newReference.chapterNr &&
                reference.subset === newReference.subset
            )

            if (existingSearchReference) {
                existingSearchReference.orderId = new Date().getTime()
            } else {
                state.references.push({
                    id: Helper.randomUid(),
                    orderId: new Date().getTime(),
                    labelType: SearchLabelEnum.Reference,
                    comparisonCard: ComparisonCardEnum.Default,
                    reference: newReference
                })
            }
        })
    }

    const onTermItemSelect = (item: ISearchTermResult) => {
        setListVisible(false)
        setQuery('')
        SearchStore.update(state => {
            state.terms.push({
                id: Helper.randomUid(),
                orderId: new Date().getTime(),
                labelType: SearchLabelEnum.Term,
                comparisonCard: ComparisonCardEnum.Default,
                text: item.text,
                count: item.count,
                references: item.references
            })
        })
    }

    const onTagItemSelect = (tag: ITagEntity) => {
        setListVisible(false)
        setQuery('')
        SearchStore.update(state => {
            state.tags.push({
                id: Helper.randomUid(),
                tagId: tag._id,
                orderId: new Date().getTime(),
                labelType: SearchLabelEnum.Tag,
                comparisonCard: ComparisonCardEnum.Default,
                color: tag.color,
                references: tag.references,
                text: tag.text
            })
        })
    }

    const onAllChapterSelect = (book: IBook) => {
        setListVisible(false)
        setQuery('')
        SearchStore.update(state => {
            state.references.push({
                id: Helper.randomUid(),
                orderId: new Date().getTime(),
                labelType: SearchLabelEnum.Reference,
                comparisonCard: ComparisonCardEnum.Default,
                reference: {
                    version: BibleVersionEnum.NKVJ,
                    bookId: BibleService.getBookIdByBook(BibleVersionEnum.NKVJ, book!),
                    chapterNr: 0,
                    subset: {
                        startVerse: 0,
                        endVerse: 0
                    }
                }
            })
        })
    }

    const onReferenceSelect = (reference: ISearchReferenceResult) => {
        setListVisible(false)
        setQuery('')
        SearchStore.update(state => {
            state.references.push({
                id: Helper.randomUid(),
                orderId: new Date().getTime(),
                labelType: SearchLabelEnum.Reference,
                comparisonCard: ComparisonCardEnum.Default,
                reference: reference.reference
            })
        })
    }

    const onTransitionEnd = (event: React.TransitionEvent) => {
        if (!listVisible) {
            setListState(ListStateEnum.Books)
        }
    }

    const renderBookSelection = () : JSX.Element => (
        <BookListContainer ref={bookListRef} visible={listState === ListStateEnum.Books}>
            <ListContainer>
                <ListTitle>
                    {'Old Testament'}
                </ListTitle>
                <BookList>
                    {
                        oldTestament.map(book => (
                            <BookListItem key={`ot-item-${book.name}`} onClick={() => onBookItemSelect(book)}>
                                {book.name}
                            </BookListItem>
                        ))
                    }
                </BookList>
            </ListContainer>
            <ListContainer>
                <ListTitle>
                    {'New Testament'}
                </ListTitle>
                <BookList>
                    {
                        newTestament.map(book => (
                            <BookListItem key={`nt-item-${book.name}`} onClick={() => onBookItemSelect(book)}>
                                {book.name}
                            </BookListItem>
                        ))
                    }
                </BookList>
            </ListContainer>
        </BookListContainer>
    )

    const renderChapterSelection = (book: IBook) : JSX.Element => (
        <ChapterListContainer visible={listState === ListStateEnum.Chapters}>
            <ListContainer>
                <ListTitle>
                    <StyledArrowLeftIcon onClick={() => setListState(ListStateEnum.Books)} />
                    {book.name + ' Chapter'}
                </ListTitle>
                <ChapterList>
                    {
                        book.chapters.length > 1 &&
                        <ChapterListItem onClick={() => onAllChapterSelect(book)}>
                            {'ALL'}
                        </ChapterListItem>
                    }
                    {
                        book.chapters.map(chapter => (
                            <ChapterListItem key={`book-item-${chapter.num}`} onClick={() => onChapterItemSelect(chapter)}>
                                {chapter.num}
                            </ChapterListItem>
                        ))
                    }
                </ChapterList>
            </ListContainer>
        </ChapterListContainer>
    )

    const renderArbitrarySelection = (options: ISearchTermResult[]) : JSX.Element => (
        <ArbitraryListContainer>
            <ListContainer>
                <ListTitle>
                    {'Search Results'}
                </ListTitle>
                <ArbitraryList>
                    {
                        referenceResults.map(ref => (
                            <BookListItem key={`reference-item-${ref.text}`} onClick={() => onReferenceSelect(ref)}>
                                {ref.text}
                            </BookListItem>
                        ))
                    }
                    {
                        options.map(option => (
                            <ArbitraryListItem key={`arbitrary-item-${option.text}`} onClick={() => onTermItemSelect(option)}>
                                {`${option.text} (${option.count})`}
                            </ArbitraryListItem>
                        ))
                    }
                    {
                        referenceResults.length === 0 &&
                        options.length === 0 &&
                        <NoResultText>
                            {'No results found'}
                        </NoResultText>
                    }
                </ArbitraryList>
            </ListContainer>
            <ListContainer>
                <ListTitle>{'Tags'}</ListTitle>
                <TagList>
                    {
                        tags.filter(t => t.text.toLowerCase()
                            .includes(query))
                            .map(tag => (
                                <TagListItem key={`tag-item-${tag._id}`} onClick={() => onTagItemSelect(tag)}>
                                    <BibleTag tag={tag} />
                                </TagListItem>
                            ))
                    }
                </TagList>
            </ListContainer>
        </ArbitraryListContainer>
    )

    return (
        <Container visible={listVisible}>
            <ScrollContainer onTransitionEnd={onTransitionEnd}>
                {
                    listState === ListStateEnum.Arbitrary ?
                        renderArbitrarySelection(options ?? []) :
                        <SlideContainer style={{transform: `translateX(${listState === ListStateEnum.Chapters ? -100 : 0}%)`}}>
                            {renderBookSelection()}
                            {
                                book &&
                                renderChapterSelection(book)
                            }
                        </SlideContainer>
                }
            </ScrollContainer>
            {
                <BibleVersionCell onClick={() => setShowBibleVersionMenu(true)}>
                    <BibleVersion>
                        {BibleVersionEnum[homeBibleVersion]}
                    </BibleVersion>
                    {
                        showBibleVersionMenu &&
                        <BibleVersionMenu defaultVersion={homeBibleVersion} closeMenu={() => setShowBibleVersionMenu(false)} />
                    }
                </BibleVersionCell>
            }
        </Container>
    )
}

export default SearchList