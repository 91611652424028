import { Icon } from 'components/common/icon'
import React, { HTMLAttributes } from 'react'

const CornerArrowLeft = (props: HTMLAttributes<SVGElement>) => (
    <Icon {...props} viewBox='0 0 24 24'>
        <polyline points='14 15 9 20 4 15' />
        <path d='M20 4h-7a4 4 0 0 0-4 4v12' />
    </Icon>
)

export default CornerArrowLeft