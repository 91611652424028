import { Icon } from 'components/common/icon'
import React, { HTMLAttributes } from 'react'

const ClosedBookIcon = (props: HTMLAttributes<SVGElement>) => (
    <Icon {...props} viewBox='0 0 24 24'>
        <path d='M4 19.5A2.5 2.5 0 0 1 6.5 17H20' />
        <path d='M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z' />
    </Icon>
)

export default ClosedBookIcon