import React, { useRef } from 'react'
import { UIStore } from 'stores/ui-store'
import styled, { keyframes } from 'styled-components'
import { styles } from 'styles/styles'

const BackgroundOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: ${styles.zIndex.modal};
    background-color: rgba(0,0,0,.25);
    overflow: hidden;
`
const FlyInAnimation = keyframes`
    from { transform: translateY(100%); }
    to { transform: translateY(0); }
`
const ModalWrapper = styled.div`
    height: 100%;
    width: 100%;
    
    animation: ${FlyInAnimation} .4s ease forwards;
`

const ModalRenderer = () => {
    const modal = UIStore.useState(state => state.modal)

    const overlayRef = useRef<HTMLDivElement>(null)

    const onOverlayClick = (event: React.MouseEvent) => {
        if (event.target === overlayRef.current) {
            UIStore.update(state => {
                state.modal = undefined
            })
        }
    }

    if (!modal) return null

    return (
        <BackgroundOverlay>
            <ModalWrapper ref={overlayRef} onClick={onOverlayClick}>
                {modal}
            </ModalWrapper>
        </BackgroundOverlay>
    )
}

export default ModalRenderer