import { useDroppable } from '@dnd-kit/core'
import BiblePassage from 'components/bible-passage'
import { ComparisonCardEnum, DragTargetEnum } from 'enums/ui'
import Helper from 'helpers/helper'
import React, { useState } from 'react'
import BibleService from 'services/bible-service'
import { SearchStore } from 'stores/search-store'
import styled from 'styled-components'
import { styles } from 'styles/styles'

const BibleCardWrapper = styled.div`
    background-color: ${p => p.theme.backgrounds.white};
    box-shadow: ${p => p.theme.shadows.medium};
    border-radius: ${styles.borderRadius.default};
    min-width: 0;
    min-height: 0;
    overflow: auto;
`
const ScrollContainer = styled.div`
    position: relative;
    height: 100%;
    overflow-y: auto;
    box-shadow: ${p => p.theme.shadows.medium};
    border-radius: ${styles.borderRadius.default};
`
const BiblePassageContainer = styled.div`
    background-color: ${p => p.theme.backgrounds.white};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: ${styles.borderRadius.default};
`
const DropAreaContainer = styled.div`
    padding: 2rem;
    width: 100%;
    height: 100%;
`
const DropArea = styled.svg`
    width: 100%;
    height: 100%;
    overflow: visible;
`
const DropRect = styled.rect`
    fill: ${p => p.theme.backgrounds.input};
    stroke: ${p => p.theme.backgrounds.inputDark};
    stroke-width: 3;
    stroke-dasharray: 6 14;
    stroke-dashoffset: 0;
    stroke-linecap: round;
`
const DropText = styled.text`
    font-size: ${styles.fontSizes.large};
    fill: ${p => p.theme.colors.textInput};
    stroke: none;
    dominant-baseline: middle;
    text-anchor: middle;
`

type Props = {
    comparisonCard: ComparisonCardEnum
    gridArea: string
}

const BibleReaderCard = (props: Props) => {
    const { gridArea, comparisonCard } = props

    const { references, tags, terms } = SearchStore.useState(state => ({
        references: state.references.filter(a => a.comparisonCard === comparisonCard),
        tags: state.tags.filter(a => a.comparisonCard === comparisonCard),
        terms: state.terms.filter(a => a.comparisonCard === comparisonCard)
    }))

    const passages = BibleService.getPassagesBySearch(references, tags, terms)

    return (
        <BibleCardWrapper style={{gridArea}}>
            <ScrollContainer>
                {
                    passages.length === 0 &&
                    <BibleReaderDroppable comparisonCard={comparisonCard} />
                }
                <BiblePassageContainer>
                    {
                        passages.length > 0 &&
                        passages.map((passage, index) => (
                            <BiblePassage
                                key={`passage-${BibleService.getPassageIdByReference({
                                    version: passage.version,
                                    bookId: passage.bookId,
                                    chapterNr: passage.chapter.num,
                                    subset: passage.subset
                                })}`}
                                passage={passage}
                                defaultCollapsed={index > 15}
                            />
                        ))
                    }
                </BiblePassageContainer>
            </ScrollContainer>
        </BibleCardWrapper>
    )
}

export default BibleReaderCard

type DroppableProps = {
    comparisonCard: ComparisonCardEnum
}

const BibleReaderDroppable = (props: DroppableProps) => {
    const { comparisonCard } = props

    const [dragId] = useState<string>(Helper.randomUid())

    const { setNodeRef } = useDroppable({
        id: dragId,
        data: {
            type: DragTargetEnum.ComparisonCard,
            card: comparisonCard
        }
    })

    return (
        <DropAreaContainer ref={setNodeRef}>
            <DropArea>
                <DropRect width='100%' height='100%' rx='1rem' ry='1rem' />
                <DropText x='50%' y='50%'>{'Drag & Drop Passage here'}</DropText>
            </DropArea>
        </DropAreaContainer>
    )
}