class RegularExpressions {
    public static invalidQueryChars = new RegExp(`[${[
        '(', ')', '\'', '{', '}'
    ].join('')}]`, 'gim')

    public static invalidVerseChars = new RegExp(`[${[
        '?', '.', ':', ',', ';', '"', '\'', '(', ')', '{', '}'
    ].join('')}]`, 'gim')

    public static validBiblePassage = new RegExp('[0-9]+(:[0-9]+(-[0-9]+)?)?$', 'gim')
}

export default RegularExpressions