import MoreDotsIcon from 'components/icons/more-dots-icon'
import RemoveIcon from 'components/icons/remove-icon'
import SearchIcon from 'components/icons/search-icon'
import { SearchLabelEnum } from 'enums/ui'
import useClickOutside from 'hooks/useClickOutside'
import useKeyboard from 'hooks/useKeyboard'
import React, { useMemo, useRef, useState } from 'react'
import { SearchStore } from 'stores/search-store'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import { IBook } from 'types/bible'
import { ISearchReference, ISearchTag, ISearchTerm, ISearchTermResult } from 'types/search'
import SearchList from 'views/overview/search-list'
import SearchReference from 'views/overview/search-reference'
import SearchTag from 'views/overview/search-tag'
import SearchTerm from 'views/overview/search-term'
import BibleSearchLabelMenu from './bible-search-label-menu'

const SearchContainer = styled.div<{focus?: boolean}>`
    position: relative;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    column-gap: 1rem;
    min-width: 40vw;
    min-height: 5rem;
    border-radius: 5rem;
    padding-left: 3rem;
    padding-right: 1rem;
    background-color: ${p => p.focus ? p.theme.backgrounds.white : p.theme.backgrounds.input};
    margin-right: 1rem;
    transition: background-color .2s ease;
`
const StyledSearchIcon = styled(SearchIcon)`
    stroke: ${p => p.theme.colors.textInput};
    font-size: 1.25em;
    margin-right: 1rem;
    flex-shrink: 0;
`
const SearchInput = styled.input`
    position: relative;
    font-family: ${styles.fonts.default};
    flex-grow: 1;
    height: 5rem;
    border: none;
    font-size: 1.25em;
    outline: none;
    padding-left: 1rem;
    background-color: transparent;

    &::placeholder {
        color: ${p => p.theme.colors.textInput};
        font-family: ${styles.fonts.default};
    }
`
const ClearButtonContainer = styled.div`
    border-radius: 50%;
    cursor: pointer;
    display: grid;
    place-items: center;
    padding-right: 1rem;
`
const StyledClearButtonIcon = styled(RemoveIcon)`
    stroke: ${p => p.theme.colors.textInput};

    &:hover {
        stroke: ${p => p.theme.colors.text};
    }
`
const AdditionalLabels = styled.div`
    position: relative;
    font-family: ${styles.fonts.default};
    font-weight: 400;
    display: grid;
    place-items: center;
    padding: .5rem;
    width: 5rem;
    border-radius: 25rem;
    white-space: nowrap;
    background-color: ${p => p.theme.colors.primaryBright};
    cursor: pointer;

    &:hover {
        background-color: ${p => p.theme.colors.primary};
    }
`
const StyledMoreIcon = styled(MoreDotsIcon)`
    stroke: ${p => p.theme.backgrounds.white};
    stroke-width: 2;
`

const maxSearchLabelCount = 5

const BibleSearch = () => {
    const { references, tags, terms} = SearchStore.useState(state => state)
    
    const ignoreRef = useRef<HTMLDivElement>(null)
    const [query, setQuery] = useState<string>('')
    const [listVisible, setListVisible] = useState<boolean>(false)
    const [listOptions, setListOptions] = useState<ISearchTermResult[]>()
    const [selectedBook, setSelectedBook] = useState<IBook>()
    const [focus, setFocus] = useState<boolean>(false)
    const [showMoreLabelsMenu, setShowMoreLabelsMenu] = useState<boolean>(false)

    const bibleSearchWorker = useMemo(() => {
        const worker = new Worker(new URL('worker.ts', import.meta.url))

        worker.onmessage = (event: MessageEvent<ISearchTermResult[]>) => {
            const { data } = event
            setListOptions(data)
        }

        return worker
    }, [])

    const searchLabels: (ISearchTag | ISearchTerm | ISearchReference)[] = [...references, ...tags, ...terms].sort((a, b) => a.orderId - b.orderId)
    
    const onEnterKey = () => {
        setListVisible(false)
    }
    const onEscapeKey = () => {
        setListVisible(false)
        setQuery('')
    }

    const onKeyDown = useKeyboard({onEnterKey, onEscapeKey})
    useClickOutside(() => setListVisible(false), ignoreRef)

    const resetSearchInput = () => {
        setQuery('')
        setSelectedBook(undefined)
    }
    const onInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchStr = event.target.value.trim()

        if (searchStr.length > 2) {
            bibleSearchWorker.postMessage(searchStr)
        } else {
            setListOptions([])
        }

        setQuery(event.target.value)
    }

    const onInputClick = () => {
        setListVisible(true)
    }

    const onFocus = () => {
        setFocus(true)
    }

    const onBlur = () => {
        setFocus(false)
    }

    const renderSearchLabel = (searchLabel: ISearchReference | ISearchTag | ISearchTerm) : JSX.Element => {
        switch (searchLabel.labelType) {
            default:
                return <></>
                
            case SearchLabelEnum.Tag:
                return <SearchTag key={`search-label-${searchLabel.orderId}`} tag={searchLabel as ISearchTag} />

            case SearchLabelEnum.Term:
                return <SearchTerm key={`search-label-${searchLabel.orderId}`} term={searchLabel as ISearchTerm} />

            case SearchLabelEnum.Reference:
                return <SearchReference key={`search-label-${searchLabel.orderId}`} reference={searchLabel as ISearchReference} />
        }
    }

    const visibleSearchLabels = searchLabels.slice(0, maxSearchLabelCount)
    const hiddenSearchLabels = searchLabels.slice(maxSearchLabelCount)

    return (
        <SearchContainer ref={ignoreRef} focus={focus}>
            <StyledSearchIcon />
            {visibleSearchLabels.map(label => renderSearchLabel(label))}
            {
                visibleSearchLabels.length < searchLabels.length &&
                <AdditionalLabels onClick={() => setShowMoreLabelsMenu(true)}>
                    <StyledMoreIcon />
                    {
                        showMoreLabelsMenu &&
                        <BibleSearchLabelMenu closeMenu={() => setShowMoreLabelsMenu(false)}>
                            {
                                hiddenSearchLabels.map(label => renderSearchLabel(label))
                            }
                        </BibleSearchLabelMenu>
                    }
                </AdditionalLabels>
            }
                
            <SearchInput
                value={query}
                placeholder={references.length === 0 ? 'Search Passage' : 'Add Passage'}
                onInput={onInput}
                onChange={onInput}
                onClick={onInputClick}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                onBlur={onBlur}
            />

            {
                query.length > 0 &&
                <ClearButtonContainer onClick={() => resetSearchInput()}>
                    <StyledClearButtonIcon />
                </ClearButtonContainer>
            }

            <SearchList
                listVisible={listVisible}
                setListVisible={setListVisible}
                book={selectedBook}
                setSelectedBook={setSelectedBook}
                query={query.toLowerCase()}
                options={listOptions}
                setQuery={setQuery}
            />
        </SearchContainer>
    )
}

export default BibleSearch