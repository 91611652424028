import BibleTag from 'components/bible-tag'
import ChapterSummary from 'components/chapter-summary'
import ChevronDownIcon from 'components/icons/chevron-down-icon'
import MoreDotsIcon from 'components/icons/more-dots-icon'
import { BibleVersionEnum } from 'enums/bible'
import React, { useMemo, useState } from 'react'
import BibleService from 'services/bible-service'
import { SearchStore } from 'stores/search-store'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import { ITagEntity } from 'types/entity'
import { IPassage, IReference, ISubset } from 'types/verse'
import BiblePassageMenu from './bible-passage-menu'
import BibleVersionMenu from './bible-version-menu'
import CollapseDownIcon from './icons/collapse-down-icon'
import RemoveCircleIcon from './icons/remove-circle-icon'
import PassageSearch from './passage-search'

const Container = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    display: grid;
    grid-template-columns: 6rem auto;
    align-items: center;
    background-color: white;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-top-left-radius: ${styles.borderRadius.default};
    border-top-right-radius: ${styles.borderRadius.default};
    border-bottom: .2rem solid ${p => p.theme.backgrounds.content};
`
const BibleVersionContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
    cursor: pointer;
`
const BibleVersion = styled.span`
    border-radius: ${styles.borderRadius.small};
    border: .2rem solid ${p => p.theme.backgrounds.inputDark};
    padding: .25rem .5rem;
    color: ${p => p.theme.backgrounds.inputDark};
    font-size: ${styles.fontSizes.small};
    font-family: ${styles.fonts.bold};
    font-weight: 700;
    letter-spacing: ${p => p.theme.letterSpacing.medium};
`
const ChapterCell = styled.div`
    text-align: left;
    display: flex;
    align-items: center;
    width: 100%;
`
const ChapterContainer = styled.div`
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    gap: .5rem;
`
const ChapterNavigator = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`
const NextChapterContainer = styled.span<{disabled?: boolean}>`
    display: inline-flex;
    transform: rotate(-90deg);
    cursor: pointer;
    pointer-events: ${p => p.disabled ? 'none' : 'auto'};
    font-size: .75em;

    svg {
        stroke: ${p => p.disabled ? p.theme.backgrounds.inputDark : p.theme.colors.text};
    }
`
const PreviousChapterContainer = styled.span<{disabled?: boolean}>`
    display: inline-flex;
    transform: rotate(90deg);
    cursor: pointer;
    pointer-events: ${p => p.disabled ? 'none' : 'auto'};
    font-size: .75em;

    svg {
        stroke: ${p => p.disabled ? p.theme.backgrounds.inputDark : p.theme.colors.text};
    }
`
const ChapterTitle = styled.h3`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: ${styles.fonts.bold};
    font-size: ${styles.fontSizes.large};
    font-weight: 700;
    margin: 0;
    color: ${p => p.theme.colors.text};
    user-select: text;
`
const RightMenuSection = styled.div`
    display: inline-flex;
    justify-content: flex-end;
    align-items: flex-start;
    column-gap: 4rem;
    margin-left: auto;
    padding-right: 1rem;
`
const MoreDotsIconContainer = styled.div`
    position: relative;
    line-height: 0;
    cursor: pointer;
`
const StyledCollapseDownIcon = styled(CollapseDownIcon)<{collapsed: boolean}>`
    cursor: pointer;
    font-size: .75em;
    transform: rotate(${p => p.collapsed ? 180 : 0}deg);
`
const StyledRemoveCircleIcon = styled(RemoveCircleIcon)`
    cursor: pointer;
    font-size: 1em;
`

type Props = {
    passage: IPassage
    chapterTags: ITagEntity[]
    collapsed: boolean
    setCollapsed: (collapsed: boolean) => void
    passageSearchTerm: string
    setPassageSearchTerm: (value: string) => void
}

const BiblePassageHeader = (props: Props) => {
    const { passage, chapterTags, collapsed, passageSearchTerm } = props
    const { setPassageSearchTerm, setCollapsed } = props

    const maxChapters = useMemo(() => BibleService.getBookById(passage.version, passage.bookId).chapters.length, [passage.bookId, passage.version])

    const [showMoreMenu, setShowrMoreMenu] = useState<boolean>(false)
    const [showBibleVersionMenu, setShowBibleVersionMenu] = useState<boolean>(false)
    const passageSubset: ISubset = passage.subset ? passage.subset : {
        startVerse: 1,
        endVerse: passage.chapter.verses.length
    }

    const onToggleCollapse = (event: React.MouseEvent) => {
        setCollapsed(!collapsed)

        // const table = event.currentTarget.closest('table')!
        // table.scrollIntoView({behavior: 'smooth', block: 'start'})
    }

    const onPrevChapterClick = () => {
        SearchStore.update(state => {
            const searchPassage = state.references.find(r => r.orderId === passage.orderId)
            if (searchPassage && searchPassage.reference.chapterNr && searchPassage.reference.chapterNr > 1) {
                searchPassage.reference.chapterNr--
                searchPassage.reference.subset = undefined
            }
        })
    }

    const onNextChapterClick = () => {
        SearchStore.update(state => {
            const searchPassage = state.references.find(r => r.orderId === passage.orderId)
            if (searchPassage && searchPassage.reference.chapterNr) {
                if (searchPassage.reference.chapterNr < maxChapters) {
                    searchPassage.reference.chapterNr++
                    searchPassage.reference.subset = undefined
                }
            }
        })
    }

    const onRemoveClick = () => {
        SearchStore.update(state => {
            state.references = state.references.filter(r => r.orderId !== passage.orderId)
        })
    }

    const passageReference: IReference = {
        version: passage.version,
        bookId: passage.bookId,
        chapterNr: passage.chapter.num,
        subset: passage.subset ?? passageSubset
    }

    const passageTitle = BibleService.convertReferenceToString(passageReference)

    return (
        <Container data-passageid={BibleService.getPassageIdByReference(passageReference)}>
            <BibleVersionContainer onClick={() => setShowBibleVersionMenu(true)}>
                <BibleVersion>
                    {BibleVersionEnum[passage.version]}
                </BibleVersion>
                {
                    showBibleVersionMenu &&
                    <BibleVersionMenu defaultVersion={passage.version} closeMenu={() => setShowBibleVersionMenu(false)} />
                }
            </BibleVersionContainer>
            <ChapterCell>
                <ChapterContainer>
                    <ChapterNavigator>
                        <PreviousChapterContainer disabled={passage.chapter.num === 1} onClick={onPrevChapterClick}>
                            <ChevronDownIcon />
                        </PreviousChapterContainer>

                        <NextChapterContainer disabled={passage.chapter.num === maxChapters} onClick={onNextChapterClick}>
                            <ChevronDownIcon />
                        </NextChapterContainer>
                    </ChapterNavigator>

                    <ChapterTitle>
                        {passageTitle}
                    </ChapterTitle>
                    
                    <StyledCollapseDownIcon collapsed={collapsed} onClick={onToggleCollapse} />
                    <ChapterSummary />
                    {
                        chapterTags.map(tag => (
                            <BibleTag
                                key={`chapter-${passage.chapter.num}-tag-${tag._id}`}
                                tag={tag}
                                reference={{
                                    version: BibleVersionEnum.NKVJ,
                                    bookId: passage.bookId,
                                    chapterNr: passage.chapter.num
                                }}
                            />))
                    }
                </ChapterContainer>
                <RightMenuSection>
                    <PassageSearch value={passageSearchTerm} setValue={setPassageSearchTerm} />
                    <MoreDotsIconContainer>
                        <MoreDotsIcon onClick={() => setShowrMoreMenu(true)} />
                        {
                            showMoreMenu &&
                            <BiblePassageMenu passage={passage} closeMenu={() => setShowrMoreMenu(false)} />
                        }
                    </MoreDotsIconContainer>
                    <StyledRemoveCircleIcon onClick={onRemoveClick} />
                </RightMenuSection>
            </ChapterCell>
        </Container>
    )
}

export default BiblePassageHeader