import BoldIcon from 'components/icons/bold-icon'
import CalendarIcon from 'components/icons/calendar-icon'
import CancelIcon from 'components/icons/cancel-icon'
import CheckCircleIcon from 'components/icons/check-circle-icon'
import ClosedBookIcon from 'components/icons/closed-book-icon'
import EditTextIcon from 'components/icons/edit-text-icon'
import EntryIconIndex from 'components/icons/entry-icon-index'
import ItalicIcon from 'components/icons/italic-icon'
import MoreDotsIcon from 'components/icons/more-dots-icon'
import UnderlineIcon from 'components/icons/underline-icon'
import UserIcon from 'components/icons/user-icon'
import { EntryIconEnum } from 'enums/icon'
import { ToastMessageEnum } from 'enums/ui'
import Helper from 'helpers/helper'
import { useEntryEntity } from 'hooks/pouchdb/useEntryEntity'
import React, { useRef, useState } from 'react'
import BibleService from 'services/bible-service'
import EntryService from 'services/entry-service'
import { EntryStore } from 'stores/entry-store'
import { UIStore } from 'stores/ui-store'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import { IEntryEntity } from 'types/entity'
import { IEntryValid } from 'types/entry'

const StyledMoreDotsIcon = styled(MoreDotsIcon)`
    font-size: 1em;
    margin-left: auto;
`
const Container = styled.div<{selected?: boolean, borderColor: string}>`
    border: .2rem solid ${p => p.theme.backgrounds.content};
    border-left: .5rem solid ${p => p.borderColor};
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: #1f1f1f;
    padding: .5rem 1rem .5rem 1.5rem;
    cursor: ${p => p.selected ? 'auto' : 'pointer'};
    border-radius: ${styles.borderRadius.default};
    max-height: ${p => p.selected ? 'auto' : '6rem'};
    max-width: 100%;

    transition: height .2s ease;
`
const ContextMenuContainer = styled.div`
    position: absolute;
    left: 100%;
    top: 50%;
`
const StyledIcon = styled(EntryIconIndex)`
    font-size: .85em;
    flex-shrink: 0;
`
const Header = styled.span`
    display: flex;
    align-items: center;
    column-gap: .5rem;
`
const Content = styled.p<{selected?: boolean}>`
    overflow: hidden;
    flex-grow: 1;
    outline: none;
    border: none;
    margin: 0;
    line-height: 1.25;
    padding: .5rem;

    ${p => p.selected ? `
        overflow-y: visible;
    ` : `
        text-overflow: ellipsis;
        white-space: nowrap;
    `}
`
const ToolbarContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 2rem;
    padding-top: .5rem;
    font-size: .75em;
    border-top: 1px solid lightgrey;
    margin-bottom: .5rem;
`
const StyledBoldIcon = styled(BoldIcon)`
    stroke-width: 3;
    cursor: pointer;
`
const StyledItalicIcon = styled(ItalicIcon)`
    cursor: pointer;
`
const StyledUnderlineIcon = styled(UnderlineIcon)`
    cursor: pointer;
`
const EditEntryOverlay = styled.div`
    position: absolute;
    left: 0;
    top 0;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    opacity: 0;
    background-color: rgba(255,255,255,.5);
    transition: opacity .2s ease;

    &:hover {
        opacity: 1;
    }
`
const EditTextIconContainer = styled.div`
    position: absolute;
    padding: 1rem;
    background-color: white;
    border-radius: 50%;
    display: grid;
    place-items: center;
`
const StyledCheckCircleIcon = styled(CheckCircleIcon)`
    margin-left: auto;
    font-size: 1.25em;
    stroke: green;
    stroke-width: 2;
    cursor: pointer;
`
const MetaDataContainer = styled.div`
    display: flex;
    align-items: center;
    font-family: ${styles.fonts.default};
    font-size: ${styles.fontSizes.small};
    font-weight: 400;
    column-gap: 2rem;
    margin-bottom: .5rem;
`
const MetaText = styled.span`
    color: #a2a2a2;
    display: flex;
    align-items: center;
    column-gap: .5rem;
`
const StyledCalendarIcon = styled(CalendarIcon)`
    stroke: #a2a2a2;
    font-size: .75em;
`
const StyledUserIcon = styled(UserIcon)`
    stroke: #a2a2a2;
    font-size: .85em;
`
const StyledReferenceIcon = styled(ClosedBookIcon)`
    stroke: #a2a2a2;
    font-size: .85em;
`
const TitleInput = styled.input`
    font-family: ${styles.fonts.bold};
    font-weight: 700;
    font-size: ${styles.fontSizes.default};
    border: none;
    outline: none;
    width: 100%;
`
const Title = styled.span`
    font-family: ${styles.fonts.bold};
    font-weight: 700;
    font-size: ${styles.fontSizes.default};
    border: none;
    outline: none;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`
const StyledCancelIcon = styled(CancelIcon)`
    stroke: red;
    font-size: 1.25em;
    stroke-width: 2;
    cursor: pointer;
    margin-left: auto;
`

type Props = {
    entry: IEntryEntity
}

const BibleEntryVerse = (props: Props) => {
    const { entry } = props

    const [showDotMenu, setShowDotMenu] = useState<boolean>(false)

    const { updateEntry } = useEntryEntity()
    
    const contentRef = useRef<HTMLParagraphElement>(null)
    const [icon, setIcon] = useState<EntryIconEnum>(entry.icon)
    const [title, setTitle] = useState<string>(entry.title)
    const [content, setContent] = useState<string>(entry.htmlContent)
    const [rerenderContent, setRerenderContent] = useState<number>(new Date().getTime())
    const [valid, setValid] = useState<IEntryValid>({
        title: true,
        content: true
    })
    const selectedEntry = EntryStore.useState(state => state.selectedEntry)
    const selected = selectedEntry?._id === entry._id

    const isTermEntry = entry.icon === EntryIconEnum.TermEntry
    //disable edit icon or title, color

    const onEntryClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        if (selected) return

        focusContent()

        EntryStore.update(state => {
            state.selectedEntry = entry
        })
    }

    const focusContent = () => {
        if (contentRef.current) {
            //put cursor at the end
            const selection = document.getSelection()
            if (selection && contentRef.current.childNodes.length > 0) {
                const range = document.createRange()
                range.setStart(contentRef.current.childNodes[0], 0)
                range.collapse(true)
                selection.removeAllRanges()
                selection.addRange(range)
            }

            //focus text input
            contentRef.current.tabIndex = 1
            contentRef.current.focus()
        }
    }

    const updateContent = () => {
        const innerHTML = contentRef.current?.innerHTML ?? ''
        setContent(innerHTML)

        setValid(prev => ({
            ...prev,
            content: innerHTML.length > 0
        }))
    }

    const onBoldClick = () => {
        document.execCommand('bold', false)
        updateContent()
    }

    const onItalicClick = () => {
        document.execCommand('italic', false)
        updateContent()
    }

    const onUnderlineClick = () => {
        document.execCommand('underline', false)
        updateContent()
    }

    const onLinkClick = () => {
        const url = '#'

        const selection = document.getSelection()
        const sText = selection?.toString()
        document.execCommand('insertHTML', false, '<a href="' + url + '" target="_blank">' + sText + '</a>')
        updateContent()
    }

    const onFinishClick = () => {
        if (title !== entry.title || content !== entry.htmlContent) {
            if (valid.title && valid.content) {
                updateEntry(entry._id, content, title)
    
                UIStore.update(state => {
                    state.toastMessages.push({
                        id: Helper.randomUid(),
                        text: 'Entry saved successfully',
                        type: ToastMessageEnum.Success
                    })
                })
            } else {
                setTitle(entry.title)
                setContent(entry.htmlContent)
                setRerenderContent(new Date().getTime())
    
                UIStore.update(state => {
                    state.toastMessages.push({
                        id: Helper.randomUid(),
                        text: 'Entry changes discarded',
                        type: ToastMessageEnum.Info
                    })
                })
            }
        }

        EntryStore.update(state => {
            state.selectedEntry = undefined
        })
    }

    const renderDotMenu = () => (
        <ContextMenuContainer>
            {'Delete Entry, Edit Entry, Rename Entry, change Type'}
        </ContextMenuContainer>
    )

    const onContentChange = (event: React.ChangeEvent<HTMLParagraphElement>) => {
        const nativeEvent = event.nativeEvent as any

        if (nativeEvent.inputType === 'formatBold' ||
            nativeEvent.inputType === 'formatItalic' ||
            nativeEvent.inputType === 'formatUnderline')
            return

        const innerHTML = contentRef.current?.innerHTML ?? ''
        setContent(innerHTML)

        setValid(prev => ({
            ...prev,
            content: innerHTML.length > 0
        }))

        setContent(innerHTML)
    }

    const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const title = event.target.value
        setValid(prev => ({
            ...prev,
            title: EntryService.validateEntryTitle(title)
        }))

        setTitle(title)
    }

    const reference = BibleService.convertReferenceToString(entry.reference!)
    const humanizedDate = Helper.humanizeDate(entry.createdOn)
    const trimmedContent = EntryService.trimHtmlContent(entry.htmlContent, 150)

    return (
        <Container borderColor={isTermEntry ? 'maroon' : '#ffd390'} selected={selected} onClick={onEntryClick}>
            {
                selected &&
                <MetaDataContainer>
                    <MetaText>
                        <StyledReferenceIcon />
                        {reference}
                    </MetaText>
                    <MetaText>
                        <StyledUserIcon />
                        {entry.createdBy}
                    </MetaText>
                    <MetaText>
                        <StyledCalendarIcon />
                        {humanizedDate}
                    </MetaText>
                    <StyledMoreDotsIcon onClick={() => setShowDotMenu(true)} />
                </MetaDataContainer>
            }

            <Header>
                <StyledIcon icon={icon} />
                {
                    isTermEntry ?
                        <Title>{title}</Title> :
                        <TitleInput value={title} onInput={onTitleChange} onChange={onTitleChange} />
                }
                
            </Header>
            <Content
                ref={contentRef}
                key={`content-${rerenderContent}`}
                onInput={onContentChange}
                onChange={onContentChange}
                contentEditable={selected}
                selected={selected}
                dangerouslySetInnerHTML={{__html: selected ? entry.htmlContent : trimmedContent}}
            />
            {
                selected &&
                <ToolbarContainer>
                    <StyledBoldIcon onClick={onBoldClick} />
                    <StyledItalicIcon onClick={onItalicClick} />
                    <StyledUnderlineIcon onClick={onUnderlineClick} />
                    {/* <LinkIcon onClick={onLinkClick} /> */}
                    {/* <CrossReferenceIcon /> */}

                    {
                        (valid.content && valid.title) ?
                            <StyledCheckCircleIcon onClick={onFinishClick} /> :
                            <StyledCancelIcon onClick={onFinishClick} />
                    }

                </ToolbarContainer>
            }
            {
                !selected &&
                <EditEntryOverlay>
                    <EditTextIconContainer>
                        <EditTextIcon />
                    </EditTextIconContainer>
                </EditEntryOverlay>
            }
            {
                showDotMenu &&
                renderDotMenu()
            }
        </Container>
    )
}

export default BibleEntryVerse