import { KeyboardButtonEnum } from 'enums/ui'
import React from 'react'

type UseKeyboardProps = {
    onEnterKey?: () => void
    onArrowUpKey?: () => void
    onArrowDownKey?: () => void
    onEscapeKey?: () => void
}

const useKeyboard = (props: UseKeyboardProps) : (event: React.KeyboardEvent) => void => {
    const { onEnterKey, onArrowUpKey, onArrowDownKey, onEscapeKey } = props

    const onKeyDown = (event: React.KeyboardEvent) => {
        switch (event.key) {
            default:
                return
            case KeyboardButtonEnum.Enter:
                return onEnterKey && onEnterKey()
            case KeyboardButtonEnum.ArrowUp:
                return onArrowUpKey && onArrowUpKey()
            case KeyboardButtonEnum.ArrowDown:
                return onArrowDownKey && onArrowDownKey()
            case KeyboardButtonEnum.Escape:
                return onEscapeKey && onEscapeKey()
        }
    }

    return onKeyDown
}

export default useKeyboard