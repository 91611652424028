import { Icon } from 'components/common/icon'
import React, { HTMLAttributes } from 'react'

const CrossHairIcon = (props: HTMLAttributes<SVGElement>) => (
    <Icon {...props} viewBox='0 0 24 24'>
        <circle cx='12' cy='12' r='10' />
        <line x1='22' y1='12' x2='18' y2='12' />
        <line x1='6' y1='12' x2='2' y2='12' />
        <line x1='12' y1='6' x2='12' y2='2' />
        <line x1='12' y1='22' x2='12' y2='18' />
    </Icon>
)

export default CrossHairIcon