import { Icon } from 'components/common/icon'
import React, { HTMLAttributes } from 'react'

const RemoveIcon = (props: HTMLAttributes<SVGElement>) => (
    <Icon {...props} viewBox='0 0 24 24'>
        <line x1='18' y1='6' x2='6' y2='18' />
        <line x1='6' y1='6' x2='18' y2='18' />
    </Icon>
)

export default RemoveIcon