import RemoveIcon from 'components/icons/remove-icon'
import { BibleVersionEnum } from 'enums/bible'
import { SearchLabelEnum, ToastMessageEnum } from 'enums/ui'
import Helper from 'helpers/helper'
import { useBookmarkEntity } from 'hooks/pouchdb/useBookmarkEntity'
import React, { useState } from 'react'
import BibleService from 'services/bible-service'
import { UIStore } from 'stores/ui-store'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import { IBookmarkEntity } from 'types/entity'
import { ISearchReference, ISearchTag, ISearchTerm } from 'types/search'
import SearchReference from 'views/overview/search-reference'
import SearchTag from 'views/overview/search-tag'
import SearchTerm from 'views/overview/search-term'

const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    border-radius: ${styles.borderRadius.default};
    background: white;
    padding: 2rem;
`
const Title = styled.h2`

`
const BookmarkNameInput = styled.input`

`
const ConfirmButton = styled.button<{disabled?: boolean}>`
    pointer-events: ${p => p.disabled ? 'none' : 'all'};
`
const StyledRemoveIconClick = styled(RemoveIcon)`
    cursor: pointer;
`
const BookmarkList = styled.div`

`
const ListItem = styled.div`
    line-height: 3rem;
    background-color: lightgrey;
    padding-left: 1rem;
    padding-right: 1rem;
`
const ReferenceLabel = styled.div`

`
const TermLabel = styled.div`

`
const TagLabel = styled.div`

`

type Props = {
    bookmarks: IBookmarkEntity[]
    references: ISearchReference[]
    tags: ISearchTag[]
    terms: ISearchTerm[]
}

const CreateBookmarkModal = (props: Props) => {
    const { bookmarks, references, tags, terms } = props

    const [bookmarkName, setBookmarkName] = useState<string>('')

    const searchLabels: (ISearchTag | ISearchTerm | ISearchReference)[] = [...references, ...tags, ...terms]
    searchLabels.sort((a, b) => a.orderId - b.orderId)

    const { createBookmark, updateBookmark } = useBookmarkEntity()

    const onCloseClick = () => {
        UIStore.update(state => {
            state.modal = undefined
        })
    }

    const onListItemClick = (bookmark: IBookmarkEntity) => {
        const tagIds = tags.map(t => t.tagId)
        const refs = references.map(r => r.reference)
        const searchTerms = terms.map(t => t.text)
        updateBookmark(bookmark._id, refs, tagIds, searchTerms)

        onCloseClick()

        UIStore.update(state => {
            state.toastMessages.push({
                id: Helper.randomUid(),
                text: 'Bookmark updated!',
                type: ToastMessageEnum.Success
            })
        })
    }

    const onConfirmClick = () => {
        const tagIds = tags.map(t => t.tagId)
        const refs = references.map(r => r.reference)
        const searchTerms = terms.map(t => t.text)
        createBookmark(bookmarkName, refs, tagIds, searchTerms)

        onCloseClick()

        UIStore.update(state => {
            state.toastMessages.push({
                id: Helper.randomUid(),
                text: 'Bookmark saved!',
                type: ToastMessageEnum.Success
            })
        })
    }

    const onNameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value
        setBookmarkName(text)
    }

    return (
        <Container>
            <StyledRemoveIconClick onClick={onCloseClick} />
            <Title>{'Create Bookmark'}</Title>
            {'List of Labels'}
            {
                searchLabels.map((label, index) => {
                    let item
                    switch (label.labelType) {
                        default:
                            return null
                            
                        case SearchLabelEnum.Tag:
                            item = label as ISearchTag
                            return (
                                <TagLabel key={`create-bookmark-label-${index}`}>
                                    {item.text}
                                </TagLabel>
                            )
                        case SearchLabelEnum.Term:
                            item = label as ISearchTerm
                            return (
                                <TermLabel key={`create-bookmark-label-${index}`}>
                                    {item.text}
                                </TermLabel>
                            )

                        case SearchLabelEnum.Reference:
                            item = label as ISearchReference
                            const referenceText = BibleService.convertReferenceToString(item.reference)
                        
                            return (
                                <ReferenceLabel key={`create-bookmark-label-${index}`}>
                                    {referenceText}
                                </ReferenceLabel>
                            )
                    }
                })
            }
            <BookmarkNameInput value={bookmarkName} onInput={onNameInput} onChange={onNameInput} />

            <ConfirmButton onClick={onConfirmClick} disabled={!bookmarkName}>
                {'Create'}
            </ConfirmButton>

            {'List of existing Bookmarks'}
            <BookmarkList>
                {
                    bookmarks.map(b => (
                        <ListItem
                            key={`create-bookmark-${b._id}`}
                            onClick={() => onListItemClick(b)}
                        >
                            {b.text}
                        </ListItem>))
                }
            </BookmarkList>
        </Container>
    )
}

export default CreateBookmarkModal