import React, { useState } from 'react'
import EntryIcon from 'components/icons/entry-icon'
import TagOffIcon from 'components/icons/tag-off-icon'
import { UIStore } from 'stores/ui-store'
import styled from 'styled-components'
import BibleSearch from 'views/overview/bible-search'
import EntryOffIcon from 'components/icons/entry-off-icon'
import TagIcon from 'components/icons/tag-icon'
import ColumnIcon from 'components/icons/column-icon'
import { ComparisonModeEnum } from 'enums/ui'
import TypographyIcon from 'components/icons/typography-icon'
import TypographyNoSerifsIcon from 'components/icons/typeography-noserifs-icon.tsx'
import { styles } from 'styles/styles'
import MaximizeIcon from 'components/icons/maximize-icon'
import MinimizeIcon from 'components/icons/minimize-icon'
import BibleComparisonMenu from './bible-comparison-menu'

const ToolbarContainer = styled.div`
    display: flex;
    padding: 1rem;
    background-color: ${p => p.theme.backgrounds.white};
    border-radius: ${styles.borderRadius.default};
    box-shadow: ${p => p.theme.shadows.medium};
`
const ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    column-gap: 2rem;
    margin-right: 2rem;
    margin-left: auto;
`
const TableModeMenuContainer = styled.div`
    position: relative;
    height: 1.5em;
`
const StyledColumnIcon = styled(ColumnIcon)`
    cursor: pointer;
`
const StyledEntryOffIcon = styled(EntryOffIcon)`
    cursor: pointer;
`
const StyledEntryIcon = styled(EntryIcon)`
    cursor: pointer;
`
const StyledTagOffIcon = styled(TagOffIcon)`
    cursor: pointer;
`
const StyledTagIcon = styled(TagIcon)`
    cursor: pointer;
`
const StyledTypographyIcon = styled(TypographyIcon)`
    cursor: pointer;
`
const StyledTypographyNoSerifsIcon = styled(TypographyNoSerifsIcon)`
    cursor: pointer;
`
const StyledMaximizeIcon = styled(MaximizeIcon)`
    cursor: pointer;
`
const StyledMinimizeIcon = styled(MinimizeIcon)`
    cursor: pointer;
`

const BibleToolbar = () => {
    console.log('BibleSearchInput')

    const {hideTags, hideEntries, fontSerifs, hideSidebar} = UIStore.useState(state => state)
    // const tableMode = ReaderStore.useState(state => state.tableMode)
    const [showTableMenu, setShowTableMenu] = useState<boolean>(false)

    const onHideTagsClick = () => {
        UIStore.update(state => {
            state.hideTags = !state.hideTags
        })
    }

    const onHideEntriesClick = () => {
        UIStore.update(state => {
            state.hideEntries = !state.hideEntries
        })
    }

    const onChangeTableMode = (mode: ComparisonModeEnum) => {
        // ReaderStore.update(state => {
        //     state.tableMode = mode
        // })
    }

    const onChangeFontSerifs = () => {
        UIStore.update(state => {
            state.fontSerifs = !state.fontSerifs
        })
    }

    const onMaximizeClick = () => {
        UIStore.update(state => {
            state.hideSidebar = !state.hideSidebar
        })
    }
    return (
        <ToolbarContainer>
            <BibleSearch />

            <ActionContainer>
                {
                    hideSidebar ?
                        <StyledMinimizeIcon onClick={onMaximizeClick} /> :
                        <StyledMaximizeIcon onClick={onMaximizeClick} />
                }
                {
                    <TableModeMenuContainer>
                        <StyledColumnIcon onClick={() => setShowTableMenu(prev => !prev)} />
                        {
                            showTableMenu &&
                            <BibleComparisonMenu closeMenu={() => setShowTableMenu(false)} />
                        }
                    </TableModeMenuContainer>
                }
                {
                    hideEntries ?
                        <StyledEntryOffIcon onClick={onHideEntriesClick} /> :
                        <StyledEntryIcon onClick={onHideEntriesClick} />
                }
                {
                    hideTags ?
                        <StyledTagOffIcon onClick={onHideTagsClick} /> :
                        <StyledTagIcon onClick={onHideTagsClick} />
                }
                {
                    fontSerifs ?
                        <StyledTypographyIcon onClick={onChangeFontSerifs} /> :
                        <StyledTypographyNoSerifsIcon onClick={onChangeFontSerifs} />
                }
            </ActionContainer>
        </ToolbarContainer>
    )
}

export default BibleToolbar