import CoffeeIcon from 'components/icons/coffee-icon'
import CompassIcon from 'components/icons/compass-icon'
import EntryIcon from 'components/icons/entry-icon'
import FeatherIcon from 'components/icons/feather-icon'
import PenIcon from 'components/icons/pen-icon'
import { EntryIconEnum } from 'enums/icon'
import React, { HTMLAttributes } from 'react'
import CornerArrowLeft from './corner-arrow-left'

type Props = HTMLAttributes<SVGElement> & {
    icon: EntryIconEnum
}

const EntryIconIndex = (props: Props) => {
    const { icon, ...attrs} = props

    switch (icon) {
        default:
        case EntryIconEnum.Default:
            return <EntryIcon {...attrs} />
        case EntryIconEnum.Pen:
            return <PenIcon {...attrs} />
        case EntryIconEnum.Feather:
            return <FeatherIcon {...attrs} />
        case EntryIconEnum.Compass:
            return <CompassIcon {...attrs} />
        case EntryIconEnum.Coffee:
            return <CoffeeIcon {...attrs} />
        case EntryIconEnum.TermEntry:
            return <CornerArrowLeft {...attrs} />
    }
}

export default EntryIconIndex