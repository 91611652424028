import { Icon } from 'components/common/icon'
import React, { HTMLAttributes } from 'react'

const UserIcon = (props: HTMLAttributes<SVGElement>) => (
    <Icon {...props} viewBox='0 0 24 24'>
        <path d='M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2' />
        <circle cx='12' cy='7' r='4' />
    </Icon>
)

export default UserIcon