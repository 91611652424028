import React from 'react'

const StudyGroups = () => {
    return (
        <>
            {'Follow Public Groups'}
            {'Create or Join Private Groups'}
            {'Be notified of updates'}
        </>
    )
}

export default StudyGroups