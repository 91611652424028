import AddCircleIcon from 'components/icons/add-circle-icon'
import { BibleBookEnum, BibleVersionEnum } from 'enums/bible'
import { EntryIconEnum } from 'enums/icon'
import { VerseCrossReferenceEnum } from 'enums/ui'
import React from 'react'
import BibleService from 'services/bible-service'
import { EntryStore } from 'stores/entry-store'
import { UIStore } from 'stores/ui-store'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import { IVerse } from 'types/bible'
import { ICrossReferenceEntity, IEntryEntity, IHighlightEntity } from 'types/entity'
import { ISearchTerm } from 'types/search'
import { IReference, IVerseReference, IVerseSection, IVerseSpecial, IVerseTag } from 'types/verse'
import StyledVerse from './styled-verse'

const TagLineContainer = styled.div`
    position: relative;
    grid-column: 1;
`
const TagLine = styled.div<{bgColor: string, level: number}>`
    position: absolute;
    top: .25rem;
    bottom: 1rem;
    left: 50%;
    width: .2rem;
    transform: translateX(-50%) translateX(-${p => p.level}rem);
    border-radius: 5rem;
    background-color: ${p => p.bgColor};
`
const TagLineDot = styled.div<{color: string, bgColor: string, level: number}>`
    position: absolute;
    top: .25rem;
    left: 50%;
    display: grid;
    place-items: center;
    color: ${p => p.color};
    font-size: ${styles.fontSizes.small};
    font-family: ${styles.fonts.bold};
    font-weight: 700;
    transform: translateX(-50%) translateX(-${p => p.level}rem);
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    background-color: ${p => p.bgColor};
    border: .2rem solid white;
`
const TagLineTail = styled.div<{bgColor: string, level: number}>`
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%) translateX(-${p => p.level}rem);
    border-radius: 50%;
    height: .5rem;
    width: .5rem;
    background-color: ${p => p.bgColor};
`
const NumberContainer = styled.div`
    grid-column: 2;
    padding-top: .5rem;
    text-align: right;
    user-select: none;
`
const Number = styled.div<{selected?: boolean}>`
    font-family: ${styles.fonts.bold};
    font-size: ${styles.fontSizes.smaller};
    color: ${p => p.selected ? p.theme.colors.primaryBright : p.theme.colors.text};
    font-weight: 700;
    line-height: 1.5;
    padding-right: 1rem;
    transform-origin: right;
    transition: transform .1s ease;

    ${p => p.selected && 'transform: scale(1.75) translateX(.5rem);'}
`
const VerseContainer = styled.div`
    display: contents;
    user-select: text;

    &:hover ${Number} {
        transform: scale(1.75) translateX(.5rem);
    }
`
const TextContainer = styled.div`
    grid-column: 3;
    font-size: clamp(.75em, 1vw, 1em);
`
const Text = styled.span<{selected?: boolean, fontSerifs?: boolean}>`
    font-family: ${p => p.fontSerifs ? styles.fonts.readerSerifs : styles.fonts.reader};
    color: ${p => p.theme.colors.text};
    line-height: 1.75;
    border-radius: .5rem;
    box-decoration-break: clone;
    scroll-margin-top: 6rem;

    ${p => p.selected && `
        background-image: linear-gradient(90deg, #959595 50%, white 0%);
        background-position: bottom;
        background-repeat: repeat-x;
        background-size: .25em .1em;
    `}
`
const AddEntryCell = styled.div`
    grid-column: 4;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: .4rem;
`
const StyledAddCircleIcon = styled(AddCircleIcon)`
    cursor: pointer;

    &:hover {
        stroke-width: 2;
    }
`

type Props = {
    version: BibleVersionEnum
    verse: IVerse
    bookId: BibleBookEnum
    chapterNr: number
    verseTags?: IVerseTag[]
    rowOffset?: number
    selected: boolean
    crossReferences?: ICrossReferenceEntity[]
    highlights?: IHighlightEntity[]
    entries?: IEntryEntity[]
    searchTerms?: ISearchTerm[]
    setSelected: (num: number) => void
}

const BibleVerse = (props: Props) => {
    const { version, verse, bookId, chapterNr, verseTags, selected } = props
    const { crossReferences, highlights, entries, searchTerms } = props
    const { setSelected } = props

    const fontSerifs = UIStore.useState(state => state.fontSerifs)

    const verseReference: IVerseReference = {
        version,
        bookId,
        chapterNr,
        verseNr: verse.num
    }

    const reference: IReference = {
        version,
        bookId,
        chapterNr,
        subset: {
            startVerse: verse.num,
            endVerse: verse.num
        }
    }

    const verseId = BibleService.getVerseIdByVerseReference(verseReference)

    const onVerseClick = () => {
        setSelected(verse.num)
    }

    const onAddEntryClick = () => {
        const defaultTitleText = BibleService.convertVerseReferenceToString(verseReference)

        EntryStore.update(state => {
            state.temporaryEntry = {
                htmlContent: '',
                icon: EntryIconEnum.Default,
                title: defaultTitleText,
                reference
            }

            state.selectedEntry = undefined
        })
    }

    const renderVerseSections = () : JSX.Element[] => {
        const termEntries = entries?.filter(e => e.icon === EntryIconEnum.TermEntry)

        const normalizedTermEntries = termEntries?.map<IVerseSpecial>(p => ({
            startOffset: p.reference!.subset!.startVerse < verse.num ? 0 : p.reference!.subset!.startOffset!,
            endOffset: p.reference!.subset!.endVerse > verse.num ? verse.text.length : p.reference!.subset!.endOffset!,
            termEntry: p
        })) ?? []

        const normalizedCrossReferences = crossReferences?.map<IVerseSpecial>(p => ({
            startOffset: p.reference!.subset!.startVerse < verse.num ? 0 : p.reference!.subset!.startOffset!,
            endOffset: p.reference!.subset!.endVerse > verse.num ? verse.text.length : p.reference!.subset!.endOffset!,
            crossReference: p
        })) ?? []

        const normalizedHighlights = highlights?.map<IVerseSpecial>(p => ({
            startOffset: p.reference!.subset!.startVerse < verse.num ? 0 : p.reference!.subset!.startOffset!,
            endOffset: p.reference!.subset!.endVerse > verse.num ? verse.text.length : p.reference!.subset!.endOffset!,
            highlight: p
        })) ?? []

        const normalizedSearchTerms: IVerseSpecial[] = []
        const verseTextLower = verse.text.toLowerCase()
        for (const searchTerm of searchTerms ?? []) {
            const searchTermLower = searchTerm.text.toLowerCase()
            let pos = 0
            while (verseTextLower.indexOf(searchTermLower, pos) !== -1) {
                const index = verseTextLower.indexOf(searchTermLower, pos)
                normalizedSearchTerms.push({
                    startOffset: index,
                    endOffset: index + searchTerm.text.length,
                    searchTerm
                })
                pos = verseTextLower.indexOf(searchTermLower, pos) + 1
            }
        }

        const specialsSortComparer = (a: IVerseSpecial, b: IVerseSpecial) => a.startOffset - b.startOffset || a.endOffset - b.endOffset

        const sortedSpecials = [...normalizedTermEntries, ...normalizedCrossReferences, ...normalizedHighlights, ...normalizedSearchTerms]
        sortedSpecials.sort(specialsSortComparer)
        
        if (sortedSpecials.length === 0) {
            return [<StyledVerse key={`verse-section-${verse.num}`} reference={reference} text={verse.text} />]
        }

        const verseSections: IVerseSection[] = []

        let currentOffset = 0
        while (currentOffset < verse.text.length) {
            const startOffset = currentOffset

            let nextSectionStartOffset = startOffset

            const lowestStartOffset = sortedSpecials.find(s => s.startOffset > nextSectionStartOffset)?.startOffset
            const lowestEndOffset = sortedSpecials.find(s => s.endOffset > nextSectionStartOffset)?.endOffset

            if (!!lowestStartOffset && !lowestEndOffset) {
                nextSectionStartOffset = lowestStartOffset
            } else if (!lowestStartOffset && !!lowestEndOffset) {
                nextSectionStartOffset = lowestEndOffset
            } else if (!!lowestStartOffset && !!lowestEndOffset) {
                nextSectionStartOffset = Math.min(lowestStartOffset, lowestEndOffset)
            }

            const sectionReference: IReference = {
                ...reference,
                subset: {
                    startVerse: verse.num,
                    endVerse: verse.num,
                    startOffset: currentOffset,
                    endOffset: nextSectionStartOffset
                }
            }

            if (nextSectionStartOffset === startOffset && startOffset < verse.text.length) {
                verseSections.push({
                    text: verse.text.substr(startOffset),
                    reference: sectionReference
                })
                break
            }

            const text = verse.text.substr(startOffset, nextSectionStartOffset - startOffset)
            const section: IVerseSection = {
                text,
                reference: sectionReference
            }

            const specials = sortedSpecials.filter(s => s.startOffset === startOffset)
            for (const special of specials) {
                if (special.highlight) {
                    section.highlight = special.highlight
                } else if (special.crossReference) {
                    const showIcon = startOffset === special.crossReference.reference.subset?.startOffset
                    section.crossReference = showIcon ? VerseCrossReferenceEnum.ShowIcon : VerseCrossReferenceEnum.HideIcon
                } else if (special.termEntry) {
                    section.termEntry = special.termEntry
                } else if (special.searchTerm) {
                    section.searchTerm = special.searchTerm
                } else {
                    throw new Error('Invalid Verse Section')
                }

                if (special.endOffset > nextSectionStartOffset) {
                    special.startOffset = nextSectionStartOffset
                }
            }

            verseSections.push(section)
            currentOffset = nextSectionStartOffset
            sortedSpecials.sort(specialsSortComparer)
        }

        return verseSections.map((section, index) => (
            <StyledVerse key={`verse-section-${verse.num}-${index}`} {...section} />
        ))
    }
    
    return (
        <VerseContainer onClick={onVerseClick}>
            {
                verseTags &&
                verseTags.reverse().map(verseTag => {
                    const rowSpan = (verseTag.reference?.subset?.endVerse ?? 0) - (verseTag.reference?.subset?.startVerse ?? 0) + 1

                    return (
                        <TagLineContainer key={`verse-tag-${verseTag.tag._id}`} style={{gridRow: `${verse.num} / span ${rowSpan}`, transform: `translateX(${3}rem)`}}>
                            <TagLine
                                key={`tag-circle-${verseTag.tag._id}`}
                                bgColor={verseTag.tag.color.secondary}
                                level={verseTag.level}
                            />
                            <TagLineTail
                                bgColor={verseTag.tag.color.secondary}
                                level={verseTag.level}
                            />
                            <TagLineDot
                                color={verseTag.tag.color.primary}
                                bgColor={verseTag.tag.color.secondary}
                                level={verseTag.level}
                            >
                                {verseTag.tag.text[0]}
                            </TagLineDot>
                        </TagLineContainer>
                    )
                })
            }
          
            <NumberContainer>
                <Number selected={selected}>
                    {verse.num}
                </Number>
            </NumberContainer>
            <TextContainer>
                <Text selected={selected} fontSerifs={fontSerifs} data-verseid={verseId}>
                    {renderVerseSections()}
                </Text>
            </TextContainer>
            <AddEntryCell>
                {
                    selected &&
                    <StyledAddCircleIcon onClick={onAddEntryClick} />
                }
            </AddEntryCell>
        </VerseContainer>
    )
}

export default BibleVerse