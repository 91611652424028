import { Icon } from 'components/common/icon'
import React, { HTMLAttributes } from 'react'

const DashboardIcon = (props: HTMLAttributes<SVGElement>) => (
    <Icon {...props} viewBox='0 0 24 24'>
        <rect x='3' y='3' width='7' height='7' />
        <rect x='14' y='3' width='7' height='7' />
        <rect x='14' y='14' width='7' height='7' />
        <rect x='3' y='14' width='7' height='7' />
    </Icon>
)

export default DashboardIcon