import RemoveIcon from 'components/icons/remove-icon'
import { useTagEntity } from 'hooks/pouchdb/useTagEntity'
import React, { useState } from 'react'
import { UIStore } from 'stores/ui-store'
import styled from 'styled-components'
import { predefinedColors } from 'styles/colors'
import { styles } from 'styles/styles'
import { IColor } from 'types/ui'

const Container = styled.div<{bgColor: string}>`
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    transform: translate(-50%, -50%);
    border-radius: ${styles.borderRadius.default};
    background-color: ${p => p.bgColor};
    padding: 2rem;
`
const Title = styled.h2`

`
const TagNameInput = styled.input`

`
const TagColorInput = styled.input`

`
const ConfirmButton = styled.button<{disabled?: boolean}>`
    pointer-events: ${p => p.disabled ? 'none' : 'all'};
`
const StyledRemoveIconClick = styled(RemoveIcon)`
    cursor: pointer;
`

const CreateTagModal = () => {
    const [tagName, setTagName] = useState<string>('')
    const [tagColor, setTagColor] = useState<IColor>(predefinedColors[3])

    const { createTag } = useTagEntity()

    const onCloseClick = () => {
        UIStore.update(state => {
            state.modal = undefined
        })
    }

    const onNameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value
        setTagName(text)
    }

    const onConfirmClick = () => {
        createTag(tagName, tagColor)
        onCloseClick()
    }

    return (
        <Container bgColor={tagColor.secondary}>
            <StyledRemoveIconClick onClick={onCloseClick} />
            <Title>{'Create Tag'}</Title>
            <TagNameInput value={tagName} onInput={onNameInput} onChange={onNameInput} />
            <TagColorInput value={tagColor.primary} />
            <ConfirmButton onClick={onConfirmClick} disabled={!tagName}>
                {'Confirm'}
            </ConfirmButton>
        </Container>
    )
}

export default CreateTagModal