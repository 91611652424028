import { Icon } from 'components/common/icon'
import React, { HTMLAttributes } from 'react'

const CancelIcon = (props: HTMLAttributes<SVGElement>) => (
    <Icon {...props} viewBox='0 0 24 24'>
        <circle cx='12' cy='12' r='10' />
        <line x1='4.93' y1='4.93' x2='19.07' y2='19.07' />
    </Icon>
)

export default CancelIcon