import EntryIcon from 'components/icons/entry-icon'
import React from 'react'
import styled from 'styled-components'
import { styles } from 'styles/styles'

const Container = styled.div`
    min-width: 25%;
    max-width: 50%;
    border-radius: ${styles.borderRadius.default};
    box-shadow: ${p => p.theme.shadows.close};
    padding: 3rem;
`
const Header = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-bottom: 2rem;
`
const Title = styled.h3`
    font-size: ${styles.fontSizes.larger};
    font-family: ${styles.fonts.bold};
    letter-spacing: ${p => p.theme.letterSpacing.medium};
    font-weight: 700;
    margin: 0;
`
const StyledEntryIcon = styled(EntryIcon)`

`

const QuickEntryCard = () => {

    return (
        <Container>
            <Header>
                <StyledEntryIcon />
                <Title>{'Quick Note'}</Title>
            </Header>
        </Container>
    )
}

export default QuickEntryCard