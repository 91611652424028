import AlertIcon from 'components/icons/alert-icon'
import RemoveIcon from 'components/icons/remove-icon'
import { ToastMessageEnum } from 'enums/ui'
import React from 'react'
import styled, { keyframes } from 'styled-components'
import { IToastMessage } from 'types/ui'

const CountdownAnimation = keyframes`
    to {}
`

const Container = styled.div`
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    height: 5rem;
    width: 25rem;
    animation: ${CountdownAnimation} 2s linear forwards;
    background-color: white;
    box-shadow: 0 0 1rem 0 rgba(0,0,0,.1);
    padding: 1.5rem 1rem 1.5rem 2rem;
    pointer-events: all;
`
const RemoveButton = styled.div`

`

type Props = {
    toastMessage: IToastMessage
    onRemove: (id: string) => void
}

const ToastMessage = (props: Props) => {
    const { toastMessage, onRemove } = props

    const onAnimationEnd = () => {
        onRemove(toastMessage.id)
    }

    const onRemoveClick = () => {
        onRemove(toastMessage.id)
    }

    const color = () => {
        switch (toastMessage.type) {
            default:
            case ToastMessageEnum.Info:
                return 'blue'

            case ToastMessageEnum.Success:
                return 'green'

            case ToastMessageEnum.Error:
                return 'red'

            case ToastMessageEnum.Warning:
                return 'yellow'
        }
    }

    const renderIcon = () : JSX.Element => {
        switch (toastMessage.type) {
            default:
                return <AlertIcon style={{stroke: color()}} />
        }
    }

    return (
        <Container onAnimationEnd={onAnimationEnd}>
            {renderIcon()}
            {toastMessage.text}
            <RemoveButton onClick={onRemoveClick}>
                <RemoveIcon />
            </RemoveButton>
        </Container>
    )
}

export default ToastMessage