import { BibleVersionEnum } from 'enums/bible'
import { LanguagesEnum } from 'enums/ui'
import { IBible } from 'types/bible'
import { ILanguage } from 'types/ui'

class BibleStore {
    public static readonly bibles: {[key in BibleVersionEnum]?: IBible} = {}

    public static readonly languages: ILanguage[] = [
        {
            type: LanguagesEnum.English,
            text: 'English'
        },
        {
            type: LanguagesEnum.German,
            text: 'German'
        },
        {
            type: LanguagesEnum.Spanish,
            text: 'Spanish'
        }
    ]
}

export default BibleStore