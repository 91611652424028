import { Icon } from 'components/common/icon'
import React, { HTMLAttributes } from 'react'

const CollapseDownIcon = (props: HTMLAttributes<SVGElement>) => (
    <Icon {...props} viewBox='0 0 24 24'>
        <polyline points='7 13 12 18 17 13' />
        <polyline points='7 6 12 11 17 6' />
    </Icon>
)

export default CollapseDownIcon