import SummaryIcon from 'components/icons/summary-icon'
import React from 'react'
import styled from 'styled-components'
import { styles } from 'styles/styles'

const Container = styled.div`
    display: inline-flex;
    align-items: center;
    column-gap: 1rem;
    background-color: ${p => p.theme.backgrounds.input};
    border-radius: ${styles.borderRadius.large};
    padding: .5rem 1.55rem;
    font-size: ${styles.fontSizes.small};
    cursor: pointer;
`
const StyledSummaryIcon = styled(SummaryIcon)`
    stroke: ${p => p.theme.colors.textInput};
`

const ChapterSummary = () => {
    return (
        <Container>
            <StyledSummaryIcon />
        </Container>
    )
}

export default ChapterSummary