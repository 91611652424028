import { useDraggable } from '@dnd-kit/core'
import RemoveIcon from 'components/icons/remove-icon'
import React from 'react'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import { IBookmarkEntity } from 'types/entity'
import DragListIcon from './icons/drag-list-icon'

const DeleteButton = styled.span`
    margin-left: auto;
    margin-right: 1rem;
    font-size: .75em;
    opacity: 0;
    display: grid;
    place-items: center;
    transition: opacity .2s ease;
`
const Wrapper = styled.div`
    width: 100%;
    height: 5rem;
    display: flex;
    align-items: center;
    border-radius: .75rem;
    color: ${p => p.theme.colors.textInput};
    background-color: ${p => p.theme.backgrounds.input};
    cursor: pointer;

    &:hover {
        color: ${p => p.theme.colors.text};
    }
    &:hover ${DeleteButton} {
        opacity: 1;
    }
`
const DragHandleContainer = styled.div`
    display: grid;
    place-items: center;
    background-color: ${p => p.theme.backgrounds.inputBright};
    border-radius: inherit;
    margin: 1rem;
    padding: .5rem;
    font-size: .75em;
    cursor: grab;
`
const StyledDragListIcon = styled(DragListIcon)`
    stroke: ${p => p.theme.colors.textInput};
`

type Props = {
    bookmark: IBookmarkEntity
    onDelete: () => void
    onClick: () => void
}

const Bookmark = (props: Props) => {
    const { bookmark, onDelete, onClick } = props

    const { transform } = useDraggable({
        id: `view-${bookmark._id}`
    })

    const onDeleteClick = (event: React.MouseEvent) => {
        event.stopPropagation()
        onDelete()
    }

    return (
        <Wrapper onClick={onClick}>
            <DragHandleContainer>
                <StyledDragListIcon />
            </DragHandleContainer>
            {bookmark.text}
            <DeleteButton onClick={onDeleteClick}>
                <RemoveIcon />
            </DeleteButton>
        </Wrapper>
    )
}

export default Bookmark