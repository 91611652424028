import useClickOutside from 'hooks/useClickOutside'
import React, { PropsWithChildren, useRef } from 'react'
import styled from 'styled-components'
import { styles } from 'styles/styles'

const Container = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    z-index: ${styles.zIndex.dropdown};
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    flex-wrap: wrap;
    background-color: ${p => p.theme.backgrounds.white};
    border-radius: ${styles.borderRadius.default};
    box-shadow: ${p => p.theme.shadows.far};
    width: 40rem;
    padding: 1.5rem;
    cursor: default;
`

type Props = {
    closeMenu: () => void
}

const BibleSearchLabelMenu = (props: PropsWithChildren<Props>) => {
    const { closeMenu } = props

    const ignoreRef = useRef<HTMLDivElement>(null)

    useClickOutside(closeMenu, ignoreRef)

    return (
        <Container ref={ignoreRef}>
            {props.children}
        </Container>
    )
}

export default BibleSearchLabelMenu