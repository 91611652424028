import ToastMessage from 'components/toast-message'
import React from 'react'
import { UIStore } from 'stores/ui-store'
import styled from 'styled-components'
import { styles } from 'styles/styles'

const Container = styled.div`
    position: absolute;
    bottom: 5rem;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-left: 5rem;
    padding-right: 5rem;
    pointer-events: none;
    z-index: ${styles.zIndex.toastmessage};
`

const ToastMessageRender = () => {
    const toastMessages = UIStore.useState(state => state.toastMessages)

    const removeToastMessage = (id: string) => {
        UIStore.update(state => {
            state.toastMessages = state.toastMessages.filter(tm => tm.id !== id)
        })
    }

    return (
        <Container>
            {
                toastMessages.map(toastMessage =>
                    <ToastMessage
                        key={`toastmessage-${toastMessage.id}`}
                        onRemove={removeToastMessage}
                        toastMessage={toastMessage}
                    />)
            }
        </Container>
    )
}

export default ToastMessageRender