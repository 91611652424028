import { Icon } from 'components/common/icon'
import React, { HTMLAttributes } from 'react'

const SummaryIcon = (props: HTMLAttributes<SVGElement>) => (
    <Icon {...props} viewBox='0 0 24 24'>
        <path d='M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2' />
        <rect x='8' y='2' width='8' height='4' rx='1' ry='1' />
        <line x1='9' y1='12' x2='15' y2='12' />
        <line x1='9' y1='16' x2='15' y2='16' />
    </Icon>
)

export default SummaryIcon