import React from 'react'
import styled from 'styled-components'
import { predefinedColors } from 'styles/colors'
import { styles } from 'styles/styles'
import { IColor } from 'types/ui'

const Container = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    width: 20rem;
    background-color: white;
    border-radius: ${styles.borderRadius.default};
    padding: .5rem;
    box-shadow: ${p => p.theme.shadows.far};
`
const HighlightColorItem = styled.div<{color: string}>`
    height: 2.5rem;
    width: 2.5rem;
    background-color: ${p => p.color};
`
const NoHighlightColorItem = styled.div`
    position: relative;
    height: 2.5rem;
    width: 2.5rem;
    background-color: transparent;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 150%;
        height: .1rem;
        background-color: red;
        transform: rotate(45deg); 
        transform-origin: top left;
    }

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: .1rem;
        height: 150%;
        background-color: red;
        transform: rotate(45deg); 
        transform-origin: top left;
    }
`

type Props = {
    closeMenu: () => void
    currentColor: IColor
    showNoColorItem?: boolean
    setColor: (color: IColor) => void
}

const ColorMenu = (props: Props) => {
    const { currentColor, showNoColorItem, setColor, closeMenu } = props

    const changeColor = (event: React.MouseEvent, color: IColor) => {
        event.stopPropagation()
        setColor(color)
        closeMenu()
    }

    return (
        <Container>
            {
                showNoColorItem &&
                <NoHighlightColorItem onClick={event => changeColor(event, predefinedColors[0])} />
            }
            {
                predefinedColors.slice(1).map(color => (
                    <HighlightColorItem
                        key={`highlight-color-${color.primary}`}
                        color={color.primary}
                        onClick={event => changeColor(event, color)}
                    />))
            }
        </Container>
    )
}

export default ColorMenu