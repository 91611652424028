import React, { useCallback, useEffect } from 'react'

const useSelect = (onSelect: () => void, onDeselect?: () => void) => {
    const onSelectionChange = useCallback(() => {
        const selection = document.getSelection()

        if (!selection || selection.rangeCount === 0) {
            debugger
            return onDeselect && onDeselect()
        }

        const selectionRange = selection.getRangeAt(0)
        const textLength = selection.toString().trim().length
    
        const startElement = selectionRange.startContainer.parentElement
        const endElement = selectionRange.endContainer.parentElement
    
        if (!startElement || !endElement) {
            debugger
            return onDeselect && onDeselect()
        }

        onSelect()
    }, [onSelect, onDeselect])

    useEffect(() => {
        document.addEventListener('selectionchange', onSelectionChange)

        return () => {
            document.removeEventListener('selectionchange', onSelectionChange)
        }
    }, [onSelectionChange])
}

export default useSelect