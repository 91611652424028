import ModalRenderer from 'views/layout/modal-renderer'
import ToastMessageRenderer from 'views/layout/toast-message-renderer'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { ProfileStore } from 'stores/profile-store'
import styled from 'styled-components'
import Header from 'views/layout/header'
import SelectionLayer from 'views/overview/selection-layer'

const Container = styled.div`
    height: 100vh;
    width: 100%;
`
const Content = styled.div`
    height: calc(100% - 7rem);
    background-color: ${p => p.theme.backgrounds.content};
`

const Layout = () => {
    const profile = ProfileStore.useState(state => state.profile)

    if (!profile) return null

    return (
        <Container>
            <Header username={profile.username} />
            <Content>
                <Outlet />
            </Content>
            <ToastMessageRenderer />
            <ModalRenderer />
            <SelectionLayer />
        </Container>
    )
}

export default Layout