import { Icon } from 'components/common/icon'
import React, { HTMLAttributes } from 'react'

const TypographyIcon = (props: HTMLAttributes<SVGElement>) => (
    <Icon {...props} viewBox='0 0 24 24'>
        <polyline points='4 7 4 4 20 4 20 7' />
        <line x1='9' y1='20' x2='15' y2='20' />
        <line x1='12' y1='4' x2='12' y2='20' />
    </Icon>
)

export default TypographyIcon