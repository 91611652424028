import { useDraggable } from '@dnd-kit/core'
import RemoveIcon from 'components/icons/remove-icon'
import { MouseButtonEnum } from 'enums/ui'
import React from 'react'
import { SearchStore } from 'stores/search-store'
import styled, { CSSProperties } from 'styled-components'
import { styles } from 'styles/styles'
import { ISearchTag } from 'types/search'

const Container = styled.div<{color: string, bgColor: string}>`
    position: relative;
    display: inline-flex;
    align-items: center;
    column-gap: 1rem;
    font-family: ${styles.fonts.bold};
    font-weight: 700;
    font-size: ${styles.fontSizes.smaller};
    padding: .5rem 1.5rem;
    border-radius: 25rem;
    letter-spacing: .05rem;
    line-height: 1;
    color: ${p => p.color};
    background-color: ${p => p.bgColor};
    cursor: pointer;
    white-space: nowrap;
    user-select: none;
`
const DeleteButton = styled.div`
    font-size: .65em;
    margin-left: .5rem;
    display: grid;
    padding: .25rem;
    cursor: pointer;
    place-items: center;
`
const StyledRemoveIcon = styled(RemoveIcon)<{color: string}>`
    stroke: ${p => p.color};
`

type Props = {
    tag: ISearchTag
}

const SearchTag = (props: Props) => {
    const { tag } = props

    const { transform, listeners, attributes, setNodeRef } = useDraggable({
        id: tag.tagId
    })

    const onRemoveClick = () => {
        SearchStore.update(state => {
            state.tags = state.tags.filter(t => t.orderId !== tag.orderId)
        })
    }

    const onMiddleMouseUp = (event: React.MouseEvent) => {
        if (event.button === MouseButtonEnum.Middle) {
            onRemoveClick()
        }
    }
    
    //right click allow modify / refine the reference

    const getStyle = () : CSSProperties => {
        const style: CSSProperties = {}
        if (transform) {
            style.transform = `translate3d(${transform.x}px, ${transform.y}px, 0)`
        }

        return style
    }

    return (
        <Container
            ref={setNodeRef}
            {...listeners}
            {...attributes}
            color={tag.color.primary}
            bgColor={tag.color.secondary}
            onMouseUp={onMiddleMouseUp}
            style={getStyle()}
        >
            {tag.text}
            <DeleteButton onClick={onRemoveClick}>
                <StyledRemoveIcon color={tag.color.primary} />
            </DeleteButton>
        </Container>
    )
}

export default SearchTag