import { Icon } from 'components/common/icon'
import React, { HTMLAttributes } from 'react'

const EditTextIcon = (props: HTMLAttributes<SVGElement>) => (
    <Icon {...props} viewBox='0 0 24 24'>
        <path d='M12 20h9' />
        <path d='M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z' />
    </Icon>
)

export default EditTextIcon