import { VerseCrossReferenceEnum } from 'enums/ui'
import React, { useState } from 'react'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import { IVerseStyle, IVerseSection } from 'types/verse'
import CrossReferenceMenu from './cross-reference-menu'
import CrossReferenceIcon from './icons/cross-reference-icon'

const StyledVerseSection = styled.span<{verseStyle: IVerseStyle, fontSerifs?: boolean}>`
    position: relative;
    padding-top: .3rem;
    padding-bottom: .3rem;

    ${p => p.verseStyle.highlightColor &&
        `background-color: ${p.verseStyle.highlightColor};
        box-decoration-break: clone;`}

    ${p => p.verseStyle.crossReference &&
        `text-decoration-style: dashed;
        text-decoration: underline;`}

    ${p => p.verseStyle.termEntry &&
        `border-radius: ${styles.borderRadius.small};
        border: .2rem solid #ffaaaa;`}

    ${p => p.verseStyle.searchTerm && `
        -webkit-text-stroke: .075rem ${p.theme.colors.text};
       `}
`
const StyledCrossReferenceIcon = styled(CrossReferenceIcon)`
    vertical-align: middle;
    padding-bottom: .5rem;
    cursor: pointer;
`

const StyledVerse = (props: IVerseSection) => {
    const { reference, text, highlight, crossReference, termEntry, searchTerm } = props

    const [showCrossReferenceMenu, setShowCrossReferenceMenu] = useState<boolean>(false)

    const verseStyle: IVerseStyle = {
        searchTerm: !!searchTerm,
        termEntry: !!termEntry,
        crossReference: !!crossReference,
        highlightColor: highlight ? highlight.color : undefined
    }

    const onCrossReferenceClick = () => {
        setShowCrossReferenceMenu(prev => !prev)
    }

    return (
        <StyledVerseSection verseStyle={verseStyle}>
            {
                crossReference === VerseCrossReferenceEnum.ShowIcon &&
                <StyledCrossReferenceIcon onClick={crossReference ? onCrossReferenceClick : undefined} />
            }
            {text}
            {
                showCrossReferenceMenu &&
                <CrossReferenceMenu reference={reference} closeMenu={() => {}} />
                //TODO if this causes issues then create a custom crossreference menu for inside the verse
            }
        </StyledVerseSection>
    )
}

export default StyledVerse