import { IColor } from 'types/ui'

export const predefinedColors: IColor[] = [
    {
        primary: 'transparent',
        secondary: 'transparent'
    },
    {
        primary: '#B80000',
        secondary: '#EB9694'
    },
    {
        primary: '#DB3E00',
        secondary: '#FAD0C3'
    },
    {
        primary: '#FCCB00',
        secondary: '#FEF3BD'
    },
    {
        primary: '#008B02',
        secondary: '#C1E1C5'
    },
    {
        primary: '#006B76',
        secondary: '#BEDADC'
    },
    {
        primary: '#1273DE',
        secondary: '#C4DEF6'
    },
    {
        primary: '#004DCF',
        secondary: '#BED3F3'
    },
    {
        primary: '#5300EB',
        secondary: '#D4C4FB'
    }
]