import { ProfileStore } from 'stores/profile-store'
import { IProfileEntity } from 'types/entity'

class ProfileService {
    public static login = (profile: IProfileEntity, password?: string) : boolean => {

        ProfileStore.update(state => {
            state.profile = profile
        })

        return true
    }
}

export default ProfileService