import { Icon } from 'components/common/icon'
import React, { HTMLAttributes } from 'react'

const ArrowLeftIcon = (props: HTMLAttributes<SVGElement>) => (
    <Icon {...props} viewBox='0 0 24 24'>
        <line x1='19' y1='12' x2='5' y2='12' />
        <polyline points='12 19 5 12 12 5' />
    </Icon>
)

export default ArrowLeftIcon