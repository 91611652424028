import RemoveIcon from 'components/icons/remove-icon'
import SearchIcon from 'components/icons/search-icon'
import React, { useState } from 'react'
import styled from 'styled-components'
import { styles } from 'styles/styles'

const Container = styled.div<{focus?: boolean}>`
    display: inline-flex;
    align-items: center;
    border-radius: 5rem;
    width: 100%;
    padding: 1rem;
    padding-left: 2rem;
    background-color: ${p => p.focus ? p.theme.backgrounds.white : p.theme.backgrounds.input};
`
const Input = styled.input`
    font-family: ${styles.fonts.default};
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;

    &::placeholder {
        color: ${p => p.theme.colors.textInput};
        font-family: ${styles.fonts.default};
    }
`
const ClearTextButton = styled.div`
    display: grid;
    place-items: center;
    border-radius: 50%;
    margin-left: auto;
    cursor: pointer;
    font-size: .75em;
`
const StyledClearButtonIcon = styled(RemoveIcon)`
    stroke: ${p => p.theme.colors.textInput};

    &:hover {
        stroke: ${p => p.theme.colors.text};
    }
`
const StyledSearchIcon = styled(SearchIcon)`
    stroke: ${p => p.theme.colors.textInput};
    font-size: .75em;
    margin-right: 1rem;
`

type Props = {
    text: string
    placeholder?: string
    setText: (text: string) => void
}

const SearchBar = (props: Props) => {
    const { text, placeholder, setText } = props

    const [focus, setFocus] = useState<boolean>(false)

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value
        setText(text)
    }

    const onClearClick = () => {
        setText('')
    }

    const onFocus = () => {
        setFocus(true)
    }

    const onBlur = () => {
        setFocus(false)
    }

    return (
        <Container focus={focus}>
            <StyledSearchIcon />
            <Input placeholder={placeholder} value={text} onInput={onInputChange} onChange={onInputChange} onFocus={onFocus} onBlur={onBlur} />
            {
                text !== '' &&
                <ClearTextButton onClick={onClearClick}>
                    <StyledClearButtonIcon />
                </ClearTextButton>
            }
        </Container>
    )
}

export default SearchBar