import { registerInDevtools, Store } from 'pullstate'
import { IEntryEntity } from 'types/entity'
import { ITemporaryEntry } from 'types/entry'

interface IEntryStore {
    selectedEntry?: IEntryEntity
    temporaryEntry?: ITemporaryEntry
}

export const EntryStore = new Store<IEntryStore>({

})

registerInDevtools({EntryStore})