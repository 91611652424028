import { EntityEnum } from 'enums/entity'
import Helper from 'helpers/helper'
import React, { useCallback } from 'react'
import { IHighlightEntity } from 'types/entity'
import { IReference } from 'types/verse'
import { usePouch } from 'use-pouchdb'

interface IUseHighlightEntity {
    createHighlight: (reference: IReference, color: string) => void
    deleteHighlights: (reference: IReference) => void
}

export const useHighlightEntity = () : IUseHighlightEntity => {
    const db = usePouch()

    const createHighlight = useCallback(async (reference: IReference, color: string) => {
        const highlight: IHighlightEntity = {
            _id: Helper.randomUid(),
            type: EntityEnum.Highlight,
            reference,
            color
        }

        const result = await db.put(highlight)

        return result
    }, [db])

    const deleteHighlights = useCallback(async (reference: IReference) : Promise<void> => {
        const highlights = (await db.find({
            selector: ['']
        })).docs as (IHighlightEntity & PouchDB.Core.IdMeta & PouchDB.Core.GetMeta)[]

        for (const highlight of highlights) {
            await db.remove(highlight)
        }
    }, [db])

    return {
        createHighlight,
        deleteHighlights
    }
}