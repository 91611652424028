import { Icon } from 'components/common/icon'
import React, { HTMLAttributes } from 'react'

const MoreDotsIcon = (props: HTMLAttributes<SVGElement>) => (
    <Icon {...props} viewBox='0 0 24 24'>
        <circle cx='12' cy='12' r='1' />
        <circle cx='19' cy='12' r='1' />
        <circle cx='5' cy='12' r='1' />
    </Icon>
)

export default MoreDotsIcon