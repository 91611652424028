import { EntityEnum } from 'enums/entity'
import { EntryIconEnum } from 'enums/icon'
import Helper from 'helpers/helper'
import React, { useCallback } from 'react'
import { IEntryEntity } from 'types/entity'
import { IReference } from 'types/verse'
import { usePouch } from 'use-pouchdb'

interface IUseEntryEntity {
    getEntry: (id: string) => void
    createEntry: (title: string, html: string, icon: EntryIconEnum, createdBy: string, reference: IReference, tagIds: string[]) => void
    deleteEntry: (id: string) => void
    updateEntry: (entryId: string, html: string, title: string) => void
}

export const useEntryEntity = () : IUseEntryEntity => {
    const db = usePouch()

    const getEntry = useCallback(async (id: string) => {
        const result = await db.get<IEntryEntity>(id)
        return result
    }, [db])

    const createEntry = useCallback(async (title: string, html: string, icon: EntryIconEnum, createdBy: string, reference: IReference, tagIds: string[]) => {
        const entry: IEntryEntity = {
            _id: Helper.randomUid(),
            type: EntityEnum.Entry,
            title,
            htmlContent: html,
            createdOn: new Date().getTime(),
            createdBy,
            icon,
            reference,
            tagIds
        }

        const result = await db.put<IEntryEntity>(entry)

        return result
    }, [db])

    const deleteEntry = useCallback(async (id: string) : Promise<boolean> => {
        const entry = await db.get<IEntryEntity>(id)
        const result = await db.remove(entry)

        //TODO remove also tagId from all bookmarks and entries

        return result.ok
    }, [db])

    const updateEntry = useCallback(async (entryId: string, html: string, title: string) => {
        const entry = await db.get<IEntryEntity>(entryId)
        entry.htmlContent = html
        entry.title = title
        await db.put<IEntryEntity>(entry)
    }, [db])

    return {
        getEntry,
        createEntry,
        deleteEntry,
        updateEntry
    }
}