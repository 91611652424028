import React, { useMemo, useRef } from 'react'
import styled from 'styled-components'
import BibleService from 'services/bible-service'
import useSelect from 'hooks/useSelect'
import { VerseStore } from 'stores/verse-store'
import { IReference } from 'types/verse'
import { ComparisonCardEnum, ComparisonModeEnum } from 'enums/ui'
import BibleReaderCard from './bible-reader-card'
import { UIStore } from 'stores/ui-store'

const Container = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    height: calc(100% - 9rem);
    width: 100%;
    margin-top: 2rem;
`

const BibleReader = () => {
    const comparisonMode = UIStore.useState(state => state.comparisonMode)

    const mousePos = useRef<{x: number, y: number}>({x: 0, y: 0})

    const onMouseMove = (event: React.MouseEvent) => {
        mousePos.current = {
            x: event.clientX,
            y: event.clientY
        }
    }

    const onSelect = () => {
        const selection = document.getSelection()!
        const selectionRange = selection.getRangeAt(0)

        const startElement = selectionRange.startContainer.parentElement
        const endElement = selectionRange.endContainer.parentElement

        const startVerseElement = startElement?.parentElement
        const endVerseElement = endElement?.parentElement

        let startVerseId: string | undefined = undefined
        let endVerseId: string | undefined = undefined

        if (startElement && startVerseElement && startVerseElement.hasAttribute('data-verseid')) {
            startVerseId = startVerseElement.dataset.verseid
        }

        if (endElement && endVerseElement && endVerseElement.hasAttribute('data-verseid')) {
            endVerseId = endVerseElement.dataset.verseid
        }

        if (!startVerseId || !endVerseId) {
            debugger
            // hideVerseMenu()
            return
        }

        const startVerseElementChildren = Array.from(startVerseElement!.children)
        const startSectionIndex = startVerseElementChildren.indexOf(startElement!)

        if (startSectionIndex === -1) {
            throw new Error('INVALID START SECTION INDEX')
        }

        let startOffset = selectionRange.startOffset
        for (let i = 0; i < startSectionIndex; i++) {
            startOffset += startVerseElementChildren[i].textContent?.length ?? 0
        }

        const endVerseElementChildren = Array.from(endVerseElement!.children)
        const endSectionIndex = endVerseElementChildren.indexOf(endElement!)

        if (endSectionIndex === -1) {
            throw new Error('INVALID END SECTION INDEX')
        }

        let endOffset = selectionRange.endOffset
        for (let i = 0; i < endSectionIndex; i++) {
            endOffset += endVerseElementChildren[i].textContent?.length ?? 0
        }

        const startReference = BibleService.getReferenceByVerseId(startVerseId)
        const endReference = BibleService.getReferenceByVerseId(endVerseId)

        const totalVerseChars = startVerseElementChildren.reduce((acc, curr) => acc + (curr.textContent?.length ?? 0), 0)
        const isOffsetBeyondChars = startOffset === totalVerseChars
        if (isOffsetBeyondChars) {
            startReference.verseNr++
            startOffset = 0
        }

        const hasCrossReference = false
        const hasHighlight = false

        const selectionReference: IReference = {
            version: startReference.version,
            bookId: startReference.bookId,
            chapterNr: startReference.chapterNr,
            subset: {
                startVerse: startReference.verseNr,
                endVerse: endReference.verseNr,
                startOffset,
                endOffset
            }
        }

        const selectedVersesText = BibleService.getTextFromReference(selectionReference)
        const firstVerseText = selectedVersesText.shift()!
        const lastVerseText = selectedVersesText.pop() ?? firstVerseText

        const specialCharactersRegExp = new RegExp('[A-Za-z0-9]', 'gim')

        for (let i = startOffset; i >= 0; i--) {
            selectionReference.subset!.startOffset = i
            if (!firstVerseText[i].match(specialCharactersRegExp)) {
                selectionReference.subset!.startOffset++
                break
            }
        }

        for (let i = endOffset - 1; i < lastVerseText.length; i++) {
            selectionReference.subset!.endOffset = i
            if (!lastVerseText[i].match(specialCharactersRegExp)) {
                break
            }
        }

        let extendedWordsVerseText: string
        if (selectionReference.subset!.startVerse === selectionReference.subset!.endVerse) {
            extendedWordsVerseText = firstVerseText.substring(selectionReference.subset!.startOffset!, selectionReference.subset!.endOffset!)
        } else {
            const firstVersePartialText = firstVerseText.substring(selectionReference.subset!.startOffset!)
            const lastVersePartialText = lastVerseText.substring(0, selectionReference.subset!.endOffset!)

            const middleVerseText = selectedVersesText.join(' ')

            extendedWordsVerseText = `${firstVersePartialText} ${middleVerseText} ${lastVersePartialText}`
        }
        extendedWordsVerseText.trim()

        VerseStore.update(state => {
            state.offset = {
                x: mousePos.current.x,
                y: mousePos.current.y
            }
            state.visible = true
            state.selection = {
                text: extendedWordsVerseText,
                hasCrossReference,
                hasHighlight,
                reference: selectionReference
            }
        })
    }

    const hideVerseMenu = () => {
        VerseStore.update(state => {
            state.visible = false
        })
    }

    useSelect(onSelect, hideVerseMenu)

    const gridConfig = useMemo(() => {
        switch (comparisonMode) {
            default:
            case ComparisonModeEnum.Default:
                return ['1 / 1 / 3 / 3']
            case ComparisonModeEnum.OneByOne:
                return ['1 / 1 / 3 / 2', '1 / 2 / 3 / 3']
            case ComparisonModeEnum.OneByTwo:
                return ['1 / 1 / 3 / 2', '1 / 2 / 2 / 3', '2 / 2 / 3 / 3']
        }
    }, [comparisonMode])

    return (
        <Container onMouseMove={onMouseMove}>
            {
                gridConfig.map((config, index) => (
                    <BibleReaderCard key={`card-config-${index}`} comparisonCard={index as ComparisonCardEnum} gridArea={config} />
                ))
            }
        </Container>
    )
}

export default BibleReader