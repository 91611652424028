import BibleTag from 'components/bible-tag'
import AddIcon from 'components/icons/add-icon'
import SortIcon from 'components/icons/sort-icon'
import TagIcon from 'components/icons/tag-icon'
import SearchBar from 'components/search-bar'
import { EntityEnum } from 'enums/entity'
import { TagSortEnum } from 'enums/tag'
import CreateTagModal from 'modals/create-tag-modal'
import React, { useState } from 'react'
import { UIStore } from 'stores/ui-store'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import { ITagEntity } from 'types/entity'
import { useFind } from 'use-pouchdb'

const Container = styled.div`
    position: relative;
    padding: 2rem;
    width: 100%;
    border-radius: ${styles.borderRadius.default};
    background-color: ${p => p.theme.backgrounds.white};
    box-shadow: ${p => p.theme.shadows.medium};
`
const Title = styled.h3`
    font-family: ${styles.fonts.bold};
    font-size: ${styles.fontSizes.larger};
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    column-gap: 1rem;
`
const TagContainer = styled.div`
    height: calc(100% - 10rem);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    gap: 1rem;
`
const SortContainer = styled.div`
    font-size: .75em;
    margin-left: auto;
    display: flex;
    align-items: center;
    column-gap: .5rem;
    cursor: pointer;
`
const ToolbarContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-bottom: 1rem;
`
const StyledAddIcon = styled(AddIcon)`
    stroke: ${p => p.theme.colors.textInput};
    
    &:hover {
        stroke: ${p => p.theme.colors.text};
    }
`
const AddIconContainer = styled.div`
    display: grid;
    place-items: center;
    cursor: pointer;
    border-radius: ${styles.borderRadius.default};
    background-color: ${p => p.theme.backgrounds.input};
    padding: .7rem;
`

const TagCloud = () => {
    const { docs: tags, loading, error } = useFind<ITagEntity>({
        index: {fields: ['type']},
        selector: {type: EntityEnum.Tag}
    })

    const [searchText, setSearchText] = useState<string>('')
    const [sortType, setSortType] = useState<TagSortEnum>(TagSortEnum.AlphabetAsc)

    const getSortText = () : string => {
        switch (sortType) {
            default:
            case TagSortEnum.AlphabetAsc:
                return 'Alphabet'

            case TagSortEnum.ReferencesAsc:
                return 'References'

            case TagSortEnum.LastUsedAsc:
                return 'Most Recent'
        }
    }

    const changeSortType = () => {
        let index = sortType + 1
        if (index >= Object.keys(TagSortEnum).length / 2) {
            index = 0
        }

        setSortType(index)
    }

    const getSortComparer = () : (a: ITagEntity, b: ITagEntity) => number => {
        switch (sortType) {
            default:
            case TagSortEnum.AlphabetAsc:
                return alphabeticComparer

            case TagSortEnum.ReferencesAsc:
                return referenceComparer

            case TagSortEnum.LastUsedAsc:
                return lastUsedComparer
        }
    }

    const alphabeticComparer = (a: ITagEntity, b: ITagEntity) =>
        a.text.localeCompare(b.text)

    const referenceComparer = (a: ITagEntity, b: ITagEntity) =>
        b.references.length - a.references.length

    const lastUsedComparer = (a: ITagEntity, b: ITagEntity) =>
        (b.lastUsed ?? 0) - (a.lastUsed ?? 0)

    const onAddTagClick = () => {
        UIStore.update(state => {
            state.modal = <CreateTagModal />
        })
    }

    return (
        <Container>
            <Title>
                <TagIcon />
                {'TAGS'}
                <SortContainer onClick={changeSortType}>
                    {getSortText()}
                    <SortIcon />
                </SortContainer>
            </Title>
            <ToolbarContainer>
                <SearchBar
                    setText={setSearchText}
                    text={searchText}
                    placeholder='Search Tag'
                />
                <AddIconContainer onClick={onAddTagClick}>
                    <StyledAddIcon />
                </AddIconContainer>
            </ToolbarContainer>
            <TagContainer>
                {
                    tags.filter(tag => tag.text.toLowerCase()
                        .includes(searchText.trim().toLowerCase()))
                        .sort(getSortComparer())
                        .map(tag => (
                            <BibleTag
                                key={tag._id}
                                tag={tag}
                                tagMenu
                            />))
                }
            </TagContainer>
        </Container>
    )
    
}

export default TagCloud