import ActivityIcon from 'components/icons/activity-icon'
import { EntityEnum } from 'enums/entity'
import React from 'react'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import { IEntryEntity } from 'types/entity'
import { useFind } from 'use-pouchdb'

const Container = styled.div`
font-family: ${styles.fonts.light};
    min-width: 25%;
    max-width: 50%;
    border-radius: ${styles.borderRadius.default};
    box-shadow: ${p => p.theme.shadows.close};
    padding: 3rem;
`
const Header = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-bottom: 2rem;
`
const Title = styled.h3`
    font-size: ${styles.fontSizes.larger};
    font-family: ${styles.fonts.bold};
    letter-spacing: ${p => p.theme.letterSpacing.medium};
    font-weight: 700;
    margin: 0;
`
const StyledActivityIcon = styled(ActivityIcon)`

`

const ActivityCard = () => {

    const { docs: entries, loading, error } = useFind<IEntryEntity>({
        index: {fields: ['type']},
        selector: {
            type: EntityEnum.Entry
        }
    })

    const bookIds = entries.filter(e => e.reference !== null).map(e => e.reference!.bookId)
    const uniqueBookIds = bookIds.filter((bookId, index, self) => self.indexOf(bookId) === index)

    return (
        <Container>
            <Header>
                <StyledActivityIcon />
                <Title>{'Activity'}</Title>
            </Header>
            {`${entries.length} Entries total.`}
            <br />
            {`${uniqueBookIds.length} Books total.`}
        </Container>
    )
}

export default ActivityCard