import NKJVBible from 'bibles/NKJV.bible.json'
import { BibleBookEnum, BibleVersionEnum } from 'enums/bible'
import RegularExpressions from 'helpers/regular-expressions'
import Stopwatch from 'helpers/stopwatch'
import BibleStore from 'stores/bible-store'
import { IBible, IBook, IChapter } from 'types/bible'
import { ISearchReference, ISearchTag, ISearchTerm, ISearchReferenceResult, ISearchTermResult } from 'types/search'
import { IPassage, IReference, IVerseReference } from 'types/verse'
import { IWordSearch } from 'types/word'

class BibleService {
    public static searchBibleBookName = (query: string) : ISearchReferenceResult[] => {
        //check if text matches any book names
        //could be mergedto searchBibleWithSuggestions and add to the for loop
        const books = BibleService.getBooks(BibleVersionEnum.NKVJ)
        const referenceMatches: ISearchReferenceResult[] = []
        for (const book of books) {

            //check if the bookname is partially typed
            const clearedQuery = query.replace(RegularExpressions.invalidQueryChars, '')
            const queryRegExp = new RegExp('\\w*' + clearedQuery + '\\w*', 'gim')
            if (queryRegExp.test(book.name)) {
                referenceMatches.push({
                    text: book.name,
                    reference: {
                        version: BibleVersionEnum.NKVJ,
                        bookId: BibleService.getBookIdByBook(BibleVersionEnum.NKVJ, book)
                    }
                })
            } else { //since the above condition will fail if a chapter / verse is added

                //check if the query ends with a chapter / verse reference
                const chapterVerseMatches = query.match(RegularExpressions.validBiblePassage)
                if (chapterVerseMatches && chapterVerseMatches.length > 0) {
                    //since the $ in the regex means the string will end, it will always only return a single match
                    const chapterVerseMatch = chapterVerseMatches[0]

                    //remove the chapter / verse part of the query and check if then a book name is partially typed
                    const queryWithoutReference = query.slice(0, -chapterVerseMatch.length).trim()
                    const slicedQueryRegExp = new RegExp('\\w*' + queryWithoutReference + '\\w*', 'gim')
                    if (slicedQueryRegExp.test(book.name)) {
                        //check if it's ONLY a chapter
                        const chapterVerseMatchSplitArr = chapterVerseMatch.split(':')
                        const chapterNr = parseInt(chapterVerseMatchSplitArr[0].trim())
                        //validate that the book actually has such a chapter
                        const chapter = book.chapters.find(c => c.num === chapterNr)

                        if (!chapter) continue

                        if (chapterVerseMatchSplitArr.length === 1) {
                            referenceMatches.push({
                                text: `${book.name} ${chapter.num}`,
                                reference: {
                                    version: BibleVersionEnum.NKVJ,
                                    bookId: BibleService.getBookIdByBook(BibleVersionEnum.NKVJ, book),
                                    chapterNr: chapter.num
                                }
                            })
                        } else { //if a verse is specified the split will create an array of 2
                            const verseSplit = chapterVerseMatchSplitArr[1]

                            const verseSplitArr = verseSplit.split('-')
                            const startVerseNr = parseInt(verseSplitArr[0].trim())
                            const startVerse = chapter.verses.find(v => v.num === startVerseNr)

                            if (!startVerse) continue

                            if (verseSplitArr.length === 1) { //if no verse range is specified only one
                                referenceMatches.push({
                                    text: `${book.name} ${chapter.num}:${startVerseNr}`,
                                    reference: {
                                        version: BibleVersionEnum.NKVJ,
                                        bookId: BibleService.getBookIdByBook(BibleVersionEnum.NKVJ, book),
                                        chapterNr: chapter.num,
                                        subset: {
                                            startVerse: startVerseNr,
                                            endVerse: startVerseNr
                                        }
                                    }
                                })
                            } else { //a verse range was specified
                                const endVerseNr = parseInt(verseSplitArr[1].trim())
                                const endVerse = chapter.verses.find(v => v.num === endVerseNr)

                                if (!endVerse) continue

                                referenceMatches.push({
                                    text: `${book.name} ${chapter.num}:${startVerseNr}-${endVerseNr}`,
                                    reference: {
                                        version: BibleVersionEnum.NKVJ,
                                        bookId: BibleService.getBookIdByBook(BibleVersionEnum.NKVJ, book),
                                        chapterNr: chapter.num,
                                        subset: {
                                            startVerse: startVerseNr,
                                            endVerse: endVerseNr
                                        }
                                    }
                                })
                            }
                        }
                    }
                }
            }
        }

        return referenceMatches.sort((a, b) => a.text.localeCompare(b.text))
    }

    public static searchCrossReference = (version: BibleVersionEnum, query: string) : ISearchReferenceResult[] => {

        const books = BibleService.getBooks(version)
        const referenceMatches: ISearchReferenceResult[] = []
        for (const book of books) {
            //check if the query ends with a chapter / verse reference
            const chapterVerseMatches = query.match(RegularExpressions.validBiblePassage)
            if (chapterVerseMatches && chapterVerseMatches.length > 0) {
                //since the $ in the regex means the string will end, it will always only return a single match
                const chapterVerseMatch = chapterVerseMatches[0]

                //remove the chapter / verse part of the query and check if then a book name is partially typed
                const queryWithoutReference = query.slice(0, -chapterVerseMatch.length).trim()
                const slicedQueryRegExp = new RegExp('\\w*' + queryWithoutReference + '\\w*', 'gim')
                if (slicedQueryRegExp.test(book.name)) {
                    //check if it's ONLY a chapter
                    const chapterVerseMatchSplitArr = chapterVerseMatch.split(':')
                    const chapterNr = parseInt(chapterVerseMatchSplitArr[0].trim())
                    //validate that the book actually has such a chapter
                    const chapter = book.chapters.find(c => c.num === chapterNr)

                    if (!chapter) continue

                    if (chapterVerseMatchSplitArr.length === 1) {
                        referenceMatches.push({
                            text: `${book.name} ${chapter.num}`,
                            reference: {
                                version: BibleVersionEnum.NKVJ,
                                bookId: BibleService.getBookIdByBook(BibleVersionEnum.NKVJ, book),
                                chapterNr: chapter.num
                            }
                        })
                    } else { //if a verse is specified the split will create an array of 2
                        const verseSplit = chapterVerseMatchSplitArr[1]

                        const verseSplitArr = verseSplit.split('-')
                        const startVerseNr = parseInt(verseSplitArr[0].trim())
                        const startVerse = chapter.verses.find(v => v.num === startVerseNr)

                        if (!startVerse) continue

                        if (verseSplitArr.length === 1) { //if no verse range is specified only one
                            referenceMatches.push({
                                text: `${book.name} ${chapter.num}:${startVerseNr}`,
                                reference: {
                                    version: BibleVersionEnum.NKVJ,
                                    bookId: BibleService.getBookIdByBook(BibleVersionEnum.NKVJ, book),
                                    chapterNr: chapter.num,
                                    subset: {
                                        startVerse: startVerseNr,
                                        endVerse: startVerseNr
                                    }
                                }
                            })
                        } else { //a verse range was specified
                            const endVerseNr = parseInt(verseSplitArr[1].trim())
                            const endVerse = chapter.verses.find(v => v.num === endVerseNr)

                            if (!endVerse) continue

                            referenceMatches.push({
                                text: `${book.name} ${chapter.num}:${startVerseNr}-${endVerseNr}`,
                                reference: {
                                    version: BibleVersionEnum.NKVJ,
                                    bookId: BibleService.getBookIdByBook(BibleVersionEnum.NKVJ, book),
                                    chapterNr: chapter.num,
                                    subset: {
                                        startVerse: startVerseNr,
                                        endVerse: endVerseNr
                                    }
                                }
                            })
                        }
                    }
                }
            }
        }

        return referenceMatches
    }

    public static searchBibleTermsWithSuggestions = (text: string) : ISearchTermResult[] => {
        const sw = Stopwatch.startNew('Extract words')
        const wordOccurences: IWordSearch = {}

        const books = BibleService.getBooks(BibleVersionEnum.NKVJ)
        for (const book of books) {
            for (const chapter of book.chapters) {
                for (const verse of chapter.verses) {
                    const partialMatches = BibleService.getPartialMatches(verse.text, text)
                    
                    for (const word of partialMatches) {
                        const lowerWord = word.toLowerCase()

                        if (wordOccurences[lowerWord]) {
                            wordOccurences[lowerWord].count++
                            
                            const verseRef: IVerseReference = {
                                version: BibleVersionEnum.NKVJ,
                                bookId: BibleService.getBookIdByBook(BibleVersionEnum.NKVJ, book),
                                chapterNr: chapter.num,
                                verseNr: verse.num
                            }

                            const refExists = wordOccurences[lowerWord].references.find(ref =>
                                ref.bookId === verseRef.bookId &&
                                ref.chapterNr === verseRef.chapterNr &&
                                ref.verseNr === verseRef.verseNr)

                            if (!refExists) {
                                wordOccurences[lowerWord].references.push(verseRef)
                            }
                        } else {
                            wordOccurences[lowerWord] = {
                                text: word,
                                count: 1,
                                references: [{
                                    version: BibleVersionEnum.NKVJ,
                                    bookId: BibleService.getBookIdByBook(BibleVersionEnum.NKVJ, book),
                                    chapterNr: chapter.num,
                                    verseNr: verse.num
                                }]
                            }
                        }
                    }
                }
            }
        }

        sw.stop()

        const searchResults = Object.values(wordOccurences)
            .sort((a, b) => b.count - a.count)
            .map<ISearchTermResult>(word => ({
                text: word.text,
                count: word.count,
                references: word.references
            }))

        return searchResults
    }

    private static getPartialMatches = (text: string, query: string) : string[] => {
        const filteredText = text.replace(RegularExpressions.invalidVerseChars, ' ')

        const filteredQuery = query.replace(RegularExpressions.invalidQueryChars, '')

        const magicRegExp = new RegExp('(\\b)(\\w*' + filteredQuery + '\\w*)(\\b)', 'gim')

        const matches = filteredText.match(magicRegExp)
        if (!matches || matches.length === 0)
            return []

        return matches
    }

    public static searchBibleExact = (text: string) : ISearchTermResult => {
        const bible = BibleStore.bibles[BibleVersionEnum.NKVJ]
        let count = 0
        const references: IVerseReference[] = []

        if (!bible) return {
            text,
            count,
            references
        }

        for (const book of bible.books) {
            for (const chapter of book.chapters) {
                for (const verse of chapter.verses) {
       
                    const exactMatches = BibleService.getExactMatches(verse.text, text)
                    
                    if (exactMatches.length > 0) {
                        count += exactMatches.length
                            
                        const verseRef: IVerseReference = {
                            version: BibleVersionEnum.NKVJ,
                            bookId: BibleService.getBookIdByBook(BibleVersionEnum.NKVJ, book),
                            chapterNr: chapter.num,
                            verseNr: verse.num
                        }
    
                        references.push(verseRef)
                    }
                }
            }
        }

        return {
            text,
            count,
            references
        }
    }

    private static getExactMatches = (text: string, query: string) : string[] => {
        const charsToFilter: string[] = [
            '?', '.', ':', ',', ';', '"', '\''
        ]
        const charFilteredRegExp = new RegExp(`[${charsToFilter.join('')}]`, 'gim')
        const filteredText = text.replace(charFilteredRegExp, '')
        
        const magicRegExp = new RegExp('\\b' + query + '\\b', 'gim')

        const matches = filteredText.match(magicRegExp)
        if (!matches || matches.length === 0)
            return []

        return matches
    }

    public static verseReferenceSortComparer = (a: IVerseReference, b: IVerseReference) =>
        a.version - b.version ||
        a.bookId - b.bookId ||
        a.chapterNr - b.chapterNr ||
        a.verseNr - b.verseNr

    public static referenceSortComparer = (a: IReference, b: IReference) =>
        a.version - b.version ||
        a.bookId - b.bookId ||
        (a.chapterNr ?? 0) - (b.chapterNr ?? 0) ||
        (a.subset?.startVerse ?? 0) - (b.subset?.startVerse ?? 0) ||
        (a.subset?.endVerse ?? 0) - (b.subset?.endVerse ?? 0)

    public static getPassagesBySearch = (references: ISearchReference[], tags: ISearchTag[], terms: ISearchTerm[]) : IPassage[] => {
        const referencePassages: IPassage[] = []
        for (const reference of references) {
            if (reference.reference.chapterNr) {
                referencePassages.push({
                    version: reference.reference.version,
                    bookId: reference.reference.bookId,
                    chapter: BibleService.getChapterByReference({
                        version: reference.reference.version,
                        bookId: reference.reference.bookId,
                        chapterNr: reference.reference.chapterNr
                    }),
                    orderId: reference.orderId,
                    subset: reference.reference.subset
                })
            } else {
                const book = BibleService.getBookById(reference.reference.version, reference.reference.bookId)
                for (const chapter of book.chapters) {
                    referencePassages.push({
                        version: reference.reference.version,
                        bookId: reference.reference.bookId,
                        chapter: BibleService.getChapterByReference({
                            version: reference.reference.version,
                            bookId: reference.reference.bookId,
                            chapterNr: chapter.num
                        }),
                        orderId: reference.orderId
                    })
                }
            }
        }

        const tagPassages: IPassage[] = []
        for (const tag of tags) {

            const sortedReferences = [...tag.references]
            sortedReferences.sort(BibleService.referenceSortComparer)

            for (const tagRef of sortedReferences) {
                if (!tagRef.subset) {
                    tagPassages.push({
                        version: tagRef.version,
                        bookId: tagRef.bookId,
                        chapter: BibleService.getChapterByReference(tagRef),
                        orderId: tag.orderId
                    })
                } else {
                    tagPassages.push({
                        version: tagRef.version,
                        bookId: tagRef.bookId,
                        chapter: BibleService.getChapterByReference(tagRef),
                        orderId: tag.orderId,
                        subset: tagRef.subset
                    })
                }
            }
        }

        const termPassages: IPassage[] = []
        for (const term of terms) {

            const sortedReferences = [...term.references]
            sortedReferences.sort(BibleService.verseReferenceSortComparer)

            for (const termRef of sortedReferences) {
                if (termPassages.length > 0) {
                    const preceedingPassage = termPassages[termPassages.length - 1]
                    if (preceedingPassage.bookId === termRef.bookId &&
                        preceedingPassage.chapter.num === termRef.chapterNr &&
                        preceedingPassage.subset &&
                        preceedingPassage.subset.endVerse === termRef.verseNr - 1) {
                        preceedingPassage.subset.endVerse++
                        continue
                    }
                }

                termPassages.push({
                    version: termRef.version,
                    bookId: termRef.bookId,
                    chapter: BibleService.getChapterByReference(termRef),
                    orderId: term.orderId,
                    subset: {
                        startVerse: termRef.verseNr,
                        endVerse: termRef.verseNr
                    }
                })
            }
        }

        const passages = [...referencePassages, ...tagPassages, ...termPassages]
        passages.sort((a, b) => b.orderId - a.orderId)

        return passages
    }

    public static getChapterByReference = (reference: IReference | IVerseReference) : IChapter => {
        const bible = BibleStore.bibles[reference.version]!

        const book = bible.books.find((_, index) => index === reference.bookId)!
        const chapter = book.chapters.find(c => c.num === reference.chapterNr)!

        return chapter
    }

    public static parseBible = (version: BibleVersionEnum) => {
        switch (version) {
            default:
            case BibleVersionEnum.NKVJ:
                const data: IBible = NKJVBible as IBible
                BibleStore.bibles[version] = data
                break
        }
    }

    public static getBooks = (version: BibleVersionEnum) : IBook[] => {
        const bible = BibleStore.bibles[version]
        return bible?.books ?? []
    }

    public static getBookById = (version: BibleVersionEnum, bookId: BibleBookEnum) : IBook => {
        const bible = BibleStore.bibles[version]!
        return bible.books[bookId]
    }

    public static getBookIdByBook = (version: BibleVersionEnum, book: IBook) : BibleBookEnum => {
        const bible = BibleStore.bibles[version]!
        return bible.books.findIndex(b => b === book)
    }

    public static convertReferenceToString = (reference: IReference) : string => {
        const book = BibleService.getBookById(reference.version, reference.bookId)
        if (!reference.chapterNr)
            return book.name

        if (!reference.subset || (reference.subset.startVerse === 1 && reference.subset.endVerse === book.chapters[reference.chapterNr - 1].verses.length))
            return `${book.name} ${reference.chapterNr}`

        if (reference.subset.startVerse === reference.subset.endVerse)
            return `${book.name} ${reference.chapterNr}:${reference.subset.startVerse}`
        
        return `${book.name} ${reference.chapterNr}:${reference.subset.startVerse}-${reference.subset.endVerse}`
    }

    public static convertVerseReferenceToString = (reference: IVerseReference) => {
        const book = BibleService.getBookById(reference.version, reference.bookId)

        return `${book.name} ${reference.chapterNr}:${reference.verseNr}`
    }

    public static getReferenceByVerseId = (verseId: string) : IVerseReference => {
        const startVerseSplit = verseId.split('-')
        if (startVerseSplit.length !== 4) {
            throw new Error ('incorrect VerseId')
        }

        const version = parseInt(startVerseSplit[0]) as BibleVersionEnum
        const bookId = parseInt(startVerseSplit[1]) as BibleBookEnum
        const chapterNr = parseInt(startVerseSplit[2])
        const verseNr = parseInt(startVerseSplit[3])

        return {
            version,
            bookId,
            chapterNr,
            verseNr
        }
    }

    public static getVerseIdByVerseReference = (reference: IVerseReference) =>
        `${reference.version}-${reference.bookId}-${reference.chapterNr}-${reference.verseNr}`

    public static getPassageIdByReference = (reference: IReference) => {
        if (!reference.chapterNr)
            return `${reference.version}-${reference.bookId}`

        if (!reference.subset)
            return `${reference.version}-${reference.bookId}-${reference.chapterNr}`

        return `${reference.version}-${reference.bookId}-${reference.chapterNr}-${reference.subset.startVerse}-${reference.subset.endVerse}`
    }
    
    public static getTextFromReference = (reference: IReference) : string[] => {
        const bible = BibleStore.bibles[reference.version]!

        const book = bible.books.find((_, index) => index === reference.bookId)!
        const chapter = book.chapters.find(c => c.num === reference.chapterNr)!
  
        const startVerse = reference.subset?.startVerse ?? 1
        const endVerse = reference.subset?.endVerse ?? chapter.verses[chapter.verses.length - 1].num

        if (startVerse === endVerse) {
            return [chapter.verses[startVerse - 1].text]
        }

        const verseText = chapter.verses.slice(startVerse - 1, endVerse)
        return verseText.map(v => v.text)
    }

    public static getBibleVersions = (query: string) : {type: BibleVersionEnum, text: string}[] => {
        const bibles = Object.values(BibleStore.bibles)
        const queryLower = query.toLowerCase()

        const versions = bibles
            .filter(b => b.versionName.toLowerCase().includes(queryLower))
            .map<{type: BibleVersionEnum, text: string}>(b => ({
                type: b.version,
                text: b.versionName
            }))

        return versions
    }
}

export default BibleService

BibleService.parseBible(BibleVersionEnum.NKVJ)