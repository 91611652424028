import { registerInDevtools, Store } from 'pullstate'
import { ISearchReference, ISearchTag, ISearchTerm } from 'types/search'

interface ISearchStore {
    references: ISearchReference[]
    tags: ISearchTag[]
    terms: ISearchTerm[]
}

export const SearchStore = new Store<ISearchStore>({
    references: [],
    tags: [],
    terms: []
})

registerInDevtools({SearchStore})