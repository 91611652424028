import React from 'react'
import styled from 'styled-components'
import { ITagEntity } from 'types/entity'

const Container = styled.div<{bgColor: string, borderColor: string}>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${p => p.bgColor};
    border: 4px solid ${p => p.borderColor};
    border-radius: 5rem;
`
const Title = styled.h2`

`

type Props = {
    tag: ITagEntity
}

const EditTagModal = (props: Props) => {
    const { tag } = props

    return (
        <Container bgColor={tag.color.secondary} borderColor={tag.color.primary}>
            <Title>{'Edit Tag ' + tag.text}</Title>
        </Container>
    )
}

export default EditTagModal