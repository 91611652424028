import ColumnIcon from 'components/icons/column-icon'
import { ComparisonModeEnum } from 'enums/ui'
import useClickOutside from 'hooks/useClickOutside'
import React, { useRef } from 'react'
import { UIStore } from 'stores/ui-store'
import styled from 'styled-components'
import { styles } from 'styles/styles'

const Container = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    background-color: ${p => p.theme.backgrounds.white};
    z-index: ${styles.zIndex.dropdown};
    border-radius: ${styles.borderRadius.default};
    box-shadow: ${p => p.theme.shadows.far};
`
const MenuItem = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
    white-space: nowrap;
    padding: 1rem 2rem;
    cursor: pointer;

    &:hover {
        background-color: ${p => p.theme.backgrounds.input};
    }
`
type Props = {
    closeMenu: () => void
}

const BibleComparisonMenu = (props: Props) => {
    const { closeMenu } = props

    const ignoreRef = useRef<HTMLDivElement>(null)

    useClickOutside(closeMenu, ignoreRef)

    const comparisonMode = UIStore.useState(state => state.comparisonMode)

    const onDefaultClick = () => {
        UIStore.update(state => {
            state.comparisonMode = ComparisonModeEnum.Default
        })
        closeMenu()
    }
    const onOneByOneClick = () => {
        UIStore.update(state => {
            state.comparisonMode = ComparisonModeEnum.OneByOne
        })
        closeMenu()
    }
    const onOneByTwoClick = () => {
        UIStore.update(state => {
            state.comparisonMode = ComparisonModeEnum.OneByTwo
        })
        closeMenu()
    }

    return (
        <Container ref={ignoreRef}>
            <MenuItem onClick={onDefaultClick}>
                <ColumnIcon />
                {'Default'}
            </MenuItem>
            <MenuItem onClick={onOneByOneClick}>
                <ColumnIcon />
                {'One by One'}
            </MenuItem>
            <MenuItem onClick={onOneByTwoClick}>
                <ColumnIcon />
                {'One by Two'}
            </MenuItem>
        </Container>
    )
}

export default BibleComparisonMenu