import { Icon } from 'components/common/icon'
import React, { HTMLAttributes } from 'react'

const UnderlineIcon = (props: HTMLAttributes<SVGElement>) => (
    <Icon {...props} viewBox='0 0 24 24'>
        <path d='M6 3v7a6 6 0 0 0 6 6 6 6 0 0 0 6-6V3' />
        <line x1='4' y1='21' x2='20' y2='21' />
    </Icon>
)

export default UnderlineIcon