import { EntityEnum } from 'enums/entity'
import Helper from 'helpers/helper'
import React, { useCallback } from 'react'
import { ITagEntity } from 'types/entity'
import { IColor } from 'types/ui'
import { IReference } from 'types/verse'
import { usePouch } from 'use-pouchdb'

interface IUseTagEntity {
    createTag: (text: string, color: IColor) => void
    deleteTag: (id: string) => void
    addTagToVerses: (id: string, reference: IReference) => void
    removeTagFromVerses: (tag: ITagEntity, reference: IReference) => void
    setFavorite: (tag: ITagEntity, favorite: boolean) => void
    setColor: (tag: ITagEntity, color: IColor) => void
}

export const useTagEntity = () : IUseTagEntity => {
    const db = usePouch()

    const createTag = useCallback(async (text: string, color: IColor) => {
        const tag: ITagEntity = {
            _id: Helper.randomUid(),
            type: EntityEnum.Tag,
            text,
            color,
            references: []
        }

        const result = await db.put<ITagEntity>(tag)

        return result
    }, [db])

    const deleteTag = useCallback(async (id: string) : Promise<boolean> => {
        const tag = await db.get<ITagEntity>(id)
        const result = await db.remove(tag)

        //TODO remove also tagId from all bookmarks and entries as well

        return result.ok
    }, [db])

    const addTagToChapter = () => {

    }

    const addTagToVerses = useCallback(async (id: string, reference: IReference) => {
        const tag = await db.get<ITagEntity>(id)
        //find intersacting references
        //TODO
        // const intersectingReference = tag.references.find(r =>
        //     r.bookId === reference.bookId &&
        //     r.chapterNr === reference.chapterNr &&
        //     (r.subset!.startVerse <= reference.subset!.startVerse && r.subset!.startVerse < reference.subset!.endVerse) ||
        //     (r.subset!.startVerse >= )
        //     )

        // if (!tag.references.some(r => r.bookId === reference.bookId && r.chapterNr === reference.chapterNr && r.verseNr === reference.verseNr)) {
        //     tag.references.push(reference)
        //     tag.lastUsed = new Date().getTime()
        // }
        await db.put<ITagEntity>(tag)
    }, [db])

    const removeTagFromVerses = useCallback(async (tag: ITagEntity, reference: IReference) => {
        //TODO
        // tag.references = tag.references.filter(r => !(r.bookId === reference.bookId && r.chapterNr === reference.chapterNr && r.verseNr === reference.verseNr))
        await db.put<ITagEntity>(tag)
    }, [db])

    const setFavorite = useCallback(async (tag: ITagEntity, favorite: boolean) => {
        tag.favorite = favorite
        await db.put<ITagEntity>(tag)
    }, [db])

    const setColor = useCallback(async (tag: ITagEntity, color: IColor) => {
        tag.color = color
        await db.put<ITagEntity>(tag)
    }, [db])

    return {
        createTag,
        deleteTag,
        addTagToVerses,
        removeTagFromVerses,
        setFavorite,
        setColor
    }
}