import HeartIcon from 'components/icons/heart-icon'
import ShuffleIcon from 'components/icons/shuffle-icon'
import { EntityEnum } from 'enums/entity'
import { ComparisonCardEnum, SearchLabelEnum } from 'enums/ui'
import Helper from 'helpers/helper'
import React from 'react'
import { useNavigate } from 'react-router'
import BibleService from 'services/bible-service'
import { SearchStore } from 'stores/search-store'
import { UIStore } from 'stores/ui-store'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import { IFavoriteEntity } from 'types/entity'
import { useFind } from 'use-pouchdb'

const Container = styled.div`
    min-width: 25%;
    max-width: 50%;
    border-radius: ${styles.borderRadius.default};
    box-shadow: ${p => p.theme.shadows.close};
    padding: 3rem;
`
const Header = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-bottom: 2rem;
`
const Title = styled.h3`
    font-size: ${styles.fontSizes.larger};
    font-family: ${styles.fonts.bold};
    letter-spacing: ${p => p.theme.letterSpacing.medium};
    font-weight: 700;
    margin: 0;
`
const StyledHeartIcon = styled(HeartIcon)`
    fill: #ff496c;
    stroke-width: 1;
`
const StyledShuffleIcon = styled(ShuffleIcon)`
    margin-left: auto;
    cursor: pointer;
`
const VerseContainer = styled.div`
    padding: 2rem;
    background-color: ${p => p.theme.backgrounds.content};
    border-radius: ${styles.borderRadius.small};
    cursor: pointer;
`
const VerseTitle = styled.h4`
    margin: 0;
    margin-bottom: 1rem;
`
const VerseText = styled.p<{fontSerifs?: boolean}>`
    font-family: ${p => p.fontSerifs ? styles.fonts.readerSerifs : styles.fonts.reader};
    margin: 0;
`

const FavoriteCard = () => {
    const navigate = useNavigate()
    const fontSerifs = UIStore.useState(state => state.fontSerifs)

    const { docs: favorites } = useFind<IFavoriteEntity>({
        index: { fields: ['type'] },
        selector: {
            type: EntityEnum.Favorite
        }
    })

    if (favorites.length === 0) return null

    const randomIndex = Helper.randomInt(0, favorites.length - 1)
    const randomFavorite = favorites[randomIndex]

    const referenceText = BibleService.convertVerseReferenceToString(randomFavorite.reference)
    const verseText = BibleService.getBookById(randomFavorite.reference.version, randomFavorite.reference.bookId)
        .chapters.find(chapter => chapter.num === randomFavorite.reference.chapterNr)!
        .verses.find(verse => verse.num === randomFavorite.reference.verseNr)!
        .text

    const onVerseClick = () => {
        SearchStore.update(state => {
            state.references = [{
                labelType: SearchLabelEnum.Reference,
                comparisonCard: ComparisonCardEnum.Default,
                orderId: 0,
                reference: {
                    bookId: randomFavorite.reference.bookId,
                    version: randomFavorite.reference.version,
                    chapterNr: randomFavorite.reference.chapterNr
                }
            }]
        })

        navigate('/bible/overview')
    }

    return (
        <Container>
            <Header>
                <StyledHeartIcon />
                <Title>{'Favorite Verse'}</Title>
                <StyledShuffleIcon />
            </Header>
            <VerseContainer onClick={onVerseClick}>
                <VerseTitle>{referenceText}</VerseTitle>
                <VerseText fontSerifs={fontSerifs}>{verseText}</VerseText>
            </VerseContainer>
        </Container>
    )
}

export default FavoriteCard