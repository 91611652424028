import { BibleBookEnum, BibleVersionEnum } from 'enums/bible'
import React, { useEffect, useRef } from 'react'
import BibleService from 'services/bible-service'
import Helper from 'helpers/helper'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import Stopwatch from 'helpers/stopwatch'
import HashIcon from 'components/icons/hash-icon'
import { IWord, IWordCount, IRect } from 'types/word'

const Container = styled.div`
    position: relative;
    padding: 2rem;
    width: 100%;
    border-radius: ${styles.borderRadius.default};
    background-color: ${p => p.theme.backgrounds.white};
    box-shadow: ${p => p.theme.shadows.medium};
`
const Title = styled.h3`
    font-family: ${styles.fonts.bold};
    font-size: ${styles.fontSizes.larger};
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 1rem;
    display: inline-flex;
    align-items: center;
    column-gap: 1rem;
`
const Canvas = styled.canvas`
    width: 100%;
    align-self: center;
`

enum SideEnum {
    Top,
    Left,
    Bottom,
    Right
}

const paddingCorrection = .8

const WordCloud = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        const createWordCloud = () : IWord[] => {
            const book = BibleService.getBookById(BibleVersionEnum.NKVJ, BibleBookEnum.Romans)
        
            const sw = Stopwatch.startNew('Extract words')
            const wordCounts: IWordCount = {}
            for (const chapter of book.chapters) {
                for (const verse of chapter.verses) {
                    for (const word of filterWordsFromText(verse.text)) {
                        if (wordCounts[word]) {
                            wordCounts[word]++
                        } else {
                            wordCounts[word] = 1
                        }
                    }
                }
            }
            sw.stop()

            const sortedWordCounts: IWord[] = Object.entries(wordCounts)
                .map((entry, index) => ({
                    text: entry[0],
                    count: entry[1],
                    index,
                    rect: {x: -1, y: -1, width: 0, height: 0}
                }))
                .sort((a, b) => b.count - a.count)
                .slice(0, 200)

            sortedWordCounts.forEach(w => w.text = w.text.toUpperCase())

            return sortedWordCounts
        }

        const filterWordsFromText = (text: string) : string[] => {
            const wordsToFilter: string[] = [
                'the', 'of', 'and', 'a', 'to', 'in', 'who', 'why', 'they', 'are', 'for',
                'you', 'have', 'their', 'by', 'our', 'not', 'with', 'all', 'but', 'which',
                'these', 'i', 'out', 'be', 'those', 'were', 'did', 'them', 'while', 'is',
                'that', 'having', 'was', 'also', 'on', 'this', 'without', 'then', 'up',
                'down', 'he', 'his', 'it', 'has', 'him', 'from', 'your', 'my', 'her',
                'thus', 'me', 'do', 'does', 'as', 'there', 'no'
            ]
            const charsToFilter: string[] = [
                '?', '.', ':', ',', ';', '"', '\'', '(', ')'
            ]
            const charFilteredRegExp = new RegExp(`[${charsToFilter.join('')}]`, 'gim')
            const wordFilteredRegExp = new RegExp('\\b(' + wordsToFilter.join('|') + ')\\b', 'gim')
 
            let filteredText = text.replace(charFilteredRegExp, '')
            filteredText = filteredText.replace(wordFilteredRegExp, '')

            const wordCountRegExp = new RegExp('\\b\\S+\\b', 'gim')
            const matches = filteredText.match(wordCountRegExp)
        
            return matches ?? []
        }

        const drawWordCloud = (words: IWord[]) => {
            const canvas = canvasRef.current!
            const ctx = canvas.getContext('2d')!
            canvas.width = canvas.clientWidth
            canvas.height = canvas.clientHeight

            const rootWord = words[0]
            const rootWordHeight = 50

            ctx.textBaseline = 'top'
            ctx.font = `${rootWordHeight}px ${styles.fonts.default}`
            rootWord.rect.width = ctx.measureText(rootWord.text).width
            rootWord.rect.height = rootWordHeight * paddingCorrection
            rootWord.rect.x = ctx.canvas.width / 2 - rootWord.rect.width / 2
            rootWord.rect.y = ctx.canvas.height / 2 - rootWord.rect.height / 2

            // ctx.fillStyle = 'red'
            // ctx.fillRect(rootWord.rect.x, rootWord.rect.y, rootWord.rect.width, rootWord.rect.height)

            ctx.fillStyle = 'black'
            ctx.fillText(rootWord.text, rootWord.rect.x, rootWord.rect.y)

            for (let i = 1; i < words.length; i++) {
                tryDrawWord(words[i], words, ctx)
            //if a word cannot be fit in anymore, it will still continue to see if others may fit.
            //I could just break and stop using the bool return
            }
        }

        const tryDrawWord = (word: IWord, words: IWord[], ctx: CanvasRenderingContext2D) : boolean => {
            const maxCount = words[0].count
            const canvasWidth = ctx.canvas.width
            const canvasHeight = ctx.canvas.height

            const height = (25 * word.count) / maxCount + 10
            ctx.font = `${height}px ${styles.fonts.default}`
            word.rect.height = height * paddingCorrection
            word.rect.width = ctx.measureText(word.text).width
        
            const drawnWords = words.filter(w => w.rect.x !== -1 && w.rect.y !== -1)
            for (const attachWord of drawnWords) {
                const sides = [...Array(Object.values(SideEnum).length / 2).keys()]
            
                Helper.shuffleArray(sides)

                for (const side of sides) {
                    let wordFits = false

                    if (side === SideEnum.Top) {
                        wordFits = tryAttachTop(word, drawnWords, attachWord, canvasWidth, canvasHeight)
                    } else if (side === SideEnum.Bottom) {
                        wordFits = tryAttachBottom(word, drawnWords, attachWord, canvasWidth, canvasHeight)
                    } else if (side === SideEnum.Left) {
                        wordFits = tryAttachLeft(word, drawnWords, attachWord, canvasWidth, canvasHeight)
                    } else if (side === SideEnum.Right) {
                        wordFits = tryAttachRight(word, drawnWords, attachWord, canvasWidth, canvasHeight)
                    }

                    if (wordFits) {
                    // ctx.fillStyle = chroma.random().hex()
                    // ctx.fillRect(word.rect.x, word.rect.y, word.rect.width, word.rect.height)
                        ctx.fillStyle = 'black'
                        ctx.fillText(word.text, word.rect.x, word.rect.y)
                        return true
                    }
                }
            }

            return false
        }

        const tryAttachTop = (word: IWord, drawnWords: IWord[], wordToAttach: IWord, canvasWidth: number, canvasHeight: number) : boolean => {
            const maxSpaceTop: IRect = {
                x: wordToAttach.rect.x - word.rect.width / 2,
                y: wordToAttach.rect.y - word.rect.height,
                width: word.rect.width + wordToAttach.rect.width,
                height: word.rect.height
            }
        
            if (maxSpaceTop.x < 0) {
                maxSpaceTop.x = 0
            }

            if (maxSpaceTop.x + maxSpaceTop.width > canvasWidth) {
                maxSpaceTop.width -= maxSpaceTop.x + maxSpaceTop.width - canvasWidth
            }
        
            if (maxSpaceTop.x + word.rect.width > canvasWidth || maxSpaceTop.y < 0)
                return false

            const availableSpaceTop = drawnWords.reduce((space: IRect, w: IWord) => {
                if (word.text !== w.text && hasCollision(space, w.rect)) {
                
                    const leftSpace = w.rect.x - space.x
                    const rightSpace = space.width - w.rect.x - w.rect.width

                    if (leftSpace > rightSpace && leftSpace > 0) {
                        space.width = w.rect.x
                    } else if (rightSpace > 0) {
                        space.width = rightSpace
                        space.x = w.rect.x + w.rect.width
                    }
                }

                return space
            }, maxSpaceTop)

            if (availableSpaceTop.width > word.rect.width) {
                word.rect.x = availableSpaceTop.x
                word.rect.y = availableSpaceTop.y
                return true
            }

            return false
        }

        const tryAttachLeft = (word: IWord, drawnWords: IWord[], wordToAttach: IWord, canvasWidth: number, canvasHeight: number) : boolean => {
            const maxSpaceLeft: IRect = {
                x: wordToAttach.rect.x - word.rect.width,
                y: wordToAttach.rect.y - word.rect.height / 2,
                width: word.rect.width,
                height: word.rect.height + wordToAttach.rect.height
            }

            if (maxSpaceLeft.y < 0) {
                maxSpaceLeft.y = 0
            }

            if (maxSpaceLeft.y + maxSpaceLeft.height > canvasHeight) {
                maxSpaceLeft.height -= maxSpaceLeft.y + maxSpaceLeft.height - canvasHeight
            }

            if (maxSpaceLeft.x < 0 || maxSpaceLeft.y + word.rect.height > canvasHeight)
                return false

            const availableSpaceLeft = drawnWords.reduce((space: IRect, w: IWord) => {
                if (word.text !== w.text && hasCollision(space, w.rect)) {
                    const topSpace = w.rect.y - space.y
                    const bottomSpace = w.rect.y + w.rect.height - space.y

                    if (topSpace > bottomSpace) {
                        space.height -= w.rect.height + bottomSpace
                    } else {
                        space.y += w.rect.height + topSpace
                    }
                }

                return space
            }, maxSpaceLeft)

            if (availableSpaceLeft.height > word.rect.height) {
                word.rect.x = availableSpaceLeft.x
                word.rect.y = availableSpaceLeft.y
                return true
            }

            return false
        }

        const tryAttachRight = (word: IWord, drawnWords: IWord[], wordToAttach: IWord, canvasWidth: number, canvasHeight: number) : boolean => {
            const maxSpaceRight: IRect = {
                x: wordToAttach.rect.x + wordToAttach.rect.width,
                y: wordToAttach.rect.y - word.rect.height / 2,
                width: word.rect.width,
                height: word.rect.height + wordToAttach.rect.height
            }

            if (maxSpaceRight.y < 0) {
                maxSpaceRight.y = 0
            }

            if (maxSpaceRight.y + maxSpaceRight.height > canvasHeight) {
                maxSpaceRight.height -= maxSpaceRight.y + maxSpaceRight.height - canvasHeight
            }

            if (maxSpaceRight.x + word.rect.width > canvasWidth || maxSpaceRight.y + word.rect.height > canvasHeight)
                return false

            const availableSpaceRight = drawnWords.reduce((space: IRect, w: IWord) => {
                if (word.text !== w.text && hasCollision(space, w.rect)) {
                    const topSpace = w.rect.y - space.y
                    const bottomSpace = w.rect.y + w.rect.height - space.y

                    if (topSpace > bottomSpace) {
                        space.height -= w.rect.height + bottomSpace
                    } else {
                        space.y += w.rect.height + topSpace
                    }
                }

                return space
            }, maxSpaceRight)

            if (availableSpaceRight.height > word.rect.height) {
                word.rect.x = availableSpaceRight.x
                word.rect.y = availableSpaceRight.y
                return true
            }

            return false
        }

        const tryAttachBottom = (word: IWord, drawnWords: IWord[], wordToAttach: IWord, canvasWidth: number, canvasHeight: number) : boolean => {
            const maxSpaceBottom: IRect = {
                x: wordToAttach.rect.x - word.rect.width / 2,
                y: wordToAttach.rect.y + wordToAttach.rect.height,
                width: word.rect.width + wordToAttach.rect.width,
                height: word.rect.height
            }

            if (maxSpaceBottom.x < 0) {
                maxSpaceBottom.x = 0
            }

            if (maxSpaceBottom.x + maxSpaceBottom.width > canvasWidth) {
                maxSpaceBottom.width -= maxSpaceBottom.x + maxSpaceBottom.width - canvasWidth
            }

            if (maxSpaceBottom.y + word.rect.height > canvasHeight || maxSpaceBottom.x + word.rect.width > canvasWidth)
                return false

            const availableSpaceBottom = drawnWords.reduce((space: IRect, w: IWord) => {
                if (word.text !== w.text && hasCollision(space, w.rect)) {
                
                    const leftSpace = w.rect.x - space.x
                    const rightSpace = space.width - w.rect.x + space.width - w.rect.width

                    if (leftSpace > rightSpace && leftSpace > 0) {
                        space.width = w.rect.x
                    } else if (rightSpace > 0) {
                        space.width = rightSpace
                        space.x = w.rect.x + w.rect.width
                    }
                }

                return space
            }, maxSpaceBottom)

            if (availableSpaceBottom.width > word.rect.width) {
                word.rect.x = availableSpaceBottom.x
                word.rect.y = availableSpaceBottom.y
                return true
            }

            return false
        }

        const hasCollision = (rect1: IRect, rect2: IRect) : boolean =>
            rect1.x < rect2.x + rect2.width &&
            rect1.x + rect1.width > rect2.x &&
            rect1.y < rect2.y + rect2.height &&
            rect1.y + rect1.height > rect2.y

        //bigger words randomly in the canvas with smaller words around
        //color changes with size => Same size => same color
        //same sized objects should not be next to each other

        const words = createWordCloud()
        drawWordCloud(words)
    }, [])

    return (
        <Container>
            <Title>
                <HashIcon />
                {'WORDS'}
            </Title>
            <Canvas ref={canvasRef} />
        </Container>
    )
}

export default WordCloud