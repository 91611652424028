import { BibleBookEnum, BibleVersionEnum } from 'enums/bible'
import { EntityEnum } from 'enums/entity'
import { LanguagesEnum } from 'enums/ui'
import { registerInDevtools, Store } from 'pullstate'
import { IProfileEntity } from 'types/entity'

interface IProfileStore {
    profile?: IProfileEntity
}

export const ProfileStore = new Store<IProfileStore>({
    profile: {
        _id: 'profile1',
        email: 'test@test.de',
        bibleVersion: BibleVersionEnum.NKVJ,
        language: LanguagesEnum.English,
        recentSearches: [{
            _id: '',
            orderId: -1,
            references: [{
                version: BibleVersionEnum.NKVJ,
                bookId: BibleBookEnum.Chronicles1,
                chapterNr: 1
            }],
            tagIds: [],
            terms: [],
            text: 'Custom Search',
            type: EntityEnum.Bookmark
        }],
        type: EntityEnum.Profile,
        username: 'Alexander Badak',
        password: 'pw',
        settings: {

        }
    }
})

registerInDevtools({ProfileStore})