import { DndContext } from '@dnd-kit/core'
import Bookmark from 'components/bookmark'
import BookmarkIcon from 'components/icons/bookmark-icon'
import EditTextIcon from 'components/icons/edit-text-icon'
import SearchBar from 'components/search-bar'
import { EntityEnum } from 'enums/entity'
import { ComparisonCardEnum, SearchLabelEnum, ToastMessageEnum } from 'enums/ui'
import Helper from 'helpers/helper'
import CreateBookmarkModal from 'modals/create-bookmark-modal'
import DeleteBookmarkModal from 'modals/delete-bookmark-modal'
import React, { useState } from 'react'
import BibleService from 'services/bible-service'
import { SearchStore } from 'stores/search-store'
import { UIStore } from 'stores/ui-store'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import { IBookmarkEntity, ITagEntity } from 'types/entity'
import { ISearchReference, ISearchTag, ISearchTerm } from 'types/search'
import { useFind } from 'use-pouchdb'

const Container = styled.div`
    position: relative;
    padding: 2rem;
    width: 100%;
    border-radius: ${styles.borderRadius.default};
    background-color: ${p => p.theme.backgrounds.white};
    box-shadow: ${p => p.theme.shadows.medium};
`
const Title = styled.h3`
    font-family: ${styles.fonts.bold};
    font-size: ${styles.fontSizes.larger};
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 1rem;
    display: inline-flex;
    align-items: center;
    column-gap: 1rem;
`
const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`
const ToolbarContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-bottom: 1rem;
`
const AddIconContainer = styled.div`
    display: grid;
    place-items: center;
    cursor: pointer;
    border-radius: ${styles.borderRadius.default};
    background-color: ${p => p.theme.backgrounds.input};
    padding: .7rem;
`
const StyledAddIcon = styled(EditTextIcon)`
    stroke: ${p => p.theme.colors.textInput};

    &:hover {
        stroke: ${p => p.theme.colors.text};
    }
`

const Bookmarks = () => {
    const { docs : bookmarks, loading, error } = useFind<IBookmarkEntity>({
        index: {fields: ['type']},
        selector: {type: EntityEnum.Bookmark}
    })

    const { docs: tags } = useFind<ITagEntity>({
        index: {fields: ['type']},
        selector: {type: EntityEnum.Tag}
    })

    const [searchText, setSearchText] = useState<string>('')

    const onBookmarkDelete = (bookmark: IBookmarkEntity) => {
        UIStore.update(state => {
            state.modal = <DeleteBookmarkModal bookmark={bookmark} />
        })
    }

    const onSetActiveBookmark = (bookmark: IBookmarkEntity) => {
        //TODO ? This shouldnt be an order id like this
        let orderId = 0

        const searchReferences = bookmark.references.map<ISearchReference>(r => ({
            labelType: SearchLabelEnum.Reference,
            comparisonCard: ComparisonCardEnum.Default,
            orderId: orderId++,
            reference: r
        }))

        const searchTags = bookmark.tagIds.map<ISearchTag | undefined>(id => {
            const tag = tags.find(t => t._id === id)
            if (tag) {
                const searchTag: ISearchTag = {
                    tagId: tag._id,
                    orderId: orderId++,
                    color: tag.color,
                    labelType: SearchLabelEnum.Tag,
                    comparisonCard: ComparisonCardEnum.Default,
                    references: tag.references,
                    text: tag.text
                }

                return searchTag
            }

            return undefined
        }).filter(tag => !!tag) as ISearchTag[]

        const searchTerms = bookmark.terms.map<ISearchTerm>(term => {
            const searchResults = BibleService.searchBibleExact(term)

            const searchTerm: ISearchTerm = {
                text: term,
                count: searchResults.count,
                labelType: SearchLabelEnum.Term,
                comparisonCard: ComparisonCardEnum.Default,
                orderId: orderId++,
                references: searchResults.references
            }

            return searchTerm
        })

        SearchStore.update(state => {
            state.references = searchReferences
            state.tags = searchTags
            state.terms = searchTerms
        })
    }

    const onAddBookmarkClick = () => {
        const { references, tags, terms } = SearchStore.getRawState()

        if (references.length > 0 || tags.length > 0 || terms.length > 0) {
            UIStore.update(state => {
                state.modal = (
                    <CreateBookmarkModal
                        bookmarks={bookmarks}
                        references={references}
                        terms={terms}
                        tags={tags}
                    />)
            })
        } else {
            UIStore.update(state => {
                state.toastMessages.push({
                    id: Helper.randomUid(),
                    text: 'No Search selected',
                    type: ToastMessageEnum.Info
                })
            })
        }
    }

    const sortedBookmarks = bookmarks
        .filter(b => b.text.toLowerCase().includes(searchText))
        .sort((a, b) => b.orderId - a.orderId)

    return (
        <DndContext>
            <Container>
                <Title>
                    <BookmarkIcon />
                    {'BOOKMARKS'}
                </Title>
                <ToolbarContainer>
                    <SearchBar setText={setSearchText} text={searchText} placeholder='Search Bookmark' />
                    <AddIconContainer onClick={onAddBookmarkClick}>
                        <StyledAddIcon />
                    </AddIconContainer>
                </ToolbarContainer>
                <ListContainer>
                    {
                        sortedBookmarks.map(bookmark => (
                            <Bookmark
                                key={`bookmark-${bookmark._id}`}
                                bookmark={bookmark}
                                onDelete={() => onBookmarkDelete(bookmark)}
                                onClick={() => onSetActiveBookmark(bookmark)}
                            />
                        ))
                    }
                </ListContainer>
            </Container>
        </DndContext>
    )
}

export default Bookmarks