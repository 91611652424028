import BibleTag from 'components/bible-tag'
import EntryIcon from 'components/icons/entry-icon'
import { EntityEnum } from 'enums/entity'
import Helper from 'helpers/helper'
import React from 'react'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import { IEntryEntity, ITagEntity } from 'types/entity'
import { useAllDocs, useFind } from 'use-pouchdb'

const Container = styled.div`
    min-width: 25%;
    max-width: 50%;
    border-radius: ${styles.borderRadius.default};
    box-shadow: ${p => p.theme.shadows.close};
    padding: 3rem;
`
const Header = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-bottom: 2rem;
`
const Title = styled.h3`
    font-size: ${styles.fontSizes.larger};
    font-family: ${styles.fonts.bold};
    letter-spacing: ${p => p.theme.letterSpacing.medium};
    font-weight: 700;
    margin: 0;
`
const StyledEntryIcon = styled(EntryIcon)`

`

const TopicEntryCard = () => {
    const { docs: entries} = useFind<IEntryEntity>({
        index: {fields: ['type', 'reference']},
        fields: ['tagIds'],
        selector: {
            type: EntityEnum.Entry,
            reference: null
        }
    })

    const allEntryTagIds = entries.flatMap(e => e.tagIds)
    const { rows } = useAllDocs({
        keys: allEntryTagIds,
        include_docs: true
    })

    const entryTags = rows.map(r => r.doc as ITagEntity)
    const randomTag = rows.length > 0 ? entryTags[Helper.randomInt(0, rows.length - 1)] : undefined
    //change to randomize the entry instead of tag

    return (
        <Container>
            <Header>
                <StyledEntryIcon />
                <Title>{'Random Topic Entry'}</Title>
            </Header>
            {
                randomTag ?
                    <BibleTag tag={randomTag} /> :
                    'You do not seem to have any tags, go ahead and create one!'
            }
        </Container>
    )
}

export default TopicEntryCard