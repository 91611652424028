import { useDraggable } from '@dnd-kit/core'
import RemoveIcon from 'components/icons/remove-icon'
import { DragTargetEnum, MouseButtonEnum } from 'enums/ui'
import Helper from 'helpers/helper'
import React, { useState } from 'react'
import BibleService from 'services/bible-service'
import { SearchStore } from 'stores/search-store'
import styled, { CSSProperties } from 'styled-components'
import { styles } from 'styles/styles'
import { ISearchReference } from 'types/search'

const Wrapper = styled.div`
    display: inline-flex;
    align-items: center;
    column-gap: 1rem;
    padding: .75rem .75rem .75rem 1.5rem;
    border-radius: ${styles.borderRadius.large};
    background-color: ${p => p.theme.colors.primaryBright};
    cursor: pointer;
    outline: 0;
    border: none;
`
const Container = styled.div`
    font-family: ${styles.fonts.default};
    color: ${p => p.theme.backgrounds.white};
    white-space: nowrap;
`
const DeleteButton = styled.div`
    font-size: .6em;
    background-color: ${p => p.theme.backgrounds.input};
    border-radius: 50%;
    display: grid;
    padding: .15rem;
    cursor: pointer;
    place-items: center;
`
const StyledRemoveIcon = styled(RemoveIcon)`
    stroke: ${p => p.theme.colors.text};
`

type Props = {
    reference: ISearchReference
}

const SearchReference = (props: Props) => {
    const { reference } = props

    const [dragId] = useState<string>(Helper.randomUid())

    const { transform, listeners, attributes, setNodeRef } = useDraggable({
        id: dragId,
        data: {
            type: DragTargetEnum.SearchLabel,
            id: reference.id
        }
    })

    const referenceText = BibleService.convertReferenceToString(reference.reference)

    const onRemoveClick = (event: React.MouseEvent) => {
        event.stopPropagation()
        SearchStore.update(state => {
            state.references = state.references.filter(r => r.orderId !== reference.orderId)
        })
    }

    const onMiddleMouseUp = (event: React.MouseEvent) => {
        if (event.button === MouseButtonEnum.Middle) {
            SearchStore.update(state => {
                state.references = state.references.filter(r => r.orderId !== reference.orderId)
            })
        }
    }

    //right click allow modify / refine the reference

    const getStyle = () : CSSProperties => {
        const style: CSSProperties = {}
        if (transform) {
            style.transform = `translate3d(${transform.x}px, ${transform.y}px, 0)`
            style.zIndex = styles.zIndex.drag
        }

        return style
    }

    return (
        <Wrapper style={getStyle()} onMouseUp={onMiddleMouseUp}>
            <Container ref={setNodeRef} {...listeners} {...attributes}>
                {referenceText}
            </Container>
            <DeleteButton onClick={onRemoveClick}>
                <StyledRemoveIcon />
            </DeleteButton>
        </Wrapper>

    )
}

export default SearchReference