export enum LanguagesEnum {
    English,
    German,
    Spanish
}

export enum ListStateEnum {
    Books,
    Chapters,
    Arbitrary
}

export enum ToastMessageEnum {
    Success,
    Error,
    Warning,
    Info
}

export enum SearchLabelEnum {
    Tag,
    Term,
    Reference
}

export enum DragTargetEnum {
    None,
    Tag,
    Verse,
    SearchLabel,
    ComparisonCard
}

export enum MouseButtonEnum {
    Left = 0,
    Middle = 1,
    Right = 2
}

export enum KeyboardButtonEnum {
    Enter = 'Enter',
    ArrowUp = 'ArrowUp',
    ArrowDown = 'ArrowDown',
    Escape = 'Escape'
}

export enum ComparisonModeEnum {
    Default,
    OneByOne,
    OneByTwo
}

export enum ComparisonCardEnum {
    Default,
    CardA,
    CardB
}

export enum SelectionMenuEnum {
    None,
    Highlight,
    CrossReference
}

export enum VerseCrossReferenceEnum {
    None,
    ShowIcon,
    HideIcon
}