import { EntityEnum } from 'enums/entity'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import ProfileService from 'services/profile-service'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import { IProfileEntity } from 'types/entity'
import { useFind } from 'use-pouchdb'

const ViewContainer = styled.div`
    position: relative;
    width: 100vw;
    padding-top: 1rem;
    padding-bottom 1rem;
    height: 100%;
    display: flex;
    overflow: hidden;
`
const ProfileCard = styled.div`
    display: grid;
    place-items: center;
    cursor: pointer;
    height: 20rem;
    width: 50rem;
    border: 1px solid lightgrey;
    font-size: ${styles.fontSizes.large};
    border-radius: ${styles.borderRadius.default};
`

const Login = () => {
    const navigate = useNavigate()

    const { docs: profiles, loading, error } = useFind<IProfileEntity>({
        index: {fields: ['type']},
        selector: {
            type: EntityEnum.Profile
        }
    })

    const onLogin = (profile: IProfileEntity) => {
        ProfileService.login(profile)
        navigate('/bible/overview')
    }

    return (
        <ViewContainer>
            {
                profiles.map(p => (
                    <ProfileCard key={`profile-${p._id}`} onClick={() => onLogin(p)}>
                        {p.username}
                    </ProfileCard>
                ))
            }
        </ViewContainer>
    )
}

export default Login