import React from 'react'
import { ProfileStore } from 'stores/profile-store'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import ActivityCard from './activity-card'
import FavoriteCard from './favorite-card'
import QuickEntryCard from './quick-entry-card'
import RecentPassagesCard from './recent-passages-card'
import TopicEntryCard from './topic-entry-card'

const ViewContainer = styled.div`
    position: relative;
    width: 100vw;
    padding: 2rem;
    display: flex;
    overflow: hidden;
`
const Container = styled.div`
    padding: 2rem;
    background-color: ${p => p.theme.backgrounds.white};
    border-radius: ${styles.borderRadius.default};
    box-shadow: ${p => p.theme.shadows.medium};
`
const Title = styled.h1`
    font-size: ${styles.fontSizes.huge};
    font-family: ${styles.fonts.bold};
    letter-spacing: ${p => p.theme.letterSpacing.small};
    font-weight: 700;
    padding-left: 10rem;
    padding-top: 5rem;
    color: ${p => p.theme.colors.text};
    margin: 0;
`
const CardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 3rem 10rem;
`

const Dashboard = () => {
    const { profile } = ProfileStore.useState(state => state)

    return (
        <ViewContainer>
            <Container>
                <Title>{`Welcome, ${profile?.username}!`}</Title>
                <CardsContainer>
                    <RecentPassagesCard />
                    <FavoriteCard />
                    <QuickEntryCard />
                    <TopicEntryCard />
                    <ActivityCard />
                </CardsContainer>
            </Container>
        </ViewContainer>
    )
}

export default Dashboard