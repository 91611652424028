export const styles = {
    colors: {
        primary: '#630c50',
        primaryDarker: '',
        primaryBrighter: '#89497d',
        secondary: '#c99c4b',
        secondaryDarker: '',
        secondaryBrighter: '#d7b47a',
        text: '#212427',
        textLight: '',
        textInput: '#5E737D'
    },
    fonts: {
        default: 'FiraSans, Trebuchet MS, sans-serif',
        reader: 'Open-Sans, Trebuchet MS, sans-serif',
        readerBold: 'Open-Sans-Bold, Trebuchet MS, sans-serif',
        readerSerifs: 'Merriweather, Trebuchet MS, sans-serif',
        readerSerifsBold: 'Merriweather-Bold, Trebuchet MS, sans-serif',
        bold: 'FiraSans-Bold, Trebuchet MS, sans-serif',
        medium: 'FiraSans-Medium, Trebuchet MS, sans-serif',
        light: 'FiraSans-Light, Trebuchet MS, sans-serif'
    },
    fontSizes: {
        tiny: '.563em',
        small: '.75em',
        smaller: '.85em',
        default: '1em',
        larger: '1.333em',
        large: '1.777em',
        huge: '2.369em',
        massive: '3.157em',
        gigantic: '4.209em'
    },
    borderRadius: {
        small: '.5rem',
        default: '1rem',
        large: '5rem'
    },
    zIndex: {
        default: 0,
        level1: 1,
        level2: 2,
        level3: 3,
        drag: 4,
        clickAway: 5,
        dropdown: 6,
        toastmessage: 7,
        modal: 8,
        alert: 9
    }
}