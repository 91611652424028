import { useBookmarkEntity } from 'hooks/pouchdb/useBookmarkEntity'
import React from 'react'
import { UIStore } from 'stores/ui-store'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import { IBookmarkEntity } from 'types/entity'

const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: ${styles.borderRadius.default};
    background: white;
    padding: 2rem;
`
const Title = styled.h2`

`
const Button = styled.button`

`

type Props = {
    bookmark: IBookmarkEntity
}

const DeleteBookmarkModal = (props: Props) => {
    const { bookmark } = props

    const { deleteBookmark } = useBookmarkEntity()

    const onCancelClick = () => {
        UIStore.update(state => {
            state.modal = undefined
        })
    }

    const onConfirmClick = () => {
        deleteBookmark(bookmark._id)

        UIStore.update(state => {
            state.modal = undefined
        })
    }

    return (
        <Container>
            <Title>{'Delete Bookmark'}</Title>
            {'Are you sure you want to delete the bookmark ' + bookmark.text + '?'}
            <Button onClick={onCancelClick}>
                {'Cancel'}
            </Button>
            <Button onClick={onConfirmClick}>
                {'Confirm'}
            </Button>
        </Container>
    )
}

export default DeleteBookmarkModal