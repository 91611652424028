import React, { useState } from 'react'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import RemoveIcon from './icons/remove-icon'
import SearchIcon from './icons/search-icon'

const Container = styled.div<{active?: boolean}>`
    display: flex;
    align-items: center;
    padding: 0 1.55rem;
    font-size: .75em;
    column-gap: 1rem;
    width: ${p => p.active ? '20rem' : '5rem' };
    background-color: ${p => p.theme.backgrounds.input};
    transition: width .2s ease;
    border-radius: ${styles.borderRadius.large};
    font-size: ${styles.fontSizes.small};

    &:hover {
        width: 20rem;
    }
`

const StyledSearchIcon = styled(SearchIcon)`
    stroke: ${p => p.theme.colors.textInput};
    flex-shrink: 0;
`
const SearchInput = styled.input`
    font-family: ${styles.fonts.default};
    font-size: ${styles.fontSizes.larger};
    width: 100%;
    height: 3rem;
    border: none;
    outline: 0;
    background: transparent;
    padding: 0;
`
const ClearTextButton = styled.div`
    display: grid;
    place-items: center;
    border-radius: 50%;
    margin-left: auto;
    cursor: pointer;
    font-size: .75em;
`
const StyledClearButtonIcon = styled(RemoveIcon)`
    stroke: ${p => p.theme.colors.textInput};

    &:hover {
        stroke: ${p => p.theme.colors.text};
    }
`

type Props = {
    value: string
    setValue: (value: string) => void
}

const PassageSearch = (props: Props) => {
    const { value, setValue } = props

    const [showSearch, setShowSearch] = useState<boolean>(false)

    const onFocus = () => {
        setShowSearch(true)
    }

    const onBlur = () => {
        setShowSearch(false)
    }

    const onInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setValue(value)
    }

    const onClearClick = (event: React.MouseEvent) => {
        event.stopPropagation()
        setValue('')
        setShowSearch(false)
    }

    const active = showSearch || value !== ''

    return (
        <Container active={active} onClick={() => setShowSearch(true)}>
            <StyledSearchIcon />
            <SearchInput onFocus={onFocus} onBlur={onBlur} value={value} onInput={onInput} onChange={onInput} />
            {
                value !== '' &&
                <ClearTextButton onClick={onClearClick}>
                    <StyledClearButtonIcon />
                </ClearTextButton>
            }
        </Container>
    )
}

export default PassageSearch