import { EntityEnum } from 'enums/entity'
import { ComparisonCardEnum, SearchLabelEnum } from 'enums/ui'
import { useCrossReferenceEntity } from 'hooks/pouchdb/useCrossReferenceEntity'
import React, { useState } from 'react'
import BibleService from 'services/bible-service'
import { ProfileStore } from 'stores/profile-store'
import { SearchStore } from 'stores/search-store'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import { ICrossReferenceEntity } from 'types/entity'
import { ISearchReferenceResult } from 'types/search'
import { IReference } from 'types/verse'
import { useFind } from 'use-pouchdb'
import RemoveIcon from './icons/remove-icon'
import SearchIcon from './icons/search-icon'

const Container = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 50rem;
    padding: 1.5rem;
    background-color: ${p => p.theme.backgrounds.white};
    box-shadow: ${p => p.theme.shadows.far};
    border-radius: ${styles.borderRadius.default};
    z-index: ${styles.zIndex.dropdown};
    cursor: default;
    line-height: normal;
    -webkit-text-stroke: 0;
`
const CrossReferenceMenuTitle = styled.h4`
    display: flex;
    font-size: ${styles.fontSizes.larger};
    font-family: ${styles.fonts.bold};
    font-weight: 700;
    align-items: center;
    margin: 0;
    padding-right: 1.5rem;
`
const CrossReferenceListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 40rem;
`
const CrossReferenceItemContainer = styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding-right: 1rem;
    border-radius: ${styles.borderRadius.large};
    background-color: ${p => p.theme.backgrounds.input};
`
const CrossReferenceItem = styled.div`
    font-family: ${styles.fonts.default};
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: .75rem .75rem .75rem 1.5rem;
    column-gap: 1rem;
    line-height: normal;
    cursor: pointer;
`
const RemoveIconContainer = styled.div`
    font-size: .6em;
    background-color: ${p => p.theme.backgrounds.input};
    border-radius: 50%;
    display: grid;
    padding: .15rem;
    cursor: pointer;
    place-items: center;
`
const StyledRemoveIcon = styled(RemoveIcon)`
    stroke: ${p => p.theme.colors.text};
`
const SearchInputContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    background-color: ${p => p.theme.backgrounds.input};
    border-radius: 5rem;
    padding: 0 1.55rem;
    padding-left: 2rem;
`
const SearchInput = styled.input`
    border: none;
    outline: none;
    background-color: transparent;
    padding: 1rem;
    height: 100%;
    width: 100%;
`
const StyledSearchIcon = styled(SearchIcon)`
    stroke: ${p => p.theme.colors.textInput};
    font-size: .75em;
    margin-right: 1rem;
`
const SearchList = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    max-height: 30rem;
    overflow-y: auto;
    padding: 1rem;
    width: 100%;
    background-color: ${p => p.theme.backgrounds.white};
`
const SearchListItem = styled.div`
    padding: 1rem;

    &:hover {
        background-color: ${p => p.theme.backgrounds.input};
    }
`
const CrossReferenceExcerpt = styled.q`
    font-style: italic;
    font-size: ${styles.fontSizes.smaller};
`

const maxVerseExcerptLength = 100

type Props = {
    closeMenu: () => void
    reference: IReference
}

const CrossReferenceMenu = (props: Props) => {
    const { reference, closeMenu } = props

    const { createCrossReference, deleteCrossReference } = useCrossReferenceEntity()

    const defaultBibleVersion = ProfileStore.useState(state => state.profile!.bibleVersion)

    const [searchText, setSearchText] = useState<string>('')
    const [searchResults, setSearchResults] = useState<ISearchReferenceResult[]>([])

    const { docs: crossReferences } = useFind<ICrossReferenceEntity>({
        index: {fields: [
            'type',
            'reference.bookId',
            'reference.chapterNr',
            'reference.subset.startVerse',
            'reference.subset.endVerse',
            'reference.subset.startOffset',
            'reference.subset.endOffset'
        ]},
        selector: {
            type: EntityEnum.CrossReference,
            'reference.bookId': reference.bookId,
            'reference.chapterNr': reference.chapterNr,
            'reference.subset.startVerse': reference.subset?.startVerse,
            'reference.subset.endVerse': reference.subset?.endVerse,
            'reference.subset.startOffset': reference.subset?.startOffset,
            'reference.subset.endOffset': reference.subset?.endOffset
        }
    })

    const onCrossReferenceItemClick = (crossReference: ICrossReferenceEntity) => {
        SearchStore.update(state => {
            if (!state.references.some(r => r.id === crossReference._id)) {
                state.references.push({
                    id: crossReference._id,
                    orderId: new Date().getTime(),
                    labelType: SearchLabelEnum.Reference,
                    comparisonCard: ComparisonCardEnum.Default,
                    reference: crossReference.targetReference
                })
            }
        })
    }

    const onCrossReferenceRemoveClick = (event: React.MouseEvent, crossReference: ICrossReferenceEntity) => {
        event.stopPropagation()
        deleteCrossReference(crossReference._id)
    }

    const onSearchItemClick = (targetReference: IReference) => {
        createCrossReference(reference, targetReference)
        setSearchText('')
        setSearchResults([])
    }

    const onSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value
        setSearchText(text)
        setSearchResults(BibleService.searchCrossReference(defaultBibleVersion, text))
    }

    const onSearchFocus = (event: React.FocusEvent) => {
        event.stopPropagation()
        event.preventDefault()
    }
    
    return (
        <Container>
            <CrossReferenceMenuTitle>
                {`${BibleService.convertReferenceToString(reference)} Cross References`}
            </CrossReferenceMenuTitle>
            <SearchInputContainer>
                <StyledSearchIcon />
                <SearchInput
                    value={searchText}
                    placeholder='Search Passage'
                    // autoFocus
                    onFocus={onSearchFocus}
                    onInput={onSearchInput}
                    onChange={onSearchInput}
                />
                {
                    (searchResults.length > 0 || searchText !== '') &&
                    <SearchList>
                        {
                            searchResults.map(result =>
                                <SearchListItem
                                    key={`cross-reference-search-${result.text}`}
                                    onClick={() => onSearchItemClick(result.reference)}
                                >
                                    {result.text}
                                </SearchListItem>)
                        }
                    </SearchList>
                }
            </SearchInputContainer>
            <CrossReferenceListContainer>
                {
                    crossReferences!.map((cr, index) => {
                        const referenceTitle = BibleService.convertReferenceToString(cr.targetReference)
                        let referenceText = BibleService.getTextFromReference(cr.targetReference).join(' ')
                        if (referenceText.length > maxVerseExcerptLength) {
                            referenceText = `${referenceText.slice(0, maxVerseExcerptLength).trim()}...`
                        }

                        return (
                        //TODO maybe key is wrong?
                            <CrossReferenceItemContainer key={`verse-cross-reference-${index}`}>
                                <CrossReferenceItem onClick={() => onCrossReferenceItemClick(cr)} title={referenceText}>
                                    {`${referenceTitle} - `}
                                    <CrossReferenceExcerpt>
                                        {referenceText}
                                    </CrossReferenceExcerpt>
                                </CrossReferenceItem>
                                <RemoveIconContainer onClick={event => onCrossReferenceRemoveClick(event, cr)}>
                                    <StyledRemoveIcon />
                                </RemoveIconContainer>
                            </CrossReferenceItemContainer>)
                    })
                }
            </CrossReferenceListContainer>
        </Container>
    )
}

export default CrossReferenceMenu