import { Icon } from 'components/common/icon'
import React, { HTMLAttributes } from 'react'

const RemoveCircleIcon = (props: HTMLAttributes<SVGElement>) => (
    <Icon {...props} viewBox='0 0 24 24'>
        <circle cx='12' cy='12' r='10' />
        <line x1='15' y1='9' x2='9' y2='15' />
        <line x1='9' y1='9' x2='15' y2='15' />
    </Icon>
)

export default RemoveCircleIcon