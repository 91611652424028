import { Icon } from 'components/common/icon'
import React, { HTMLAttributes } from 'react'

const BoldIcon = (props: HTMLAttributes<SVGElement>) => (
    <Icon {...props} viewBox='0 0 24 24'>
        <path d='M6 4h8a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z' />
        <path d='M6 12h9a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z' />
    </Icon>
)

export default BoldIcon