import React, { RefObject, useEffect } from 'react'

const useClickOutside = (callback: () => void, ignoreRef?: RefObject<HTMLElement>) => {
    useEffect(() => {
        if (ignoreRef) {
            const checkIfClickedOutside = (event: MouseEvent) => {
                if (ignoreRef.current && !ignoreRef.current.contains(event.target as Element)) {
                    callback()
                }
            }

            document.addEventListener('mouseup', checkIfClickedOutside)

            return () => {
                document.removeEventListener('mouseup', checkIfClickedOutside)
            }
        }
    }, [callback, ignoreRef])
}

export default useClickOutside