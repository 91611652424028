import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Dashboard from 'views/dashboard/dashboard'
import Entries from 'views/entries/entries'
import Layout from 'views/layout/layout'
import Login from 'views/login/login'
import Overview from 'views/overview/overview'
import Profile from 'views/profile/profile'
import StudyGroups from 'views/study-groups/studiy-groups'

const AppRouter = () => {
    return (
        <Routes>
            <Route path='login' element={<Login />} />
            <Route path='bible' element={<Layout />}>
                <Route path='dashboard' element={<Dashboard />} />
                <Route path='overview' element={<Overview />} />
                <Route path='entries' element={<Entries />} />
                <Route path='study-groups' element={<StudyGroups />} />
                <Route path='profile' element={<Profile />} />
            </Route>
            <Route path='*' element={<Navigate to='bible/dashboard' />} />
            {/* <Route path='*' element={<Navigate to='login' />} /> */}
        </Routes>
    )
}

export default AppRouter