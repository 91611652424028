import { ComparisonModeEnum } from 'enums/ui'
import { registerInDevtools, Store } from 'pullstate'
import { DefaultTheme } from 'styled-components'
import { darkTheme, defaultTheme } from 'styles/themes'
import { IToastMessage } from 'types/ui'

interface IUIStore {
    theme: DefaultTheme
    hideTags: boolean
    hideEntries: boolean
    hideSidebar: boolean
    fontSerifs: boolean
    comparisonMode: ComparisonModeEnum
    modal?: JSX.Element
    alert?: JSX.Element
    toastMessages: IToastMessage[]
}

export const UIStore = new Store<IUIStore>({
    theme: defaultTheme,
    hideTags: false,
    hideEntries: false,
    fontSerifs: false,
    hideSidebar: false,
    comparisonMode: ComparisonModeEnum.Default,
    toastMessages: []
})

registerInDevtools({UIStore})