import styled from 'styled-components'

const iconSize = 1.5

export const Icon = styled.svg`
    fill: none;
    stroke-width: .15rem;
    width: ${iconSize}em;
    height: ${iconSize}em;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: ${p => p.theme.colors.text};
    transition: stroke-width .2s ease;
`