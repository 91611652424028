import { Icon } from 'components/common/icon'
import React from 'react'

const HashIcon = () => (
    <Icon viewBox='0 0 24 24'>
        <line x1='4' y1='9' x2='20' y2='9' />
        <line x1='4' y1='15' x2='20' y2='15' />
        <line x1='10' y1='3' x2='8' y2='21' />
        <line x1='16' y1='3' x2='14' y2='21' />
    </Icon>
)

export default HashIcon