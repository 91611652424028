class Stopwatch {
    constructor(title: string) {
        this.title = title
        this.startTime = performance.now()
    }

    private startTime: number
    private title: string

    public stop = () : number => {
        const time = performance.now() - this.startTime
        console.log(`${this.title} ${time.toFixed(2)} ms`)
        return time
    }

    public restart = () => {
        this.startTime = performance.now()
    }

    public static startNew = (title: string) : Stopwatch => {
        return new Stopwatch(title)
    }
}

export default Stopwatch