import { Icon } from 'components/common/icon'
import React, { HTMLAttributes } from 'react'

const DragListIcon = (props: HTMLAttributes<SVGElement>) => (
    <Icon {...props} viewBox='0 0 24 24'>
        <line x1='3' y1='12' x2='21' y2='12' />
        <line x1='3' y1='6' x2='21' y2='6' />
        <line x1='3' y1='18' x2='21' y2='18' />
    </Icon>
)

export default DragListIcon

