import React from 'react'

const Entries = () => {
    return (
        <>
            {'Browse all Entries'}
            {'Create Entry that may not be linked to scripture like a study on certain topics such as "The Temple Veil"'}
            {'Sort, filter through all types of entries, search by ocurring tags'}
        </>
    )
}

export default Entries