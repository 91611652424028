import { useTagEntity } from 'hooks/pouchdb/useTagEntity'
import EditTagModal from 'modals/edit-tag-modal'
import React, { useState } from 'react'
import { UIStore } from 'stores/ui-store'
import styled from 'styled-components'
import { ITagEntity } from 'types/entity'
import { IColor } from 'types/ui'
import ColorMenu from 'views/overview/color-menu'
import ColorIcon from './icons/color-icon'
import EditIcon from './icons/edit-icon'
import StarIcon from './icons/star-icon'
import TrashIcon from './icons/trash-icon'

const Container = styled.div`
    display: flex;
`
const MenuItem = styled.div`
    position: relative;
    padding: .5em 1rem;
    cursor: pointer;

    &:hover {
        font-weight: 600;
    }
`

type Props = {
    tag: ITagEntity
    closeMenu: () => void
}

const TagMenu = (props: Props) => {
    const { tag, closeMenu } = props

    const { deleteTag, setFavorite, setColor } = useTagEntity()
    
    const [showColorMenu, setShowColorMenu] = useState<boolean>(false)

    const openEditTagModal = () => {
        closeMenu()
        UIStore.update(state => {
            state.modal = <EditTagModal tag={tag} />
        })
    }

    const onToggleFavorite = (tag: ITagEntity) => {
        setFavorite(tag, !tag.favorite)
        closeMenu()
    }

    const setTagColor = (color: IColor) => {
        setColor(tag, color)
    }

    return (
        <Container>
            <MenuItem onClick={openEditTagModal}>
                <EditIcon />
            </MenuItem>
            <MenuItem onClick={() => onToggleFavorite(tag)}>
                <StarIcon style={{fill: tag.favorite ? 'orange' : 'none'}} />
            </MenuItem>
            <MenuItem>
                <ColorIcon style={{fill: tag.color.primary}} onClick={() => setShowColorMenu(true)} />
                {
                    showColorMenu &&
                    <ColorMenu
                        currentColor={tag.color}
                        setColor={setTagColor}
                        closeMenu={() => setShowColorMenu(false)}
                    />
                }
            </MenuItem>
            <MenuItem onClick={() => deleteTag(tag!._id)}>
                <TrashIcon />
            </MenuItem>
        </Container>
    )
}

export default TagMenu