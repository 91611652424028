import { Icon } from 'components/common/icon'
import React, { HTMLAttributes } from 'react'

const AddCircleIcon = (props: HTMLAttributes<SVGElement>) => (
    <Icon {...props} viewBox='0 0 24 24'>
        <circle cx='12' cy='12' r='10' />
        <line x1='12' y1='8' x2='12' y2='16' />
        <line x1='8' y1='12' x2='16' y2='12' />
    </Icon>
)

export default AddCircleIcon