import { Icon } from 'components/common/icon'
import React, { HTMLAttributes } from 'react'

const TagOffIcon = (props: HTMLAttributes<SVGElement>) => (
    <Icon {...props} viewBox='0 0 24 24'>
        <path d='M 14.22,4.22 C 14.22,4.22 12.01,2.00 12.01,2.00 12.01,2.00 2.00,2.00 2.00,2.00 2.00,2.00 2.00,12.00 2.00,12.00 2.00,12.00 4.04,14.03 4.04,14.03' />
        <path d='M 17.01,6.99 C 17.01,6.99 20.59,10.59 20.59,10.59 20.95,10.95 21.17,11.45 21.17,12.00 21.17,12.55 20.95,13.05 20.59,13.41 20.59,13.41 20.59,13.41 20.59,13.41 20.59,13.41 13.42,20.58 13.42,20.58 13.06,20.94 12.56,21.17 12.00,21.17 11.45,21.17 10.95,20.94 10.59,20.58 10.59,20.58 6.98,17.01 6.98,17.01' />
        <line x1='7' y1='7' x2='7.01' y2='7' />
        <line x1='23' y1='1' x2='1' y2='23' />
    </Icon>
)

export default TagOffIcon