import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import { ITagEntity } from 'types/entity'
import useClickOutside from 'hooks/useClickOutside'
import StarIcon from 'components/icons/star-icon'
import { IReference } from 'types/verse'
import TagMenu from './tag-menu'

const Container = styled.div<{color: string, bgColor: string}>`
    position: relative;
    display: inline-flex;
    align-items: center;
    column-gap: 1rem;
    font-family: ${styles.fonts.bold};
    font-weight: 700;
    font-size: ${styles.fontSizes.smaller};
    padding: .75rem 1.5rem .75rem 1.5rem;
    border-radius: 25rem;
    letter-spacing: .05rem;
    line-height: 1;
    color: ${p => p.color};
    background-color: ${p => p.bgColor};
    cursor: pointer;
    white-space: nowrap;
    user-select: none;
`
const MenuContainer = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 2rem);
    background: white;
    border-radius: 1rem;
    box-shadow: 0 0 1rem 0 rgba(0,0,0,.1);
    z-index: ${styles.zIndex.dropdown};
    padding: 1rem 1rem;
    transition: opacity .2s ease;

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -2rem;
        width: 0;
        height: 0;
        display: block;
        border-left: 1rem solid transparent;
        border-right: 1rem solid white;
        border-bottom: 1rem solid transparent;
        border-top: 1rem solid transparent;
    }
`

const StyledStarIcon = styled(StarIcon)`
    font-size: .5em;
    stroke-width: 1;
`

type Props = {
    tag: ITagEntity
    reference?: IReference
    tagMenu?: boolean
}

const BibleTag = (props: Props) => {
    const { tag, reference, tagMenu } = props

    const ignoreRef = useRef<HTMLDivElement>(null)
    const [showMenu, setShowMenu] = useState<boolean>()

    useClickOutside(() => setShowMenu(false), ignoreRef)

    const onTagClick = () => {
        if (!tagMenu) return
        setShowMenu(true)
    }

    return (
        <Container
            color={tag.color.primary}
            bgColor={tag.color.secondary}
            onClickCapture={onTagClick}
        >
            {
                tag.favorite &&
                <StyledStarIcon style={{fill: 'orange'}} />
            }
            
            {tag.text}

            {
                tagMenu && showMenu &&
                <MenuContainer ref={ignoreRef}>
                    <TagMenu tag={tag} closeMenu={() => setShowMenu(false)} />
                </MenuContainer>
            }
        </Container>
    )
}

export default BibleTag