class Helper {
    public static randomInt = (min: number, max: number) =>
        Math.floor(Math.random() * (max - min + 1) + min)

    public static randomEnum = <T>(anEnum: T): T[keyof T] => {
        const enumValues = Object.keys(anEnum)
            .map(n => Number.parseInt(n))
            .filter(n => !Number.isNaN(n)) as unknown as T[keyof T][]

        const randomIndex = Helper.randomInt(0, enumValues.length - 1)
        const randomEnumValue = enumValues[randomIndex]
        return randomEnumValue
    }

    static shuffleArray = <T>(array: T[]) : void => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]]
        }
    }

    static clampNumber = (val: number, maxVal: number, minVal: number) : number => Math.min(Math.max(val, minVal), maxVal)

    static normalize = (val: number, max: number, min: number) => (val - min) / (max - min)
    
    static denormalize = (val: number, max: number, min: number) => val * (max - min) + min

    static humanizeDate = (date: number) : string => {
        const MINUTE = 60, HOUR = MINUTE * 60, DAY = HOUR * 24, YEAR = DAY * 365
    
        const secondsAgo = Math.round((+new Date() - date) / 1000)
      
        if (secondsAgo < MINUTE) {
            return 'now'
        } else if (secondsAgo < HOUR) {
            return Math.floor(secondsAgo / MINUTE) + 'm'
        } else if (secondsAgo < DAY) {
            return Math.floor(secondsAgo / HOUR) + 'h'
        } else if (secondsAgo < YEAR) {
            return new Intl.DateTimeFormat('en-US', {day: 'numeric', month: 'short'}).format(date)
        } else {
            return new Intl.DateTimeFormat('en-US', {year: 'numeric', month: 'short'}).format(date)
        }
    }

    static randomUid = () : string => {
        function getRandomSymbol (symbol: string) {
            if (symbol === 'y') {
                const array = ['8', '9', 'a', 'b']
                return array[Math.floor(Math.random() * array.length)]
            }
    
            const array = new Uint8Array(1)
            window.crypto.getRandomValues(array)

            return (array[0] % 16).toString(16)
        }
        
        const uidRegExp = new RegExp('[xy]', 'g')

        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(uidRegExp, getRandomSymbol)
    }
}

export default Helper