import CrossReferenceMenu from 'components/cross-reference-menu'
import ColorIcon from 'components/icons/color-icon'
import CopyIcon from 'components/icons/copy-icon'
import CrossReferenceIcon from 'components/icons/cross-reference-icon'
import CrossHairIcon from 'components/icons/crosshair-icon'
import NoteIcon from 'components/icons/note-icon'
import TagIcon from 'components/icons/tag-icon'
import { EntryIconEnum } from 'enums/icon'
import { ComparisonCardEnum, SearchLabelEnum, SelectionMenuEnum, ToastMessageEnum } from 'enums/ui'
import Helper from 'helpers/helper'
import { useHighlightEntity } from 'hooks/pouchdb/useHighlightEntity'
import React, { useState } from 'react'
import { EntryStore } from 'stores/entry-store'
import { SearchStore } from 'stores/search-store'
import { UIStore } from 'stores/ui-store'
import { VerseStore } from 'stores/verse-store'
import styled from 'styled-components'
import { predefinedColors } from 'styles/colors'
import { IColor } from 'types/ui'
import { ISelection } from 'types/verse'
import ColorMenu from './color-menu'

const Container = styled.div`
    position: absolute;
    z-index: 4;
    display: flex;
    background-color: white;
    box-shadow: .5rem .5rem 1rem .5rem rgba(0,0,0,.15);
    border-radius: .5rem;
    pointer-events: all;
    padding: .5rem;
`
const ButtonWrapper = styled.div`
    position: relative;
`
const Button = styled.button`
    padding: .75rem;
    border: none;
    outline: none;
    background: ${p => p.theme.backgrounds.white};
    height: 100%;
    display: grid;
    place-items: center;
`
const StyledColorIcon = styled(ColorIcon)<{disabled?: boolean, color: string}>`
    fill: ${p => p.disabled ? 'none' : p.color};
    stroke: ${p => p.disabled ? 'lightgrey' : 'black'};
`
const StyledCrossReferenceIcon = styled(CrossReferenceIcon)<{disabled?: boolean}>`
    stroke: ${p => p.disabled ? 'lightgrey' : 'black'};
`
const StyledTermEntryIcon = styled(NoteIcon)<{disabled?: boolean}>`
    stroke: ${p => p.disabled ? 'lightgrey' : 'black'};
`
const StyledTagIcon = styled(TagIcon)<{color?: string}>`

`

type Props = {
    offset: {x: number, y: number}
    selection: ISelection
}

const SelectionMenu = (props: Props) => {
    const { offset, selection } = props

    const { createHighlight, deleteHighlights } = useHighlightEntity()

    const [currentVisibleMenu, setCurrentVisibleMenu] = useState<SelectionMenuEnum>(SelectionMenuEnum.None)
    const [highlightColor, setHighlightColor] = useState<IColor>(predefinedColors[0])

    const changeHighlightColor = (color: IColor) => {
        if (color === predefinedColors[0]) {
            deleteHighlights(selection.reference)
        } else {
            setHighlightColor(color)
            //check if the new selection intersects with a previous highlight
            createHighlight(selection.reference, color.secondary)
        }
    }

    const closeVerseMenu = () => {
        VerseStore.update(state => {
            state.visible = false
        })
    }

    const onIsolateClick = () => {
        SearchStore.update(state => {
            state.tags = []
            state.terms = []
            state.references = [{
                labelType: SearchLabelEnum.Reference,
                comparisonCard: ComparisonCardEnum.Default,
                orderId: 0,
                reference: selection.reference
            }]
        })

        closeVerseMenu()
    }

    const onCrossReferenceClick = (event: React.MouseEvent) => {
        if (disableVerseReference) return
        setCurrentVisibleMenu(prev => prev === SelectionMenuEnum.CrossReference ? SelectionMenuEnum.None : SelectionMenuEnum.CrossReference)
    }

    const onHighlightClick = (event: React.MouseEvent) => {
        setCurrentVisibleMenu(prev => prev === SelectionMenuEnum.Highlight ? SelectionMenuEnum.None : SelectionMenuEnum.Highlight)
    }

    const onCopySelectionClick = async () => {
        const text = selection.text

        await navigator.clipboard.writeText(text)

        UIStore.update(state => {
            state.toastMessages.push({
                id: Helper.randomUid(),
                text: 'Copied to Clipboard.',
                type: ToastMessageEnum.Success
            })
        })
    }

    const onTermEntryClick = () => {
        if (disableVerseReference) return

        const text = selection.text

        EntryStore.update(state => {
            state.temporaryEntry = {
                icon: EntryIconEnum.TermEntry,
                htmlContent: '',
                title: text,
                reference: selection.reference
            }
        })
    }

    const onTagClick = () => {
        //TODO
    }

    const disableVerseReference = selection.reference.subset?.startVerse !== selection.reference.subset?.endVerse || selection.hasCrossReference
    const disableHighlight = selection.hasHighlight

    return (
        <Container style={{transform: `translate(${offset.x + 20}px, ${offset.y + 20}px)`}}>
            <Button title='Copy' onClick={onCopySelectionClick}>
                <CopyIcon />
            </Button>

            <ButtonWrapper>
                <Button title='Highlight' onClick={onHighlightClick}>
                    <StyledColorIcon disabled={disableHighlight} color={highlightColor.primary} />
                </Button>
                {
                    currentVisibleMenu === SelectionMenuEnum.Highlight &&
                    <ColorMenu
                        currentColor={highlightColor}
                        showNoColorItem
                        setColor={changeHighlightColor}
                        closeMenu={() => setCurrentVisibleMenu(SelectionMenuEnum.None)}
                    />
                }
            </ButtonWrapper>

            <Button title='Term Entry' onClick={onTermEntryClick}>
                <StyledTermEntryIcon disabled={disableVerseReference} />
            </Button>

            <ButtonWrapper>
                <Button title='Cross Reference' onClick={onCrossReferenceClick}>
                    <StyledCrossReferenceIcon disabled={disableVerseReference} />
                </Button>
                {
                    currentVisibleMenu === SelectionMenuEnum.CrossReference &&
                    <CrossReferenceMenu
                        reference={selection.reference}
                        closeMenu={() => setCurrentVisibleMenu(SelectionMenuEnum.None)}
                    />
                }
            </ButtonWrapper>

            <ButtonWrapper>
                <Button title='Tag' onClick={onTagClick}>
                    <StyledTagIcon />
                </Button>
            </ButtonWrapper>

            <Button title='Isolate Verse(s)' onClick={onIsolateClick}>
                <CrossHairIcon />
            </Button>
        </Container>
    )
}

export default SelectionMenu