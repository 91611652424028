import { registerInDevtools, Store } from 'pullstate'
import { ISelection } from 'types/verse'

interface IVerseStore {
    visible: boolean
    offset: {x: number, y: number}
    selection?: ISelection
}

export const VerseStore = new Store<IVerseStore>({
    visible: false,
    offset: {x: 0, y: 0}
})

registerInDevtools({VerseStore})