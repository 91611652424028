import BiblePassageHeader from 'components/bible-passage-header'
import BibleVerse from 'components/bible-verse'
import { EntityEnum } from 'enums/entity'
import { ComparisonCardEnum, SearchLabelEnum } from 'enums/ui'
import React, { useState } from 'react'
import BibleService from 'services/bible-service'
import { EntryStore } from 'stores/entry-store'
import { SearchStore } from 'stores/search-store'
import { UIStore } from 'stores/ui-store'
import styled from 'styled-components'
import { ICrossReferenceEntity, IEntryEntity, IHighlightEntity, ITagEntity } from 'types/entity'
import { IPassaeTags, IPassage, ISubset, IVerseTag } from 'types/verse'
import { useFind } from 'use-pouchdb'
import BibleEntryVerse from 'views/overview/bible-entry-verse'
import TemporaryEntry from 'views/overview/temporary-entry'

const Wrapper = styled.div`
    content-visibility: auto;
    contain-intrinsic-size: 50rem;
    width: 100%;
    flex: 1 0 50%;
`
const GridContainer = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 4rem 5rem minmax(25%, calc(15vw + 30%)) 5rem auto;
    grid-auto-flow: row dense;
    grid-auto-rows: auto;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
`
const EntryContainer = styled.div`
    position: absolute;
    grid-column: 5;
    width: 50rem;
    margin-right: 1rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
`

type Props = {
    passage: IPassage
    defaultCollapsed?: boolean
}

const BiblePassage = (props: Props) => {
    const { passage, defaultCollapsed } = props

    const { hideTags, hideEntries } = UIStore.useState(state => state)
    const { terms } = SearchStore.useState(state => state)
    const temporaryEntry = EntryStore.useState(state => {
        if (!state.temporaryEntry) return undefined

        const tempRef = state.temporaryEntry.reference
        if (tempRef.version === passage.version &&
            tempRef.bookId === passage.bookId &&
            tempRef.chapterNr === passage.chapter.num &&
            (!passage.subset ||
                (tempRef.subset!.startVerse >= passage.subset.startVerse &&
                tempRef.subset!.endVerse <= passage.subset.endVerse)
            )) {
            return state.temporaryEntry
        }

        return undefined
    })

    const [passageSearchTerm, setPassageSearchTerm] = useState<string>('')
    const [collapsed, setCollaped] = useState<boolean>(!!defaultCollapsed)
    const [selectedVerse, setSelectedVerse] = useState<number>()

    const passageSubset: ISubset = passage.subset ? passage.subset : {
        startVerse: 1,
        endVerse: passage.chapter.verses.length
    }
    const passageVerses = passage.chapter.verses.slice(passageSubset.startVerse - 1, passageSubset.endVerse)

    const { docs: tags, loading, error } = useFind<ITagEntity>({
        index: {fields: ['type', 'references']},
        selector: {
            type: EntityEnum.Tag,
            references: {
                $elemMatch: {
                    bookId: {$eq: passage.bookId},
                    chapterNr: {$eq: passage.chapter.num},
                }
            }
        }
    })

    const { docs: entries } = useFind<IEntryEntity>({
        index: {fields: ['type', 'reference.bookId', 'reference.chapterNr']},
        selector: {
            type: EntityEnum.Entry,
            'reference.bookId': passage.bookId,
            'reference.chapterNr': passage.chapter.num
        }
    })

    const { docs: crossReferences } = useFind<ICrossReferenceEntity>({
        index: {fields: ['type', 'reference.bookId', 'reference.chapterNr']},
        selector: {
            type: EntityEnum.CrossReference,
            'reference.bookId': passage.bookId,
            'reference.chapterNr': passage.chapter.num
        }
    })

    const { docs: highlights } = useFind<IHighlightEntity>({
        index: {fields: ['type', 'reference.bookId', 'reference.chapterNr']},
        selector: {
            type: EntityEnum.Highlight,
            'reference.bookId': passage.bookId,
            'reference.chapterNr': passage.chapter.num
        }
    })

    const getPassageTags = () : IPassaeTags => {
        const verseTags: {[key: number]: IVerseTag[]} = {}
        const chapterTags: ITagEntity[] = []

        if (hideTags) {
            return {
                verseTags,
                chapterTags
            }
        }

        const tagSortComparer = (a: ITagEntity, b: ITagEntity) =>
            a.text.localeCompare(b.text)

        for (const tag of tags) {
            const tagReferences = tag.references.filter(r => r.bookId === passage.bookId)

            if (tagReferences.some(r => !!r.subset)) {
                chapterTags.push(tag)
            }

            for (const reference of tagReferences) {
                if (reference.subset) {
                    const startVerse = reference.subset.startVerse

                    const intersectingTags = Object.values(verseTags)
                        .flatMap(v => v)
                        .filter(v => v.reference.subset!.startVerse <= startVerse && v.reference.subset!.endVerse >= startVerse)

                    let lowestAvailableLevel = intersectingTags.reduce((acc: number | undefined, curr) => {
                        if (!acc || curr.level > acc) return curr.level
                        return acc
                    }, undefined)

                    if (lowestAvailableLevel !== undefined) {
                        lowestAvailableLevel++
                    } else {
                        lowestAvailableLevel = 0
                    }
                    
                    if (verseTags[startVerse]) {
                        verseTags[startVerse].push({
                            tag,
                            reference,
                            level: lowestAvailableLevel
                        })
                    } else {
                        verseTags[startVerse] = [{
                            tag,
                            reference,
                            level: lowestAvailableLevel
                        }]
                    }
                }
            }
        }

        return {
            verseTags,
            chapterTags
        }
    }

    const getPassageCrossReferences = () : {[key: number]: ICrossReferenceEntity[]} => {
        const passageCrossReferences: {[key: number]: ICrossReferenceEntity[]} = {}

        for (const cr of crossReferences) {
            if (passageCrossReferences[cr.reference.subset!.startVerse]) {
                passageCrossReferences[cr.reference.subset!.startVerse].push(cr)
            } else {
                passageCrossReferences[cr.reference.subset!.startVerse] = [cr]
            }
        }

        return passageCrossReferences
    }

    const getPassageHighlights = () : {[key: number]: IHighlightEntity[]} => {
        const passageHighlights: {[key: number]: IHighlightEntity[]} = {}

        for (const highlight of highlights) {
            const startVerse = highlight.reference.subset!.startVerse
            const endVerse = highlight.reference.subset!.endVerse

            for (let i = startVerse; i <= endVerse; i++) {
                if (passageHighlights[i]) {
                    passageHighlights[i].push(highlight)
                } else {
                    passageHighlights[i] = [highlight]
                }
            }
        }

        return passageHighlights
    }

    const getPassageEntries = () : {[key: number]: IEntryEntity[]} => {
        const verseEntries: {[key: number]: IEntryEntity[]} = {}

        if (hideEntries) {
            return verseEntries
        }

        for (const entry of entries) {
            if (verseEntries[entry.reference!.subset!.startVerse]) {
                verseEntries[entry.reference!.subset!.startVerse].push(entry)
            } else {
                verseEntries[entry.reference!.subset!.startVerse] = [entry]
            }
        }

        return verseEntries
    }

    const passageTags = getPassageTags()
    const passageEntries = getPassageEntries()
    const passageCrossReferences = getPassageCrossReferences()
    const passageHightlights = getPassageHighlights()

    const passageSearchTerms = [...terms]
    if (passageSearchTerm !== '') {
        passageSearchTerms.push({
            orderId: 0,
            count: 0,
            labelType: SearchLabelEnum.Term,
            comparisonCard: ComparisonCardEnum.Default,
            references: [],
            text: passageSearchTerm
        })
    }

    const sortedEntries = entries.sort((a, b) => BibleService.referenceSortComparer(a.reference!, b.reference!))

    return (
        <Wrapper>
            <BiblePassageHeader
                passage={passage}
                chapterTags={passageTags.chapterTags}
                collapsed={collapsed}
                setCollapsed={setCollaped}
                passageSearchTerm={passageSearchTerm}
                setPassageSearchTerm={setPassageSearchTerm}
            />
            <GridContainer>
                {
                    !collapsed &&
                    passageVerses.map((verse, index) =>
                        <BibleVerse
                            key={`passage-${index}`}
                            verseTags={passageTags.verseTags[verse.num]}
                            version={passage.version}
                            bookId={passage.bookId}
                            chapterNr={passage.chapter.num}
                            verse={verse}
                            selected={selectedVerse === verse.num}
                            crossReferences={passageCrossReferences[verse.num]}
                            highlights={passageHightlights[verse.num]}
                            entries={passageEntries[verse.num]}
                            searchTerms={passageSearchTerms}
                            setSelected={setSelectedVerse}
                        />)
                }
                <EntryContainer>
                    {
                        !!temporaryEntry &&
                        <TemporaryEntry entry={temporaryEntry} />
                    }
                    {
                        !hideEntries &&
                        sortedEntries.map(entry => <BibleEntryVerse key={`entry-${entry._id}`} entry={entry} />)
                    }
                </EntryContainer>
            </GridContainer>
        </Wrapper>
    )
}

export default BiblePassage