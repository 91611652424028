import { DefaultTheme } from 'styled-components'

export const defaultTheme: DefaultTheme = {
    backgrounds: {
        white: 'white',
        content: '#f5f6fd',
        input: '#f5f6fd',
        inputBright: '#e3e5f7',
        inputDark: '#c7cadf'
    },
    colors: {
        primary: '#630c50',
        primaryDark: '',
        primaryBright: '#89497d',
        secondary: '#c99c4b',
        secondaryDark: '',
        secondaryBright: '#d7b47a',
        text: '#1D2124',
        textInput: '#7e8091'
    },
    shadows: {
        close: `0.9px 0.9px 4.4px rgba(0, 0, 0, 0.05),
                2px 2px 6.7px rgba(0, 0, 0, 0.034),
                5px 5px 10px rgba(0, 0, 0, 0.022)`,
        medium: `0.7px 0.7px 8.8px rgba(0, 0, 0, 0.005),
                2.2px 2.2px 11.5px rgba(0, 0, 0, 0.008),
                10px 10px 20px rgba(0, 0, 0, 0.02)`,
        far: `4.6px 4.6px 17px rgba(0, 0, 0, 0.02),
                10.9px 10.9px 23.9px rgba(0, 0, 0, 0.03),
                20px 20px 30px rgba(0, 0, 0, 0.05)`
    },
    letterSpacing: {
        small: '-0.0125em',
        medium: '0.0125em'
    }
}

export const darkTheme: DefaultTheme = {
    backgrounds: {
        white: '',
        content: '',
        input: '',
        inputBright: '',
        inputDark: ''
    },
    colors: {
        primary: '',
        primaryDark: '',
        primaryBright: '',
        secondary: '',
        secondaryDark: '',
        secondaryBright: '',
        text: '',
        textInput: ''
    },
    shadows: {
        close: '',
        medium: '',
        far: ''
    },
    letterSpacing: {
        small: '',
        medium: ''
    }
}