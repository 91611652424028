import React from 'react'
import { VerseStore } from 'stores/verse-store'
import styled from 'styled-components'
import SelectionMenu from 'views/overview/selection-menu'

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
`

const SelectionLayer = () => {
    const { selection, visible, offset } = VerseStore.useState(state => state)

    return (
        <Container>
            {
                visible && selection &&
                <SelectionMenu selection={selection} offset={offset} />
            }
        </Container>
    )
}

export default SelectionLayer