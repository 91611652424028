import BoldIcon from 'components/icons/bold-icon'
import CalendarIcon from 'components/icons/calendar-icon'
import CancelIcon from 'components/icons/cancel-icon'
import CheckCircleIcon from 'components/icons/check-circle-icon'
import CrossReferenceIcon from 'components/icons/cross-reference-icon'
import EntryIconIndex from 'components/icons/entry-icon-index'
import ItalicIcon from 'components/icons/italic-icon'
import LinkIcon from 'components/icons/link-icon'
import MoreDotsIcon from 'components/icons/more-dots-icon'
import UnderlineIcon from 'components/icons/underline-icon'
import UserIcon from 'components/icons/user-icon'
import { EntryIconEnum } from 'enums/icon'
import { ToastMessageEnum } from 'enums/ui'
import Helper from 'helpers/helper'
import { useEntryEntity } from 'hooks/pouchdb/useEntryEntity'
import React, { useState } from 'react'
import BibleService from 'services/bible-service'
import EntryService from 'services/entry-service'
import { EntryStore } from 'stores/entry-store'
import { ProfileStore } from 'stores/profile-store'
import { UIStore } from 'stores/ui-store'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import { IEntryValid, ITemporaryEntry } from 'types/entry'

const StyledMoreDotsIcon = styled(MoreDotsIcon)<{forceVisible?: boolean}>`
    font-size: .75em;
    margin-left: auto;
    opacity: ${p => p.forceVisible ? 1 : 0};
`
const Container = styled.div<{borderColor: string}>`
    border-left: .5rem solid ${p => p.borderColor};
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: #1f1f1f;
    padding: 1rem 1rem 0 2rem;
    border-radius: ${styles.borderRadius.default};
    width: 50rem;
    box-shadow: .1rem .1rem .25rem rgba(0,0,0,.2);

    transition: height .2s ease;

    &:hover ${StyledMoreDotsIcon} {
        opacity: 1;
    }
`
const StyledIcon = styled(EntryIconIndex)`
    font-size: .85em;
    flex-shrink: 0;
`
const Header = styled.span`
    display: flex;
    align-items: center;
    column-gap: 1rem;
    font-family: ${styles.fonts.bold};
    font-weight: 700;
    font-size: ${styles.fontSizes.default};
    margin-bottom: 1rem;
    white-space: nowrap;
`
const Content = styled.p`
    overflow-y: visible;
    overflow-x: hidden;
    flex-grow: 1;
    padding-right: 2rem;
    outline: none;
    border: none;
    margin: 0;
    line-height: 1.25;
    margin-bottom: 1rem;
`
const ToolbarContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 2rem;
    padding-top: 1rem;
    font-size: .75em;
    border-top: 1px solid lightgrey;
    margin-bottom: 1rem;
`
const StyledBoldIcon = styled(BoldIcon)`
    stroke-width: 3;
    cursor: pointer;
`
const StyledItalicIcon = styled(ItalicIcon)`
    cursor: pointer;
`
const StyledUnderlineIcon = styled(UnderlineIcon)`
    cursor: pointer;
`
const StyledCheckIcon = styled(CheckCircleIcon)`
    margin-left: auto;
    font-size: 1.25em;
    stroke: green;
    stroke-width: 2;
    cursor: pointer;
`
const MetaDataContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: ${styles.fontSizes.small};
    font-weight: 400;
    column-gap: 2rem;
    margin-bottom: 1rem;
`
const MetaText = styled.span`
    color: #a2a2a2;
    display: flex;
    align-items: center;
    column-gap: .5rem;
`
const StyledCalendarIcon = styled(CalendarIcon)`
    stroke: #a2a2a2;
    font-size: .75em;
`
const StyledUserIcon = styled(UserIcon)`
    stroke: #a2a2a2;
    font-size: .85em;
`
const StyledCancelIcon = styled(CancelIcon)`
    stroke: red;
    font-size: 1.25em;
    stroke-width: 2;
    cursor: pointer;
    margin-left: auto;
`
const TitleInput = styled.input`
    font-family: ${styles.fonts.bold};
    font-weight: 700;
    font-size: ${styles.fontSizes.default};
    border: none;
    outline: none;
    width: 100%;
`
const Title = styled.span`
    font-family: ${styles.fonts.bold};
    font-weight: 700;
    font-size: ${styles.fontSizes.default};
    border: none;
    outline: none;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`

type Props = {
    entry: ITemporaryEntry
}

const TemporaryEntry = (props: Props) => {
    const { entry } = props

    const { createEntry } = useEntryEntity()

    const [icon, setIcon] = useState<EntryIconEnum>(entry.icon)
    const [title, setTitle] = useState<string>(entry.title)
    const [content, setContent] = useState<string>(entry.htmlContent)
    const [valid, setValid] = useState<IEntryValid>({
        title: true,
        content: false
    })
    const profile = ProfileStore.useState(state => state.profile)!

    const isTermEntry = entry.icon === EntryIconEnum.TermEntry
    //disable edit icon or title

    const onEntryClick = () => {
        EntryStore.update(state => {
            state.selectedEntry = undefined
        })
    }

    const onBoldClick = () => {
        document.execCommand('bold', false)
    }

    const onItalicClick = () => {
        document.execCommand('italic', false)
    }

    const onUnderlineClick = () => {
        document.execCommand('underline', false)
    }

    const onFinishClick = () => {
        if (valid.title && valid.content) {
            createEntry(title, content, icon, profile.username, entry.reference, [])

            UIStore.update(state => {
                state.toastMessages.push({
                    id: Helper.randomUid(),
                    text: 'Entry created successfully',
                    type: ToastMessageEnum.Success
                })
            })
        } else {
            UIStore.update(state => {
                state.toastMessages.push({
                    id: Helper.randomUid(),
                    text: 'Entry discarded',
                    type: ToastMessageEnum.Info
                })
            })
        }

        EntryStore.update(state => {
            state.selectedEntry = undefined
            state.temporaryEntry = undefined
        })
    }

    const onContentChange = (event: React.ChangeEvent<HTMLParagraphElement>) => {
        const nativeEvent = event.nativeEvent as any

        if (nativeEvent.inputType === 'formatBold' ||
            nativeEvent.inputType === 'formatItalic' ||
            nativeEvent.inputType === 'formatUnderline')
            return

        const html = event.target.innerHTML
        setValid(prev => ({
            ...prev,
            content: html.length > 0
        }))

        setContent(html)
    }

    const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const title = event.target.value

        setValid(prev => ({
            ...prev,
            title: EntryService.validateEntryTitle(title)
        }))

        setTitle(title)
    }

    const reference = BibleService.convertReferenceToString(entry.reference)

    const humanizedDate = Helper.humanizeDate(new Date().getTime())

    return (
        <Container borderColor={isTermEntry ? 'maroon' : '#ffd390'} onClick={onEntryClick}>
            <MetaDataContainer>
                <MetaText>
                    {reference}
                </MetaText>
                <MetaText>
                    <StyledUserIcon />
                    {profile.username}
                </MetaText>
                <MetaText>
                    <StyledCalendarIcon />
                    {humanizedDate}
                </MetaText>
            </MetaDataContainer>
            <Header>
                <StyledIcon icon={icon} />
                {
                    isTermEntry ?
                        <Title>{title}</Title> :
                        <TitleInput value={title} onInput={onTitleChange} onChange={onTitleChange} />
                }
                
            </Header>
            <Content
                ref={el => el?.focus()}
                onInput={onContentChange}
                onChange={onContentChange}
                contentEditable
                dangerouslySetInnerHTML={{__html: entry.htmlContent}}
            />
            <ToolbarContainer>
                <StyledBoldIcon onClick={onBoldClick} />
                <StyledItalicIcon onClick={onItalicClick} />
                <StyledUnderlineIcon onClick={onUnderlineClick} />
                <LinkIcon />
                <CrossReferenceIcon />
                
                {
                    (valid.content && valid.title) ?
                        <StyledCheckIcon onClick={onFinishClick} /> :
                        <StyledCancelIcon onClick={onFinishClick} />
                }

            </ToolbarContainer>

        </Container>
    )
}

export default TemporaryEntry