import RemoveIcon from 'components/icons/remove-icon'
import { MouseButtonEnum } from 'enums/ui'
import React from 'react'
import { SearchStore } from 'stores/search-store'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import { ISearchTerm } from 'types/search'

const Container = styled.div`
    font-family: ${styles.fonts.default};
    font-weight: 400;
    color: white;
    display: inline-flex;
    align-items: center;
    padding: .75rem .75rem .75rem 1.5rem;
    border-radius: ${styles.borderRadius.large};
    background-color: #616161;
    white-space: nowrap;
`
const DeleteButton = styled.div`
    font-size: .65em;
    background-color: darkgrey;
    border-radius: 50%;
    margin-left: 1rem;
    display: grid;
    padding: .25rem;
    cursor: pointer;
    place-items: center;

    &:hover {
        background-color: lightgrey;
    }
`

type Props = {
    term: ISearchTerm
}

const SearchTerm = (props: Props) => {
    const { term } = props

    const onRemoveClick = () => {
        SearchStore.update(state => {
            state.terms = state.terms.filter(t => t.orderId !== term.orderId)
        })
    }

    const onMiddleMouseUp = (event: React.MouseEvent) => {
        if (event.button === MouseButtonEnum.Middle) {
            onRemoveClick()
        }
    }

    return (
        <Container onMouseUp={onMiddleMouseUp}>
            {`${term.text} (${term.count})`}
            <DeleteButton onClick={onRemoveClick}>
                <RemoveIcon />
            </DeleteButton>
        </Container>
    )
}

export default SearchTerm