import { BibleVersionEnum } from 'enums/bible'
import { LanguagesEnum } from 'enums/ui'
import useClickOutside from 'hooks/useClickOutside'
import React, { useMemo, useRef, useState } from 'react'
import BibleService from 'services/bible-service'
import { ProfileStore } from 'stores/profile-store'
import styled from 'styled-components'
import { styles } from 'styles/styles'
import BibleLanguageMenu from './bible-language-menu'
import CheckIcon from './icons/check-icon'
import ClosedBookIcon from './icons/closed-book-icon'
import GlobeIcon from './icons/globe-icon'
import SearchBar from './search-bar'

const Container = styled.div`
    position: absolute;
    left: 0;
    top: 100%;
    background: white;
    padding: 1rem;
    width: 40rem;
    box-shadow: ${p => p.theme.shadows.far};
    cursor: default;
    border-radius: ${styles.borderRadius.default};
`
const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
`
const Title = styled.h3`
    font-family: ${styles.fonts.bold};
    font-weight: 700;
    font-size: 1.15em;
    margin: 0;
    padding: 1rem 0;
`
const VersionList = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    max-height: 20rem;
    overflow: auto;
`
const VersionItem = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
    line-height: 4rem;
    padding: 0 2rem;
    white-space: nowrap;
    cursor: pointer;
    justify-content: space-between;

    &:hover {
        background-color: ${p => p.theme.backgrounds.input};
    }
`
const NoResultsText = styled.div`
    line-height: 4rem;
    padding: 0 2rem;
    white-space: nowrap;
    cursor: pointer;
`
const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
`
const StyledCheckIcon = styled(CheckIcon)`
    stroke: green;
`
const LanguageContainer = styled.div`
    position: relative;
`

type Props = {
    closeMenu: () => void
    defaultVersion: BibleVersionEnum
}

const BibleVersionMenu = (props: Props) => {
    const { defaultVersion, closeMenu } = props
    
    const defaultLanguage = ProfileStore.useState(state => state.profile!.language)

    const [searchText, setSearchText] = useState<string>('')
    const [selectedLanguage, setSelectedLanguage] = useState<LanguagesEnum>(defaultLanguage)
    const [showLanguageMenu, setShowLanguageMenu] = useState<boolean>(false)
    const ignoreRef = useRef<HTMLDivElement>(null)

    useClickOutside(closeMenu, ignoreRef)

    const versions = useMemo(() => BibleService.getBibleVersions(searchText), [searchText])

    return (
        <Container ref={ignoreRef}>
            <TitleContainer>
                <ClosedBookIcon />
                <Title>{'BIBLE VERSION'}</Title>
            </TitleContainer>

            <SearchContainer>
                <SearchBar text={searchText} setText={setSearchText} placeholder='Search Version' />
                <LanguageContainer onClick={() => setShowLanguageMenu(prev => !prev)}>
                    <GlobeIcon />
                    {
                        showLanguageMenu &&
                        <BibleLanguageMenu
                            selectedLanguage={selectedLanguage}
                            setSelectedLanguage={setSelectedLanguage}
                            closeMenu={() => setShowLanguageMenu(false)}
                        />
                    }
                </LanguageContainer>
            </SearchContainer>

            <VersionList>
                {
                    versions.map(version => {

                        return (
                            <VersionItem key={`version-${version.type}`}>
                                {version.text}
                                {
                                    version.type === defaultVersion &&
                                    <StyledCheckIcon />
                                }
                            </VersionItem>
                        )
                    })
                }
                {
                    versions.length === 0 &&
                    <NoResultsText>
                        {'No version found'}
                    </NoResultsText>
                }
            </VersionList>
        </Container>
    )
}

export default BibleVersionMenu