import { Icon } from 'components/common/icon'
import React, { HTMLAttributes } from 'react'

const AddIcon = (props: HTMLAttributes<SVGElement>) => (
    <Icon {...props} viewBox='0 0 24 24'>
        <line x1='12' y1='5' x2='12' y2='19' />
        <line x1='5' y1='12' x2='19' y2='12' />
    </Icon>
)

export default AddIcon