import { Icon } from 'components/common/icon'
import React, { HTMLAttributes } from 'react'

const CompassIcon = (props: HTMLAttributes<SVGElement>) => (
    <Icon {...props} viewBox='0 0 24 24'>
        <circle cx='12' cy='12' r='10' />
        <polygon points='16.24 7.76 14.12 14.12 7.76 16.24 9.88 9.88 16.24 7.76' />
    </Icon>
)

export default CompassIcon