import { EntityEnum } from 'enums/entity'
import Helper from 'helpers/helper'
import React, { useCallback } from 'react'
import { ICrossReferenceEntity } from 'types/entity'
import { IReference } from 'types/verse'
import { usePouch } from 'use-pouchdb'

interface IUseCrossReferenceEntity {
    createCrossReference: (reference: IReference, targetReference: IReference) => void
    deleteCrossReference: (id: string) => void
}

export const useCrossReferenceEntity = () : IUseCrossReferenceEntity => {
    const db = usePouch()

    const createCrossReference = useCallback(async (reference: IReference, targetReference: IReference) => {
        const crossReference: ICrossReferenceEntity = {
            _id: Helper.randomUid(),
            type: EntityEnum.CrossReference,
            reference,
            targetReference
        }

        const result = await db.put(crossReference)

        return result
    }, [db])

    const deleteCrossReference = useCallback(async (id: string) : Promise<boolean> => {
        const crossReference = await db.get<ICrossReferenceEntity>(id)
        const result = await db.remove(crossReference)

        return result.ok
    }, [db])

    return {
        createCrossReference,
        deleteCrossReference
    }
}